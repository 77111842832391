import { useMemo } from 'react';

import SearchInput from '@/components/Form/SearchInput';
import { useTranslation } from 'react-i18next';
import useFilter from '@/hooks/useFilter';
import { PageLayoutFilterSearchInputProps } from '@/layouts/PageLayout/types';

export const Search = (props: PageLayoutFilterSearchInputProps) => {
  const { t } = useTranslation(['ui']);
  const { placeholder = t('searchInput.placeholder'), onChange, onClear, onSearch, value } = props;

  const { onSearch: onOldSearch, search: filterSearch } = useFilter();

  const finalValue = useMemo(() => {
    return onSearch ? value : filterSearch;
  }, [value, filterSearch]);

  const handleSearch = (value?: string) => {
    if (value === undefined) {
      return;
    }
    if (onSearch) {
      onSearch(value);
    } else {
      onOldSearch(value);
    }
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    onOldSearch(undefined);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <SearchInput
      value={finalValue}
      onSearch={(value) => handleSearch(value)}
      onChange={(e) => handleOnChange(e)}
      onClear={() => handleClear()}
      placeholder={placeholder}
    />
  );
};

export default Search;
