import { Stack } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { BreadcrumbItemProps } from '@/components/Breadcrumbs';
import ProductPackageForm from '@/features/productPackages/components/ProductPackageForm';
import PageLayout from '@/layouts/PageLayout';
import theme from '@/theme';
import {
  useCreateAppProductPackage,
  useUpdateProductPackage,
} from '@/services/sellerApiQuery/api/product-packages/product-packages';
import { ProductPackageParams } from '@/services/sellerApiQuery/model';
import useAccount from '@/hooks/useAccount';

const FormPage = () => {
  const { id } = useParams<{ id: string }>();
  const isEdit = useMemo(() => !!id, [id]);

  const { t } = useTranslation(['product-packages'], { keyPrefix: 'form' });
  const { selectedAccount } = useAccount();

  const {
    mutate: createMutate,
    data: createResult,
    error: createError,
    isPending: isCreatePending,
    isSuccess: isCreateSuccess,
  } = useCreateAppProductPackage();

  const {
    mutate: updateMutate,
    data: updateResult,
    error: updateError,
    isPending: isUpdatePending,
    isSuccess: isUpdateSuccess,
  } = useUpdateProductPackage();

  const isLoading = useMemo(() => isCreatePending || isUpdatePending, [isCreatePending, isUpdatePending]);
  const data = useMemo(() => (isEdit ? updateResult : createResult), [isEdit, updateResult, createResult]);
  const error = useMemo(() => (isEdit ? updateError : createError), [isEdit, updateError, createError]);

  const onSubmit = (data: ProductPackageParams) => {
    if (isEdit) {
      updateMutate({ productPackageId: id ?? '', data });
    } else {
      createMutate({ appId: selectedAccount?.appId ?? '', data });
    }
  };

  const isSuccess = useMemo(
    () => (isEdit ? isUpdateSuccess : isCreateSuccess),
    [isEdit, isUpdateSuccess, isCreateSuccess],
  );

  const pageTitle = useMemo(() => {
    if (isEdit) {
      return `${t('edit_title')}`;
    }
    return t('new_title');
  }, [isEdit, t]);

  const breadcrumbs: BreadcrumbItemProps[] = [
    {
      label: t('packages_title'),
      to: '../productPackages',
    },
    {
      label: t(isEdit ? 'edit_title' : 'new_title'),
    },
  ];

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayout
        title={pageTitle}
        showFilterButton={false}
        showSearchInput={false}
        showGoBackButton={true}
        breadcrumbs={breadcrumbs}
      >
        <Stack sx={{ width: '100%', paddingX: theme.spacing(5), paddingY: theme.spacing(6) }}>
          <ProductPackageForm
            loading={isLoading}
            onSubmit={onSubmit}
            data={data}
            error={error}
            isSuccess={isSuccess}
            productPackageId={id}
          />
        </Stack>
      </PageLayout>
    </Fragment>
  );
};

export default FormPage;
