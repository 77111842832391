import { Fragment } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { PartnershipPortalCartRoutesProvider } from '@/contexts/PartnershipPortalCartContext';
import PartnershipList from '@/components/Partnership/PartnershipList';
import PartnershipDetail from '@/components/Partnership/PartnershipDetail';
import Dashboard from '@/layouts/Dashboard';
import ProposalLayout from '@/layouts/ProposalLayout';
import CreateApp from '@/pages/App/Create';
import Base from '@/pages/Base';
import DirectSales from '@/pages/DirectSales';
import ExternalPage from '@/pages/ExternalPage';
import Login from '@/pages/Login';
import PartnershipAffiliations from '@/pages/Partnership/Affiliations';
import PartnershipCommissions from '@/pages/Partnership/Commissions';
import PartnershipCanceledCommissions from '@/pages/Partnership/Commissions/Canceled';
import PartnershipProviders from '@/pages/Partnership/Providers';
import ProductShowCase from '@/pages/ProductShowCase';
import ProductShowCaseReservationForm from '@/pages/ProductShowCase/ProductShowCaseReservationForm';
import ProgramProposal from '@/pages/ProgramProposal';
import RefundRequests from '@/pages/RefundRequests';
import RequestRefundModal from '@/pages/RefundRequests/RequestRefundModal';
import ResetPassword from '@/pages/ResetPassword';
import CodeValidation from '@/pages/ResetPassword/CodeValidation';
import NewPassword from '@/pages/ResetPassword/NewPassword';
import SendEmail from '@/pages/ResetPassword/SendEmail';
import ShowCaseCheckoutPage from '@/pages/ShowCaseCheckoutPage';
import Signup from '@/pages/Signup';
import TermModal from '@/pages/Signup/TermModal';
import Terms from '@/pages/Terms';
import { AssociationModel } from '@/services/SellerApi';
import ProtectedRoute from './ProtectedRoute';
import { schedulingRouters } from '@/features/agenda/router';
import { SaleRoutes } from '@/features/sales/router';
import { CouponRoutes } from '@/features/coupons/router';
import { productPackagesRouters } from '@/features/productPackages/router';

const internalDashboardRoutes = createRoutesFromElements(
  <Fragment>
    <Route index element={<Navigate to='dashboard' />} />
    {SaleRoutes()}
    {CouponRoutes()}
    <Route
      path='refund-requests'
      element={
        <ProtectedRoute
          policies={[
            {
              namespace: 'refundRequests',
              action: 'GetAppRefundRequests',
              code: 'refundRequests:GetAppRefundRequests',
            },
          ]}
          element={<RefundRequests />}
        />
      }
    >
      <Route
        path='create/:saleId'
        element={
          <ProtectedRoute
            policies={[
              {
                namespace: 'refundRequests',
                action: 'CreateSaleRefundRequest',
                code: 'refundRequests:CreateSaleRefundRequest',
              },
            ]}
            element={<RequestRefundModal />}
          />
        }
      />
    </Route>

    <Route
      path='partnership/agents'
      element={
        <ProtectedRoute
          policies={[
            {
              namespace: 'partnerships',
              action: 'GetAppValidProvidedPartnerships',
              code: 'partnerships:GetAppValidProvidedPartnerships',
            },
          ]}
          element={<PartnershipAffiliations />}
        />
      }
    >
      <Route
        path={AssociationModel.Affiliation}
        element={<PartnershipList viewType='provider' currentTab={AssociationModel.Affiliation} />}
      />
      <Route
        path={AssociationModel.DirectPurchase}
        element={<PartnershipList viewType='provider' currentTab={AssociationModel.DirectPurchase} />}
      />
    </Route>

    <Route
      path='partnership/:id'
      element={
        <ProtectedRoute
          policies={[
            {
              namespace: 'partnerships',
              action: 'GetAppValidProvidedPartnerships',
              code: 'partnerships:GetAppValidProvidedPartnerships',
            },
          ]}
          element={<PartnershipDetail />}
        />
      }
    />

    <Route
      path='partnership/providers'
      element={
        <ProtectedRoute
          policies={[
            {
              namespace: 'partnerships',
              action: 'GetAppValidArrangedPartnerships',
              code: 'partnerships:GetAppValidArrangedPartnerships',
            },
          ]}
          element={<PartnershipProviders />}
        />
      }
    >
      <Route
        path={AssociationModel.Affiliation}
        element={<PartnershipList viewType='agent' currentTab={AssociationModel.Affiliation} />}
      />
      <Route
        path={AssociationModel.DirectPurchase}
        element={<PartnershipList viewType='agent' currentTab={AssociationModel.DirectPurchase} />}
      />
    </Route>

    <Route path='partnership/commissions' element={<ProtectedRoute element={<PartnershipCommissions />} />} />

    <Route
      path='partnership/commissions/canceled'
      element={<ProtectedRoute element={<PartnershipCanceledCommissions />} />}
    />

    <Route
      path='partnership/productShowCase'
      element={
        <ProtectedRoute
          policies={[
            {
              namespace: 'arrangedProducts',
              action: 'GetAppArrangedProducts',
              code: 'arrangedProducts:GetAppArrangedProducts',
            },
          ]}
          element={<ProductShowCase />}
        />
      }
    />
    <Route path='partnership/productShowCase' element={<PartnershipPortalCartRoutesProvider />}>
      <Route
        path=''
        element={
          <ProtectedRoute
            policies={[
              {
                namespace: 'arrangedProducts',
                action: 'GetAppArrangedProducts',
                code: 'arrangedProducts:GetAppArrangedProducts',
              },
            ]}
            element={<ProductShowCase />}
          />
        }
      >
        <Route path=':productId' element={<ProductShowCaseReservationForm />} />
      </Route>
      <Route
        path='checkout'
        element={
          <ProtectedRoute
            policies={[
              {
                namespace: 'arrangedProducts',
                action: 'GetAppArrangedProducts',
                code: 'arrangedProducts:GetAppArrangedProducts',
              },
            ]}
            element={<ShowCaseCheckoutPage />}
          />
        }
      />
    </Route>
    <Route
      path='partnership/productShowCase'
      element={
        <ProtectedRoute
          policies={[
            {
              namespace: 'arrangedProducts',
              action: 'GetAppArrangedProducts',
              code: 'arrangedProducts:GetAppArrangedProducts',
            },
          ]}
          element={<ProductShowCase />}
        />
      }
    />

    <Route path='partnership/productShowCase' element={<PartnershipPortalCartRoutesProvider />}>
      <Route
        path=''
        element={
          <ProtectedRoute
            policies={[
              {
                namespace: 'arrangedProducts',
                action: 'GetAppArrangedProducts',
                code: 'arrangedProducts:GetAppArrangedProducts',
              },
            ]}
            element={<ProductShowCase />}
          />
        }
      >
        <Route path=':productId' element={<ProductShowCaseReservationForm />} />
      </Route>
      <Route
        path='checkout'
        element={
          <ProtectedRoute
            policies={[
              {
                namespace: 'arrangedProducts',
                action: 'GetAppArrangedProducts',
                code: 'arrangedProducts:GetAppArrangedProducts',
              },
            ]}
            element={<ShowCaseCheckoutPage />}
          />
        }
      />
    </Route>

    <Route
      path='partnership/directSales'
      element={
        <ProtectedRoute
          policies={[
            {
              namespace: 'directSales',
              action: 'GetAppDirectSales',
              code: 'directSales:GetAppDirectSales',
            },
          ]}
          element={<DirectSales />}
        />
      }
    />

    <Route path='*' element={<ProtectedRoute element={<ExternalPage />} />} />
  </Fragment>,
);

const useRouter = () => {
  return createBrowserRouter(
    [
      { path: '/', index: true, element: <Base /> },
      {
        path: '/privacy-policy',
        element: <Terms documentType='privacy_policy' />,
      },
      {
        path: '/terms-of-use',
        element: <Terms documentType='terms_of_use' />,
      },
      {
        path: '/proposal',
        element: <ProposalLayout />,
        children: [
          {
            path: ':code',
            element: <ProgramProposal />,
          },
        ],
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/signup',
        element: <Signup />,
        children: [
          {
            path: 'terms-of-use',
            element: <TermModal documentType='terms_of_use' />,
          },
          { path: 'privacy-policy', element: <TermModal documentType='privacy_policy' /> },
        ],
      },
      {
        path: '/reset-password',
        element: <ResetPassword />,
        children: [
          {
            index: true,
            element: <SendEmail />,
          },
          {
            path: 'code-validation',
            element: <CodeValidation />,
          },
          {
            path: 'new-password',
            element: <NewPassword />,
          },
        ],
      },
      {
        path: '/app/create',
        element: <ProtectedRoute needAccount={false} element={<CreateApp />} />,
      },
      {
        path: '/:appCode',
        element: <Dashboard />,
        children: [...internalDashboardRoutes, ...schedulingRouters, ...productPackagesRouters],
      },
    ],
    {
      future: {
        v7_relativeSplatPath: true,
      },
    },
  );
};

export default useRouter;
