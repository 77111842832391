import { RescheduleScheduling } from '@/services/sellerApiQuery/model';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import { isSameDay } from 'date-fns';

const checkRescheduleSoldOut = (day: Date, reschedules: RescheduleScheduling[]) => {
  return reschedules.some((schedulings) => {
    const schedulingDate = apiDateToDateObject(schedulings.date);
    return (
      isSameDay(schedulingDate, day) &&
      (schedulings.scheduleAvailabilities?.every((availability) => !availability.available) ||
        schedulings.scheduleAvailabilities.length === 0)
    );
  });
};

export default checkRescheduleSoldOut;
