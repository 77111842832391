import { Reservation, SellingMode } from '@/services/sellerApiQuery/model';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import { isValid, isSameDay } from 'date-fns';
import { z } from 'zod';

/**
 * These functions are exported due to an inconsistency in form validation when changing the date.
 * Whenever the date is changed, the form's `isValid` state presents issues, requiring a second click
 * to revalidate the form and set the `isValid` state of the formState.
 */

export const isValidTime = (isSellModeWithDateOnly: boolean, time?: string): boolean => {
  if (!isSellModeWithDateOnly) {
    return !!time && /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
  }
  return true;
};

export const isValidDateTime = (
  data: { scheduleDate?: Date; scheduleTime?: string | null },
  reservation?: Reservation,
): boolean => {
  if (!data.scheduleDate || !isValid(data.scheduleDate)) {
    return false;
  }
  const isSameDate =
    reservation?.scheduleDate && isSameDay(data.scheduleDate, apiDateToDateObject(reservation.scheduleDate));

  if (reservation?.product?.sellingMode === SellingMode.with_date_only) {
    return !isSameDate;
  } else {
    if (data?.scheduleTime === null) {
      return false;
    }
    const isSameTime = reservation && data.scheduleTime === reservation.scheduleTime;

    return !(isSameTime && isSameDate);
  }
};

export const rescheduleFormSchema = (reservation?: Reservation) => {
  const isSellModeWithDateOnly = reservation?.product?.sellingMode === SellingMode.with_date_only;
  return z
    .object({
      scheduleDate: z.date().refine((date) => {
        if (!isValid(date)) {
          return false;
        }
        if (isSellModeWithDateOnly) {
          return !(reservation?.scheduleDate && isSameDay(date, apiDateToDateObject(reservation?.scheduleDate)));
        }
        return true;
      }),
      scheduleTime: z
        .string()
        .optional()
        .refine((time) => {
          return isValidTime(isSellModeWithDateOnly, time);
        }),
    })
    .superRefine((data) => {
      return isValidDateTime(data, reservation);
    })
    .transform((data) => {
      const scheduleDate = data.scheduleDate.toISOString().split('T')[0];
      return {
        ...data,
        scheduleDate,
      };
    });
};
