import i18n from '@/services/i18n';
import translatePT from '@/features/products/locales/pt.json';

export const NS = 'products';

const loadProductsTranslations = () => {
  i18n.addResourceBundle('pt-BR', NS, translatePT);
};

export default loadProductsTranslations;
