import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import FilterProvider from '@/contexts/FilterContext';
import ConfigProvider from '@/features/config/context/provider';
import { ProductPackagesConfigState } from '@/features/productPackages/types';
import useProductPackagesConfig from '@/features/productPackages/store/useProductPackagesConfig';
import ProductPackagesPageWithConfig from '@/features/productPackages/components/ProductPackagesPageWithConfig';

const ListPage = () => {
  const { t } = useTranslation(['product-packages']);
  const configProductPackages = useProductPackagesConfig();

  return (
    <ConfigProvider<ProductPackagesConfigState> config={configProductPackages}>
      <FilterProvider contextName={'product-packages'}>
        <Helmet>
          <title>{t('page_title')}</title>
        </Helmet>
        <ProductPackagesPageWithConfig />
      </FilterProvider>
    </ConfigProvider>
  );
};

export default ListPage;
