/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetAppProductPackagesParams,
  GetProductPackageCategoriesParams,
  GetProductPackageParams,
  GetProductPackageProductsParams,
  NotFoundResponseResponse,
  ProductPackage,
  ProductPackageCategory,
  ProductPackageParams,
  ProductPackageProduct,
  UnprocessableResponseResponse
} from '../../model'
import { customInstance } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getAppProductPackages = (
    appId: string,
    params?: GetAppProductPackagesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProductPackage[]>(
      {url: `/apps/${appId}/productPackages`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAppProductPackagesQueryKey = (appId: string,
    params?: GetAppProductPackagesParams,) => {
    return [`/apps/${appId}/productPackages`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAppProductPackagesQueryOptions = <TData = Awaited<ReturnType<typeof getAppProductPackages>>, TError = ErrorType<NotFoundResponseResponse>>(appId: string,
    params?: GetAppProductPackagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppProductPackages>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAppProductPackagesQueryKey(appId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppProductPackages>>> = ({ signal }) => getAppProductPackages(appId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAppProductPackages>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAppProductPackagesQueryResult = NonNullable<Awaited<ReturnType<typeof getAppProductPackages>>>
export type GetAppProductPackagesQueryError = ErrorType<NotFoundResponseResponse>



export function useGetAppProductPackages<TData = Awaited<ReturnType<typeof getAppProductPackages>>, TError = ErrorType<NotFoundResponseResponse>>(
 appId: string,
    params?: GetAppProductPackagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppProductPackages>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAppProductPackagesQueryOptions(appId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createAppProductPackage = (
    appId: string,
    productPackageParams: BodyType<ProductPackageParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProductPackage>(
      {url: `/apps/${appId}/productPackages`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: productPackageParams, signal
    },
      options);
    }
  


export const getCreateAppProductPackageMutationOptions = <TError = ErrorType<UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAppProductPackage>>, TError,{appId: string;data: BodyType<ProductPackageParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createAppProductPackage>>, TError,{appId: string;data: BodyType<ProductPackageParams>}, TContext> => {
    
const mutationKey = ['createAppProductPackage'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAppProductPackage>>, {appId: string;data: BodyType<ProductPackageParams>}> = (props) => {
          const {appId,data} = props ?? {};

          return  createAppProductPackage(appId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateAppProductPackageMutationResult = NonNullable<Awaited<ReturnType<typeof createAppProductPackage>>>
    export type CreateAppProductPackageMutationBody = BodyType<ProductPackageParams>
    export type CreateAppProductPackageMutationError = ErrorType<UnprocessableResponseResponse>

    export const useCreateAppProductPackage = <TError = ErrorType<UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAppProductPackage>>, TError,{appId: string;data: BodyType<ProductPackageParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createAppProductPackage>>,
        TError,
        {appId: string;data: BodyType<ProductPackageParams>},
        TContext
      > => {

      const mutationOptions = getCreateAppProductPackageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getProductPackage = (
    productPackageId: string,
    params?: GetProductPackageParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProductPackage>(
      {url: `/productPackages/${productPackageId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProductPackageQueryKey = (productPackageId: string,
    params?: GetProductPackageParams,) => {
    return [`/productPackages/${productPackageId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProductPackageQueryOptions = <TData = Awaited<ReturnType<typeof getProductPackage>>, TError = ErrorType<NotFoundResponseResponse>>(productPackageId: string,
    params?: GetProductPackageParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductPackage>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductPackageQueryKey(productPackageId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductPackage>>> = ({ signal }) => getProductPackage(productPackageId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productPackageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductPackage>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductPackageQueryResult = NonNullable<Awaited<ReturnType<typeof getProductPackage>>>
export type GetProductPackageQueryError = ErrorType<NotFoundResponseResponse>



export function useGetProductPackage<TData = Awaited<ReturnType<typeof getProductPackage>>, TError = ErrorType<NotFoundResponseResponse>>(
 productPackageId: string,
    params?: GetProductPackageParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductPackage>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductPackageQueryOptions(productPackageId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateProductPackage = (
    productPackageId: string,
    productPackageParams: BodyType<ProductPackageParams>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ProductPackage>(
      {url: `/productPackages/${productPackageId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: productPackageParams
    },
      options);
    }
  


export const getUpdateProductPackageMutationOptions = <TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProductPackage>>, TError,{productPackageId: string;data: BodyType<ProductPackageParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProductPackage>>, TError,{productPackageId: string;data: BodyType<ProductPackageParams>}, TContext> => {
    
const mutationKey = ['updateProductPackage'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProductPackage>>, {productPackageId: string;data: BodyType<ProductPackageParams>}> = (props) => {
          const {productPackageId,data} = props ?? {};

          return  updateProductPackage(productPackageId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateProductPackageMutationResult = NonNullable<Awaited<ReturnType<typeof updateProductPackage>>>
    export type UpdateProductPackageMutationBody = BodyType<ProductPackageParams>
    export type UpdateProductPackageMutationError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>

    export const useUpdateProductPackage = <TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProductPackage>>, TError,{productPackageId: string;data: BodyType<ProductPackageParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateProductPackage>>,
        TError,
        {productPackageId: string;data: BodyType<ProductPackageParams>},
        TContext
      > => {

      const mutationOptions = getUpdateProductPackageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteProductPackage = (
    productPackageId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/productPackages/${productPackageId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProductPackageMutationOptions = <TError = ErrorType<NotFoundResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductPackage>>, TError,{productPackageId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProductPackage>>, TError,{productPackageId: string}, TContext> => {
    
const mutationKey = ['deleteProductPackage'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProductPackage>>, {productPackageId: string}> = (props) => {
          const {productPackageId} = props ?? {};

          return  deleteProductPackage(productPackageId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProductPackageMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProductPackage>>>
    
    export type DeleteProductPackageMutationError = ErrorType<NotFoundResponseResponse>

    export const useDeleteProductPackage = <TError = ErrorType<NotFoundResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductPackage>>, TError,{productPackageId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteProductPackage>>,
        TError,
        {productPackageId: string},
        TContext
      > => {

      const mutationOptions = getDeleteProductPackageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getProductPackageProducts = (
    productPackageId: string,
    params?: GetProductPackageProductsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProductPackageProduct[]>(
      {url: `/productPackages/${productPackageId}/products`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProductPackageProductsQueryKey = (productPackageId: string,
    params?: GetProductPackageProductsParams,) => {
    return [`/productPackages/${productPackageId}/products`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProductPackageProductsQueryOptions = <TData = Awaited<ReturnType<typeof getProductPackageProducts>>, TError = ErrorType<NotFoundResponseResponse>>(productPackageId: string,
    params?: GetProductPackageProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductPackageProducts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductPackageProductsQueryKey(productPackageId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductPackageProducts>>> = ({ signal }) => getProductPackageProducts(productPackageId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productPackageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductPackageProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductPackageProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getProductPackageProducts>>>
export type GetProductPackageProductsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetProductPackageProducts<TData = Awaited<ReturnType<typeof getProductPackageProducts>>, TError = ErrorType<NotFoundResponseResponse>>(
 productPackageId: string,
    params?: GetProductPackageProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductPackageProducts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductPackageProductsQueryOptions(productPackageId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getProductPackageCategories = (
    productPackageId: string,
    params?: GetProductPackageCategoriesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProductPackageCategory[]>(
      {url: `/productPackages/${productPackageId}/categories`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProductPackageCategoriesQueryKey = (productPackageId: string,
    params?: GetProductPackageCategoriesParams,) => {
    return [`/productPackages/${productPackageId}/categories`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProductPackageCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getProductPackageCategories>>, TError = ErrorType<NotFoundResponseResponse>>(productPackageId: string,
    params?: GetProductPackageCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductPackageCategories>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductPackageCategoriesQueryKey(productPackageId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductPackageCategories>>> = ({ signal }) => getProductPackageCategories(productPackageId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productPackageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductPackageCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductPackageCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getProductPackageCategories>>>
export type GetProductPackageCategoriesQueryError = ErrorType<NotFoundResponseResponse>



export function useGetProductPackageCategories<TData = Awaited<ReturnType<typeof getProductPackageCategories>>, TError = ErrorType<NotFoundResponseResponse>>(
 productPackageId: string,
    params?: GetProductPackageCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductPackageCategories>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductPackageCategoriesQueryOptions(productPackageId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



