import { useLocation } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';

export const useActiveTabInRoute = <T extends string = string>(tabRoutes: string[], defaultTab?: string): T => {
  const location = useLocation();
  const defaultValue = (defaultTab as T) || ('' as T);
  const [activeTab, setActiveTab] = useState<T>(defaultValue);

  useLayoutEffect(() => {
    const sortedRoutes = [...tabRoutes].sort((a, b) => b.length - a.length);

    for (const route of sortedRoutes) {
      if (location.pathname.includes(route)) {
        setActiveTab(route as T);
        return;
      }
    }

    setActiveTab(defaultValue);
  }, [location.pathname, tabRoutes]);

  return activeTab;
};

export default useActiveTabInRoute;
