import { DatePicker } from '@planne-software/uni/DatePicker';
import { useCallback } from 'react';
import checkSoldOut from '@/features/reservation/utils/checkRescheduleSoldOut';
import checkRescheduleAvailable from '@/features/reservation/utils/checkRescheduleAvailable';
import { isSameMonth, isSameDay } from 'date-fns';
import { GetAllReservationRescheduleSchedulingsResponse } from '@/features/reservation/useCases/useGetAllReservationRescheduleSchedulings';
import HorizontalCalendar from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/CalendarSelector/HorizontalCalendar';
import HorizontalCalendarSkeleton from './HorizontalCalendar/HorizontalCalendarSkeleton/index';
import { DayCellProps } from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/CalendarSelector/HorizontalCalendar/DayCell/types';

type Props = {
  value?: Date;
  activeMonth: Date;
  isCalendarCompact: boolean;
  isLoading: boolean;
  isChaosMode: boolean;
  onChange: (date: Date | null) => void;
  onViewChange: (date: Date) => void;
  compactWindow?: number;
  isViewLoading?: boolean;
  focusedDate?: Date | null;
  getAllResponse?: GetAllReservationRescheduleSchedulingsResponse;
};
const CalendarSelector = (props: Props) => {
  const {
    value,
    activeMonth,
    isCalendarCompact,
    isLoading,
    isChaosMode,
    getAllResponse,
    onChange,
    onViewChange,
    compactWindow,
    isViewLoading,
    focusedDate,
  } = props;

  const checkAvailability = useCallback(
    (props: DayCellProps<Date>) => {
      const { day } = props;

      const isSoldOut = checkSoldOut(day, getAllResponse?.data ?? []);
      const isAvailable = checkRescheduleAvailable(day, getAllResponse?.data ?? []);
      const isMonthDaySameMonth = isSameMonth(day, activeMonth);
      const isValueSameDay = value && isSameDay(day, value);

      const isDisabled = () => {
        if (isCalendarCompact) {
          if (isChaosMode) {
            return false;
          }

          return !isAvailable || isSoldOut;
        }

        if (!isChaosMode) {
          return !isAvailable || isSoldOut || !isMonthDaySameMonth;
        }

        return false;
      };

      const getColor = () => {
        if (isCalendarCompact) {
          if (isDisabled() && isValueSameDay) {
            return 'error';
          }

          if (isValueSameDay) {
            return 'primary';
          }

          if (isChaosMode && isValueSameDay) {
            return 'primary';
          }

          if (isSoldOut) {
            return 'error';
          }
        }

        if (isSoldOut) {
          return 'error';
        }

        if (isChaosMode && isMonthDaySameMonth && !isCalendarCompact && !isAvailable) {
          return 'primary';
        }

        if (isAvailable) {
          return 'success';
        }

        return 'neutral';
      };

      const newProps: DayCellProps<Date> = {
        ...props,
        isLoading: isViewLoading,
        disabled: isDisabled(),
        color: getColor(),
      };

      return newProps;
    },
    [getAllResponse, value, isCalendarCompact, isChaosMode, activeMonth, isViewLoading],
  );

  if (isLoading && isCalendarCompact) {
    return <HorizontalCalendarSkeleton total={compactWindow} />;
  }

  return isCalendarCompact ? (
    <HorizontalCalendar
      dayRenderer={checkAvailability}
      value={value}
      focusedDate={focusedDate}
      viewSize={compactWindow}
      onChange={(date: Date | null) => onChange(date)}
      onViewChange={onViewChange}
    />
  ) : (
    <DatePicker
      disablePast
      dayRenderer={checkAvailability}
      loading={isLoading}
      value={value}
      onMonthChange={onViewChange}
      onChange={(date: Date | null) => onChange(date)}
      slots={{
        actionBar: () => null,
      }}
    />
  );
};

export default CalendarSelector;
