import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AlertTitle, Grid, Typography } from '@mui/material';

import useAnalytics from '@/hooks/analytics/useAnalytics';
import {
  DirectSaleTransaction,
  DirectSalesApiGetDirectSalePaymentOptionsRequest,
  PaymentMethodCode,
  PaymentOption,
  DirectSale,
  ErrorObject,
  TransactionRequest,
} from '@/services/SellerApi';

import Button from '@/components/Button';

import { useSellerApi } from '@/hooks/useSellerApi';
import { formatCents } from '@/utils';
import Loading from '@/components/Loading';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import TransactionErrorAlert from '../TransactionErrorAlert';
import { enqueueSnackbar } from 'notistack';
import BackdropLoading from '@/components/BackdropLoading';
import { AxiosError } from 'axios';
import { TransactionErrorAlertModalTitle } from '../TransactionErrorAlert/styles';
import { ReceiptAmountCard } from './styles';
import { IconReceipt, IconX } from '@tabler/icons-react';
import { InfoOutlined } from '@mui/icons-material';
import Alert from '@/components/Alert';
import useAuth from '@/contexts/Auth/hooks/useAuth';

type Props = {
  saleId: string;
  directSale?: DirectSale;
  onSuccess?: (transaction: DirectSaleTransaction) => void;
  onFail?: (error: ErrorObject) => void;
  onCancel?: () => void;
};

const PostPaidForm = (props: Props) => {
  const { saleId, onSuccess = () => {}, onFail = () => {}, onCancel = () => {}, directSale } = props;

  const { track } = useAnalytics();
  const { sessionId } = useAuth();

  const { directSalesApi, salesApi } = useSellerApi();

  const { t } = useTranslation(['payment']);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPaymentOption, setLoadingPaymentOption] = useState<boolean>(true);
  const [paymentOption, setPaymentOption] = useState<PaymentOption | undefined>(undefined);
  const [transactionError, setTransactionError] = useState<'transaction' | 'antifraud' | 'expired' | undefined>(
    undefined,
  );

  const getPaymentOptions = async (saleId: string) => {
    setLoadingPaymentOption(true);
    const params: DirectSalesApiGetDirectSalePaymentOptionsRequest = {
      saleId: saleId,
      sessionId,
    };

    try {
      const { data } = await directSalesApi.getDirectSalePaymentOptions(params);

      const option = data.find((option) => {
        return option.paymentMethod.code === PaymentMethodCode.PostPaid;
      });

      setPaymentOption(option);
    } catch (error) {
      enqueueSnackbar(t('errors.could_not_retrieve_payment_option'), {
        variant: 'error',
      });
    } finally {
      setLoadingPaymentOption(false);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const transactionRequest: TransactionRequest = {
      paymentMethodId: paymentOption?.paymentMethod.id ?? '',
      deviceInfo: {
        language: navigator.language,
        screenHeight: screen.height,
        screenWidth: screen.width,
        timeZoneOffset: new Date().getTimezoneOffset() / 60,
      },
      sessionId,
    };

    try {
      const { data } = await salesApi.transactDirectSale({
        saleId,
        transactionRequest,
      });

      onSuccess(data);
    } catch (error) {
      const res = (error as AxiosError).response!.data as ErrorObject;

      if (res.errors?.length) {
        if (res.errors[0].extra?.type === 'Transaction') {
          setTransactionError('transaction');
        }

        // eslint-disable-next-line
        // @ts-ignore
        if (res.errors[0]?.type === 'saleMustHaveValidState') {
          setTransactionError('expired');
        }
      }

      enqueueSnackbar(t('errors.could_not_create_transaction'), {
        variant: 'error',
      });

      onFail(res);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!props.saleId) return;

    getPaymentOptions(props.saleId);

    track('paymentForm');
  }, []);

  return (
    <Grid container spacing={2} component='form' onSubmit={onSubmit}>
      {loadingPaymentOption ? (
        <Grid item xs={12}>
          <Loading sx={{ my: '70px' }} />
        </Grid>
      ) : null}

      {loading ? (
        <Grid item xs={12}>
          <BackdropLoading />
        </Grid>
      ) : null}

      {!loadingPaymentOption && !paymentOption ? (
        <Typography variant='h6' sx={{ my: '50px' }}>
          {t('post_paid_form.sale_without_post_paid_message')}
        </Typography>
      ) : null}

      {paymentOption ? (
        <>
          <Grid item xs={12}>
            <ReceiptAmountCard>
              <Typography variant='largeMedium' sx={{ mb: '0.5rem' }}>
                {t('post_paid_form.total_message')}
              </Typography>

              {directSale ? <Typography variant='largeBold'>{formatCents(directSale.amountCents)}</Typography> : null}
            </ReceiptAmountCard>
          </Grid>

          <Grid item xs={12}>
            <Alert severity='warning' icon={<InfoOutlined fontSize='inherit' />} sx={{ mb: '16px' }}>
              <AlertTitle>{t('post_paid_form.warning.title')}</AlertTitle>

              {t('post_paid_form.warning.message')}
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent={'flex-end'}>
              <Grid item>
                <Button variant='outlined' color='primary' fullWidth onClick={onCancel} startIcon={<IconX />}>
                  {t('post_paid_form.cancel')}
                </Button>
              </Grid>

              <Grid item>
                <Button type='submit' variant='contained' color='primary' fullWidth startIcon={<IconReceipt />}>
                  {t('post_paid_form.finish_payment')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}

      <ModalOnDesktopDrawerOnMobile
        width={455}
        anchor='bottom'
        isOpen={!!transactionError}
        title={<TransactionErrorAlertModalTitle>{t('errors.rejected.title')}</TransactionErrorAlertModalTitle>}
        onClose={() => setTransactionError(undefined)}
      >
        {transactionError ? (
          <TransactionErrorAlert type={transactionError} onClose={() => setTransactionError(undefined)} />
        ) : null}
      </ModalOnDesktopDrawerOnMobile>
    </Grid>
  );
};

export default PostPaidForm;
