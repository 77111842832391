import { Box, styled } from '@mui/material';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isNewDesign?: boolean;
}

export const FilterContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isNewDesign',
})<Props>(({ theme, isNewDesign }) => ({
  overflowX: 'hidden',
  overflowY: 'auto',
  ...(isNewDesign && {
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
    gap: theme.spacing(3),
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  }),
}));
