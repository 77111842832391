import { get } from 'lodash';
import { Grid, FormHelperText, Stack, Box } from '@mui/material';
import { Fragment, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { Control, Controller, FieldErrors, useFieldArray, useWatch } from 'react-hook-form';

import theme from '@/theme';
import Button from '@/components/Button';
import Input from '@/components/Form/Input';
import useBreakpoints from '@/hooks/useBreakpoints';

import { ProductPackageFormData } from '@/features/productPackages/components/ProductPackageForm/schema';
import {
  TimesAndQuantityWrapper,
  Container,
  ReplicateQuantity,
} from '@/features/agenda/features/availability/components/AvailabilityForm/DynamicTime/styles';
import ProductSelector from '@/features/products/components/ProductSelector';
import { FilterOption } from '@/interfaces/filterContext';
import useLoadProductsQuery from '@/queries/useLoadProductsQuery';
import Alert from '@/components/Alert';

type ProductExtraInformation = {
  name: string;
  archived: boolean;
};

interface Props {
  formValues?: ReturnType<typeof useWatch<ProductPackageFormData>>;
  errors: FieldErrors<ProductPackageFormData>;
  control: Control<ProductPackageFormData, unknown>;
  isEdit: boolean;
  onReplicateDiscount: () => void;
}

const DynamicProductWithDiscount = (props: Props) => {
  const { control, errors, onReplicateDiscount } = props;

  const { t } = useTranslation(['product-packages'], { keyPrefix: 'form' });
  const { isMobile, isTablet } = useBreakpoints();

  const { fields, append, remove } = useFieldArray({ control, name: 'products' });

  const [selectedProductId, setSelectedProductId] = useState<string | undefined>(undefined);

  const [productsQuery] = useLoadProductsQuery(undefined, undefined, false);

  const { data: productOptions } = productsQuery(true);

  const hiddenProducts = useMemo(() => {
    return fields.map((field) => field.productId);
  }, [fields]);

  const handleSelectProduct = (value?: FilterOption[]) => {
    if (value) {
      setSelectedProductId(value[0].value);
    } else {
      setSelectedProductId(undefined);
    }
  };

  const getExtraProductInformation = (productId: string) => {
    const product = productOptions?.find((product) => product.id === productId);
    if (product) {
      return {
        name: product.internalName ?? product.name ?? '',
        archived: !product.active,
      } as ProductExtraInformation;
    }
  };

  const addProduct = () => {
    const lastIdx = fields.length - 1;

    if (selectedProductId) {
      append({
        productId: selectedProductId,
        discountPercentage: 0,
        position: lastIdx + 1,
      });
    }
    setSelectedProductId(undefined);
  };

  const handleRemoveProduct = (itemIdx: number) => {
    remove(itemIdx);
  };

  useEffect(() => {
    fields.forEach((item, itemIdx) => {
      if (getExtraProductInformation(item.productId)?.archived) {
        control.setError(`products.${itemIdx}.productId`, {
          type: 'manual',
          message: t('error.fields.products.archived.title'),
        });
      }
    });
  }, [fields, control, t]);

  return (
    <Fragment>
      <Grid item xs={12} gap={2} marginBottom={4}>
        <Stack direction='row' gap={2}>
          <ProductSelector
            isMultiple={false}
            value={selectedProductId ?? ''}
            filterOptions={hiddenProducts}
            initialState={productOptions}
            activeOnly={false}
            disableInactive
            sellingModes={[]}
            error={!!errors.products?.message}
            onChange={(_, value) => handleSelectProduct(value as FilterOption[])}
            onReset={() => setSelectedProductId(undefined)}
          />
          <Button
            color='secondary'
            variant='outlined'
            onClick={addProduct}
            disabled={!selectedProductId}
            startIcon={<IconPlus color='currentColor' />}
          >
            {t('add_product')}
          </Button>
        </Stack>
        {errors.products?.message ? <FormHelperText error>{errors.products?.message}</FormHelperText> : null}
      </Grid>
      {fields.map((item, itemIdx) => (
        <Container direction='column' key={item.productId} marginBottom={itemIdx === fields.length - 1 ? 0 : 4}>
          <TimesAndQuantityWrapper direction={{ xs: 'column', md: 'row' }}>
            <Input
              type='text'
              fullWidth
              value={getExtraProductInformation(item.productId)?.name ?? undefined}
              label={t('product.name')}
              disabled
            />
            <Controller
              name={`products.${itemIdx}.discountPercentage`}
              control={control}
              render={({ field: { onChange, value = '', name, ...rest } }) => (
                <Input
                  type='number'
                  fullWidth
                  name={name}
                  value={value}
                  label={t('product.discountPercentage')}
                  onChange={({ target }) => {
                    if (target.value.length > 3) {
                      target.value = target.value.substring(0, 3);
                    }
                    if (target.value[0] === '0' && target.value.length > 1) {
                      target.value = target.value.substring(1);
                    }
                    onChange(target.value && target.value.length > 0 ? Number.parseInt(target.value) : undefined);
                  }}
                  error={!!get(errors, name)}
                  helperText={get(errors, name)?.message as string}
                  onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                  {...rest}
                />
              )}
            />

            <Button
              color='secondary'
              variant='outlined'
              fullWidth={isTablet || isMobile}
              startIcon={<IconTrash size={24} color='currentColor' />}
              onClick={() => handleRemoveProduct(itemIdx)}
              sx={!isMobile && !isTablet ? { alignSelf: 'flex-start', marginTop: theme.spacing(8) } : undefined}
            >
              {t('remove_product')}
            </Button>
          </TimesAndQuantityWrapper>

          {errors.products?.[itemIdx]?.productId?.message && (
            <FormHelperText error>{errors.products?.[itemIdx]?.productId?.message}</FormHelperText>
          )}

          {getExtraProductInformation(item.productId)?.archived && (
            <Alert severity='warning' sx={{ marginTop: theme.spacing(3), borderRadius: '0.5rem' }}>
              <Stack sx={{ gap: theme.spacing(2) }}>
                <Box>{t(`error.fields.products.archived.description`)}</Box>
              </Stack>
            </Alert>
          )}

          {fields.length > 1 && fields[0].discountPercentage && itemIdx === 0 ? (
            <ReplicateQuantity variant='smallSemiBold' onClick={onReplicateDiscount}>
              {t('replicate_discount')}
            </ReplicateQuantity>
          ) : null}
        </Container>
      ))}
    </Fragment>
  );
};

export default DynamicProductWithDiscount;
