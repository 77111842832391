import { Stack } from '@mui/material';
import { Fragment, useContext, useMemo } from 'react';

import { Search } from './Search';
import { FilterContext } from '@/contexts/FilterContext';
import FilterButton from '@/components/FilterDrawer/FilterButton';
import FilterDrawer, { ChildrenProps as FilterChildrenProps } from '@/components/FilterDrawer';
import { PageLayoutFilterSearchInputProps } from '@/layouts/PageLayout/types';
export type FilterFieldsType = (props: FilterChildrenProps) => JSX.Element;
type Props = {
  showSearchInput?: boolean;
  showFilterButton?: boolean;
  showFilterActions?: boolean;
  searchInputProps?: PageLayoutFilterSearchInputProps;
  FilterFields?: FilterFieldsType;
  filterFieldsProps?: Partial<FilterChildrenProps>;
};

export const PageLayoutFilterConfig = (props: Props) => {
  const {
    showFilterButton,
    showSearchInput,
    showFilterActions = true,
    FilterFields,
    filterFieldsProps,
    searchInputProps,
  } = props;

  const context = useContext(FilterContext);

  const totalOfFilters = useMemo(
    () =>
      filterFieldsProps?.filters &&
      Object.values(filterFieldsProps?.filters).filter((filter) => {
        if (Array.isArray(filter)) {
          return filter.length > 0;
        }
        return filter !== undefined && filter !== null;
      }).length,
    [filterFieldsProps],
  );

  return context ? (
    <Fragment>
      <Stack
        spacing={1}
        width='100%'
        direction='row'
        alignItems='center'
        justifyContent={showSearchInput ? undefined : 'flex-end'}
      >
        {showSearchInput ? <Search {...searchInputProps} /> : null}
        {showFilterButton ? <FilterButton filterCount={totalOfFilters} /> : null}
      </Stack>

      {FilterFields ? (
        <FilterDrawer childrenProps={filterFieldsProps} legacyActions={showFilterActions}>
          {FilterFields}
        </FilterDrawer>
      ) : null}
    </Fragment>
  ) : null;
};

export default PageLayoutFilterConfig;
