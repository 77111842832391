import { AccountContext } from '@/contexts/AccountContext';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { useContext } from 'react';

type FeatureFlagHook = {
  isReady: boolean;
  isFeatureEnabled: (feature: string) => boolean;
  getTreatment: (feature: string) => string;
};

const defaultFeatures = [
  'partnership_provider',
  'partnership_agent',
  'new-schedulings-page',
  'seat_maps',
  'partnership_product_showcase',
  'partnership_direct_sales',
  'show_old_schedulings_availabilities_pages',
  'sale_reschedule_modal',
  'PR-646-product-packages',
] as const;

export type DefaultFeatureFlags = (typeof defaultFeatures)[number];

export const useFeatureFlag = (featureNames = defaultFeatures): FeatureFlagHook => {
  const context = useContext(AccountContext);

  if (!context) {
    throw new Error('useFeatureFlag must be used within a AccountProvider');
  }

  const { treatments, isReady } = useSplitTreatments({ names: featureNames as unknown as string[] });

  const isFeatureEnabled = (feature: string): boolean => {
    if (isReady) {
      const featureTreatment = treatments[feature];

      if (!featureTreatment) {
        console.warn(`Feature flag ${feature} not found. Check if it is correctly configured.`);
        return false;
      }

      return featureTreatment.treatment === 'on';
    }

    return false;
  };

  const getTreatment = (feature: string): string => {
    if (isReady) {
      return treatments[feature].treatment;
    }

    return '';
  };

  return {
    isReady,
    isFeatureEnabled,
    getTreatment,
  };
};

export default useFeatureFlag;
