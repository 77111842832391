import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useSellerApi } from '@/hooks/useSellerApi';
import {
  DetailedDirectSaleItem,
  DirectSale,
  DirectSaleTransaction,
  DirectSalesApiGetDetailedDirectSaleItemsRequest,
  DirectSalesApiGetDirectSalePaymentOptionsRequest,
  DirectSalesApiGetDirectSaleRequest,
  PaymentMethodCode,
  PaymentOption,
  SaleStateEnum,
} from '@/services/SellerApi';
import { Amount, Card, Product, SaleIndex, SaleStatus, Total } from './styles';
import BackdropLoading from '@/components/BackdropLoading';
import { formatCents } from '@/utils';
import ProviderInfo from './ProviderInfo';
import Button from '@/components/Button';
import { Grid } from '@mui/material';
import PixIconWhite from '@/assets/Icons/pix-01-white.svg';
import { IconCreditCard, IconReceipt } from '@tabler/icons-react';
import Chip from '@/components/Chip';
import theme from '@/theme';
import { enqueueSnackbar } from 'notistack';
import { PaymentModals } from '@/components/Payment/PaymentModals';
import Tooltip from '@/components/Tooltip';
import useAuth from '@/contexts/Auth/hooks/useAuth';

type Props = {
  saleId: string;
  onPayment?: (saleId: string, transaction: DirectSaleTransaction) => void;
  missingPaymentCount: number;
};

export const SaleCard = (props: Props) => {
  const { saleId, onPayment = () => {}, missingPaymentCount } = props;

  const { sessionId } = useAuth();

  const { directSalesApi } = useSellerApi();

  const { track } = useAnalytics();
  const { t } = useTranslation(['showCaseCheckout', 'sales']);

  const [loadingDirectSale, setLoadingDirectSale] = useState<boolean>(false);
  const [loadingDirectSaleItems, setLoadingDirectSaleItems] = useState<boolean>(false);
  const [loadingPaymentOptions, setLoadingPaymentOptions] = useState<boolean>(false);
  const [directSale, setDirectSale] = useState<DirectSale | undefined>(undefined);
  const [directSaleItems, setDirectSaleItems] = useState<DetailedDirectSaleItem[]>([]);
  const [pixPaymentOption, setPixPaymentOption] = useState<PaymentOption | undefined>(undefined);
  const [creditCardPaymentOption, setCreditCardPaymentOption] = useState<PaymentOption | undefined>(undefined);
  const [postPaidPaymentOption, setPostPaidPaymentOption] = useState<PaymentOption | undefined>(undefined);
  const [openPixPaymentModal, setOpenPixPaymentModal] = useState<boolean>(false);
  const [openCreditCardPaymentModal, setOpenCreditCardPaymentModal] = useState<boolean>(false);
  const [openPostPaidPaymentModal, setOpenPostPaidPaymentModal] = useState<boolean>(false);
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<DirectSaleTransaction | undefined>(undefined);

  const getDirectSale = async (saleId: string) => {
    setLoadingDirectSale(true);
    const params: DirectSalesApiGetDirectSaleRequest = {
      saleId: saleId,
      include: ['app', 'customer', 'aggregatedVouchers'],
    };

    try {
      const { data } = await directSalesApi.getDirectSale(params);

      setDirectSale(data);
    } catch (error) {
      enqueueSnackbar(t('snackbar.could_not_retrieve_direct_sale'), {
        variant: 'error',
      });
    } finally {
      setLoadingDirectSale(false);
    }
  };

  const getDirectSaleItems = async (saleId: string) => {
    setLoadingDirectSaleItems(true);

    const params: DirectSalesApiGetDetailedDirectSaleItemsRequest = {
      saleId: saleId,
      offset: 0,
      limit: 100,
      include: [
        'product',
        'sale',
        'partnershipItem.partnership.provider',
        'partnershipItem.partnership.agent',
        'partnershipItem.partnership.publicProposal',
      ],
    };

    try {
      const { data } = await directSalesApi.getDetailedDirectSaleItems(params);

      setDirectSaleItems(data);
    } catch (error) {
      enqueueSnackbar(t('snackbar.could_not_retrieve_direct_sale_items'), {
        variant: 'error',
      });
    } finally {
      setLoadingDirectSaleItems(false);
    }
  };

  const getPaymentOptions = async (saleId: string) => {
    setLoadingPaymentOptions(true);
    const params: DirectSalesApiGetDirectSalePaymentOptionsRequest = {
      saleId: saleId,
      sessionId,
    };

    try {
      const { data } = await directSalesApi.getDirectSalePaymentOptions(params);

      setPixPaymentOption(data.find((option) => option.paymentMethod.code === PaymentMethodCode.Pix));
      setCreditCardPaymentOption(data.find((option) => option.paymentMethod.code === PaymentMethodCode.CreditCard));
      setPostPaidPaymentOption(data.find((option) => option.paymentMethod.code === PaymentMethodCode.PostPaid));
    } catch (error) {
      enqueueSnackbar(t('snackbar.could_not_retrieve_direct_sale_payment_options'), {
        variant: 'error',
      });
    } finally {
      setLoadingPaymentOptions(false);
    }
  };

  const handlePayUsingPix = () => {
    track('showCaseCheckoutPage/payUsingPix', { saleId });

    setOpenPixPaymentModal(true);
  };
  const handlePayUsingCreditCard = () => {
    track('showCaseCheckoutPage/payUsingCredicard', { saleId });

    setOpenCreditCardPaymentModal(true);
  };
  const handlePayUsingPostPaid = () => {
    track('showCaseCheckoutPage/payUsingPostPaid', { saleId });

    setOpenPostPaidPaymentModal(true);
  };

  const handlePaymentSuccess = (transaction: DirectSaleTransaction) => {
    setOpenPixPaymentModal(false);
    setOpenPostPaidPaymentModal(false);
    setOpenCreditCardPaymentModal(false);
    setOpenPaymentSuccess(true);

    getDirectSale(saleId);

    setTransaction(transaction);

    onPayment(saleId, transaction);
    track('showCaseCheckoutPage/paymentSuccess', { saleId, transaction });
  };

  const getSaleStatusColor = (status: SaleStateEnum) => {
    switch (status) {
      case SaleStateEnum.Created:
        return { font: theme.palette.secondary[700], background: theme.palette.secondary[100] };
      case SaleStateEnum.Pending:
        return { font: theme.palette.secondary[700], background: theme.palette.secondary[100] };
      case SaleStateEnum.Expired:
        return { font: theme.palette.colors.gray[700], background: theme.palette.colors.gray[100] };
      case SaleStateEnum.PaymentComplete:
        return { font: theme.palette.colors.green[700], background: theme.palette.colors.green[100] };
      default:
        return undefined;
    }
  };

  useEffect(() => {
    getDirectSale(saleId);
    getDirectSaleItems(saleId);
    getPaymentOptions(saleId);
  }, []);

  return (
    <Card sx={{ p: 2, position: 'relative' }}>
      {loadingPaymentOptions || loadingDirectSale || loadingDirectSaleItems ? <BackdropLoading /> : null}

      <Grid container spacing={{ xs: 2, md: 4 }}>
        <Grid item flexGrow={1}>
          {directSale ? (
            <Grid container justifyContent='space-between' alignItems='center' flexDirection='row'>
              <Grid item>
                <SaleStatus display='flex' alignItems='center'>
                  <SaleIndex variant='smallSemiBold'>
                    {t('payment.purchase_number', { number: directSale.position })}
                  </SaleIndex>

                  <Chip
                    size='small'
                    color={getSaleStatusColor(directSale.currentState)}
                    variant='outlined'
                    label={t(`status.${directSale.currentState}`, {
                      ns: 'sales',
                    })}
                  />
                </SaleStatus>

                {directSaleItems.map((item) => (
                  <Product key={item.id} variant='regularBold'>
                    {item.product?.name}
                  </Product>
                ))}

                <ProviderInfo provider={directSale.app!} />
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
                <Grid container flexDirection={{ xs: 'row', sm: 'row', md: 'column' }} justifyContent='space-between'>
                  <Grid item md={12}>
                    <Total variant='regularSemiBold'>{t('payment.total')}</Total>
                  </Grid>

                  <Grid item md={12}>
                    <Amount variant='h6'>{formatCents(directSale.amountCents)}</Amount>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        {!transaction ? (
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent={'space-between'}>
              <Tooltip
                title={t('payment.disabled_post_paid')}
                placement='top'
                arrow
                disableFocusListener={!!postPaidPaymentOption}
                disableHoverListener={!!postPaidPaymentOption}
                disableTouchListener={!!postPaidPaymentOption}
                disableInteractive={!!postPaidPaymentOption}
              >
                <Grid item>
                  <Button
                    fullWidth
                    variant='outlined'
                    color='primary'
                    disabled={!postPaidPaymentOption || directSale?.currentState === SaleStateEnum.Expired}
                    onClick={handlePayUsingPostPaid}
                  >
                    <IconReceipt size='24px' style={{ marginRight: '8px' }} />

                    {t('payment.pay_using_post_paid')}
                  </Button>
                </Grid>
              </Tooltip>

              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      fullWidth
                      variant='outlined'
                      color='primary'
                      disabled={!creditCardPaymentOption || directSale?.currentState === SaleStateEnum.Expired}
                      onClick={handlePayUsingCreditCard}
                    >
                      <IconCreditCard size='24px' style={{ marginRight: '8px' }} />

                      {t('payment.pay_using_credit_card')}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      disabled={!pixPaymentOption || directSale?.currentState === SaleStateEnum.Expired}
                      onClick={handlePayUsingPix}
                    >
                      <img src={PixIconWhite} alt={t('payment.pay_using_pix')} style={{ marginRight: '8px' }} />

                      {t('payment.pay_using_pix')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <PaymentModals
        directSale={directSale}
        openPaymentSuccess={openPaymentSuccess}
        missingPaymentCount={missingPaymentCount}
        handlePaymentSuccess={handlePaymentSuccess}
        openPixPaymentModal={openPixPaymentModal}
        openCreditCardPaymentModal={openCreditCardPaymentModal}
        openPostPaidPaymentModal={openPostPaidPaymentModal}
        onClosePaymentSuccess={() => setOpenPaymentSuccess(false)}
        onClosePixPaymentModal={() => setOpenPixPaymentModal(false)}
        onCloseCreditCardPaymentModal={() => setOpenCreditCardPaymentModal(false)}
        onClosePostPaidPaymentModal={() => setOpenPostPaidPaymentModal(false)}
      />
    </Card>
  );
};

export default SaleCard;
