/* eslint-disable react-refresh/only-export-components */
import { ReactNode, Suspense, Fragment } from 'react';
import { RouteObject, LoaderFunctionArgs } from 'react-router-dom';

import Loading from '@/components/Loading';
import ProtectedRoute from '@/routes/ProtectedRoute';
import policyCodeToRule from '@/utils/policyCodeToRule';

// const LazyListPage = lazy(() => import('./pages/ListPage'));
// const LazyProductPackageTable = lazy(() => import('./components/ProductPackageTable'));
// const LazyFormPage = lazy(() => import('./pages/FormPage'));

import LazyListPage from './pages/ListPage';
import LazyFormPage from './pages/FormPage';
import Bugsnag from '@/services/bugsnag/index.ts';
import { NotifiableError } from '@bugsnag/js';
import loadProductPackagesTranslations from '@/features/productPackages/locales/loadProductPackagesTranslations';
import loadProductsTranslations from '@/features/products/locales/loadProductsTranslations';
import getItemsFromUrl from '@/hooks/getItemsFromUrl';
import { getProduct } from '@/services/sellerApiQuery/api/products/products';
import { AppPolicyRules } from '@/interfaces/rules';
import { PolicyRule } from '@/services/sellerApiQuery/model';

const SuspenseElement = ({ children }: { children: ReactNode }) => (
  <Suspense fallback={<Loading />}>{children}</Suspense>
);

const FEATURE_FLAG = 'PR-646-product-packages';
const PRODUCT_PACKAGES_POLICIES: PolicyRule[] = [policyCodeToRule('productPackages:GetAppProductPackages')];

const buildRouter = (
  path: string,
  policies: AppPolicyRules,
  element: JSX.Element,
  children?: RouteObject[],
): RouteObject => {
  return {
    path,
    loader: async (args: LoaderFunctionArgs<unknown>) => {
      try {
        loadProductPackagesTranslations();
        loadProductsTranslations();

        const url = new URL(args.request.url);
        return getItemsFromUrl(url, getProduct, 'products');
      } catch (error) {
        Bugsnag.notify(error as NotifiableError);
        return false;
      }
    },
    element: <ProtectedRoute featureFlag={FEATURE_FLAG} policies={policies} element={element} />,
    children,
  };
};

export const productPackagesRouters: RouteObject[] = [
  buildRouter(
    'productPackages',
    {
      OR: [...PRODUCT_PACKAGES_POLICIES, policyCodeToRule('productPackages:GetProductPackage')],
    },
    <SuspenseElement>
      <LazyListPage />
    </SuspenseElement>,
    [buildRouter('archived', { OR: PRODUCT_PACKAGES_POLICIES }, <Fragment />)],
  ),
  buildRouter(
    'productPackages/create',
    {
      AND: [...PRODUCT_PACKAGES_POLICIES, policyCodeToRule('productPackages:CreateProductPackage')],
    },
    <SuspenseElement>
      <LazyFormPage />
    </SuspenseElement>,
  ),
  buildRouter(
    'productPackages/:id/edit',
    {
      AND: [
        ...PRODUCT_PACKAGES_POLICIES,
        policyCodeToRule('productPackages:UpdateProductPackage'),
        policyCodeToRule('productPackageProducts:GetProductPackageProducts'),
      ],
    },
    <SuspenseElement>
      <LazyFormPage />
    </SuspenseElement>,
  ),
];
