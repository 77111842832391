/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */
import {
  z as zod
} from 'zod'


export const authSignupBodyPasswordMin = 8;


export const authSignupBody = zod.object({
  "name": zod.string(),
  "email": zod.string().email(),
  "password": zod.string().min(authSignupBodyPasswordMin),
  "acceptTerms": zod.boolean()
})


export const authLoginBody = zod.object({
  "email": zod.string(),
  "password": zod.string(),
  "keepConnected": zod.boolean().optional()
})

export const authLoginResponse = zod.object({
  "user": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "email": zod.string().email().optional(),
  "createdAt": zod.string().datetime(),
  "isSuperAdmin": zod.boolean().optional()
}),
  "token": zod.object({
  "token": zod.string(),
  "expiresAt": zod.string().datetime()
})
})


export const getTermsOfUseResponse = zod.object({
  "content": zod.object({
  "components": zod.array(zod.object({
  "elements": zod.array(zod.object({
  "text": zod.string(),
  "bold": zod.boolean(),
  "underline": zod.boolean(),
  "linkUrl": zod.string().nullable()
})),
  "namedStyleType": zod.string(),
  "listId": zod.string().nullable()
}))
})
})


export const getPrivacyPolicyResponse = zod.object({
  "content": zod.object({
  "components": zod.array(zod.object({
  "elements": zod.array(zod.object({
  "text": zod.string(),
  "bold": zod.boolean(),
  "underline": zod.boolean(),
  "linkUrl": zod.string().nullable()
})),
  "namedStyleType": zod.string(),
  "listId": zod.string().nullable()
}))
})
})


export const authResetPasswordBody = zod.object({
  "email": zod.string().email()
})


export const authValidateResetPasswordCodeParams = zod.object({
  "code": zod.string()
})


export const authRecoverPasswordBodyNewPasswordMin = 8;


export const authRecoverPasswordBody = zod.object({
  "code": zod.string(),
  "newPassword": zod.string().min(authRecoverPasswordBodyNewPasswordMin)
})

export const authRecoverPasswordResponse = zod.object({
  "user": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "email": zod.string().email().optional(),
  "createdAt": zod.string().datetime(),
  "isSuperAdmin": zod.boolean().optional()
}),
  "token": zod.object({
  "token": zod.string(),
  "expiresAt": zod.string().datetime()
})
})


export const getOwnUserResponse = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "email": zod.string().email().optional(),
  "createdAt": zod.string().datetime(),
  "isSuperAdmin": zod.boolean().optional()
})


export const getOwnUserAccountsQueryParams = zod.object({
  "include": zod.array(zod.enum(['app', 'app.plan', 'role'])).optional()
})

export const getOwnUserAccountsResponseItem = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).nullish(),
  "roleId": zod.string(),
  "role": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "isManaged": zod.boolean()
}).nullish()
})
export const getOwnUserAccountsResponse = zod.array(getOwnUserAccountsResponseItem)


export const getRolePoliciesResponseItem = zod.object({
  "namespace": zod.enum(['apps', 'sales', 'saleItems', 'tariffGroups', 'customers', 'reservations', 'vouchers', 'products', 'saleAdditionals', 'incomeSchedulings', 'refundRequests', 'directLinks', 'siteConfigs', 'paymentMethods', 'availabilities', 'saleOrigins', 'schedulings', 'partnerships', 'coupons', 'tags', 'reservationTariffs', 'arrangedProducts', 'directSales', 'couponProducts', 'productCategories', 'productPackages', 'productPackageProducts', 'productPackageCategories']),
  "action": zod.string(),
  "code": zod.string()
})
export const getRolePoliciesResponse = zod.array(getRolePoliciesResponseItem)


export const createAppBodyExpectedMonthlyRevenueCentsMin = 0;


export const createAppBody = zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "corporateName": zod.string().optional(),
  "openedAt": zod.string().date().nullish(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "bank": zod.object({
  "code": zod.string().nullable(),
  "agency": zod.string().nullable(),
  "account": zod.string().nullable(),
  "identifier": zod.string().nullable(),
  "identifierType": zod.enum(['cpf', 'cnpj']).nullable(),
  "accountType": zod.enum(['conta_corrente', 'poupanca']).nullish()
}).nullish(),
  "merchantCategoryCode": zod.string().nullish(),
  "establishmentType": zod.string().nullish(),
  "establishmentTypeId": zod.string().nullish(),
  "owner": zod.object({
  "name": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "cpf": zod.string().nullable()
}).optional(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullish(),
  "expectedMonthlyRevenueCents": zod.number().min(createAppBodyExpectedMonthlyRevenueCentsMin).nullish()
})


export const getAppQueryParams = zod.object({
  "include": zod.array(zod.enum(['plan', 'info'])).optional()
})

export const getAppResponse = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
})


export const updateAppInfoQueryParams = zod.object({
  "include": zod.array(zod.enum(['plan', 'info'])).optional()
})

export const updateAppInfoBodyExpectedMonthlyRevenueCentsMin = 0;


export const updateAppInfoBody = zod.object({
  "identifier": zod.string().optional(),
  "identifierType": zod.enum(['cpf', 'cnpj']).optional(),
  "fantasyName": zod.string().optional(),
  "corporateName": zod.string().optional(),
  "openedAt": zod.string().date().optional(),
  "contact": zod.object({
  "name": zod.string().optional(),
  "phone": zod.string().optional()
}).optional(),
  "bank": zod.object({
  "code": zod.string().optional(),
  "agency": zod.string().optional(),
  "account": zod.string().optional(),
  "identifier": zod.string().optional(),
  "identifierType": zod.enum(['cpf', 'cnpj']).optional(),
  "accountType": zod.enum(['conta_corrente', 'poupanca']).optional()
}).optional(),
  "merchantCategoryCode": zod.string().optional(),
  "establishmentType": zod.string().optional(),
  "establishmentTypeId": zod.string().optional(),
  "owner": zod.object({
  "name": zod.string().optional(),
  "birthdate": zod.string().date().optional(),
  "cpf": zod.string().optional()
}).optional(),
  "address": zod.object({
  "zipCode": zod.string().optional(),
  "uf": zod.string().optional(),
  "city": zod.string().optional(),
  "district": zod.string().optional(),
  "street": zod.string().optional(),
  "number": zod.string().optional(),
  "complement": zod.string().optional()
}).optional(),
  "expectedMonthlyRevenueCents": zod.number().min(updateAppInfoBodyExpectedMonthlyRevenueCentsMin).optional(),
  "submitForAnalysis": zod.boolean()
})

export const updateAppInfoResponse = zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "corporateName": zod.string().nullable(),
  "merchantCategoryCode": zod.string().nullable(),
  "establishmentType": zod.string().nullable(),
  "establishmentTypeId": zod.string().nullish(),
  "openedAt": zod.string().date().nullable(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "bank": zod.object({
  "code": zod.string().nullable(),
  "agency": zod.string().nullable(),
  "account": zod.string().nullable(),
  "identifier": zod.string().nullable(),
  "identifierType": zod.enum(['cpf', 'cnpj']).nullable(),
  "accountType": zod.enum(['conta_corrente', 'poupanca']).nullish()
}).nullable(),
  "owner": zod.object({
  "name": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "cpf": zod.string().nullable()
}).nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing']),
  "notificationEmails": zod.array(zod.string()).nullable(),
  "supportEmail": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const getAppInfoResponse = zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "corporateName": zod.string().nullable(),
  "merchantCategoryCode": zod.string().nullable(),
  "establishmentType": zod.string().nullable(),
  "establishmentTypeId": zod.string().nullish(),
  "openedAt": zod.string().date().nullable(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "bank": zod.object({
  "code": zod.string().nullable(),
  "agency": zod.string().nullable(),
  "account": zod.string().nullable(),
  "identifier": zod.string().nullable(),
  "identifierType": zod.enum(['cpf', 'cnpj']).nullable(),
  "accountType": zod.enum(['conta_corrente', 'poupanca']).nullish()
}).nullable(),
  "owner": zod.object({
  "name": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "cpf": zod.string().nullable()
}).nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing']),
  "notificationEmails": zod.array(zod.string()).nullable(),
  "supportEmail": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const getAppBuyerTermsOfUseResponse = zod.object({
  "content": zod.object({
  "components": zod.array(zod.object({
  "elements": zod.array(zod.object({
  "text": zod.string(),
  "bold": zod.boolean(),
  "underline": zod.boolean(),
  "linkUrl": zod.string().nullable()
})),
  "namedStyleType": zod.string(),
  "listId": zod.string().nullable()
}))
})
})


export const getAppBuyerPrivacyPolicyResponse = zod.object({
  "content": zod.object({
  "components": zod.array(zod.object({
  "elements": zod.array(zod.object({
  "text": zod.string(),
  "bold": zod.boolean(),
  "underline": zod.boolean(),
  "linkUrl": zod.string().nullable()
})),
  "namedStyleType": zod.string(),
  "listId": zod.string().nullable()
}))
})
})


export const getAppSiteTemplateDataResponse = zod.record(zod.string(), zod.any())


export const getAppPaymentMethodsQueryOffsetMin = 0;
export const getAppPaymentMethodsQueryLimitMax = 100;


export const getAppPaymentMethodsQueryParams = zod.object({
  "offset": zod.number().min(getAppPaymentMethodsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppPaymentMethodsQueryLimitMax).optional()
})

export const getAppPaymentMethodsResponseItem = zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppPaymentMethodsResponse = zod.array(getAppPaymentMethodsResponseItem)


export const getAppSaleStateChangesQueryOffsetMin = 0;
export const getAppSaleStateChangesQueryLimitMax = 100;


export const getAppSaleStateChangesQueryParams = zod.object({
  "offset": zod.number().min(getAppSaleStateChangesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppSaleStateChangesQueryLimitMax).optional(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "include": zod.array(zod.enum(['sale', 'sale.customer'])).optional(),
  "sortBy": zod.enum(['recent', 'oldest']).optional()
})

export const getAppSaleStateChangesResponseSaleAmountCentsMin = 0;
export const getAppSaleStateChangesResponseSalePartialAmountCentsMin = 0;
export const getAppSaleStateChangesResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppSaleStateChangesResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppSaleStateChangesResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppSaleStateChangesResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppSaleStateChangesResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getAppSaleStateChangesResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getAppSaleStateChangesResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppSaleStateChangesResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getAppSaleStateChangesResponseItem = zod.object({
  "id": zod.string(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getAppSaleStateChangesResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getAppSaleStateChangesResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppSaleStateChangesResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getAppSaleStateChangesResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppSaleStateChangesResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppSaleStateChangesResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppSaleStateChangesResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getAppSaleStateChangesResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppSaleStateChangesResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getAppSaleStateChangesResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "stateFrom": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']).nullable(),
  "stateTo": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "createdAt": zod.string().datetime()
})
export const getAppSaleStateChangesResponse = zod.array(getAppSaleStateChangesResponseItem)


export const getAppProductsQueryOffsetMin = 0;
export const getAppProductsQueryLimitMax = 100;


export const getAppProductsQueryParams = zod.object({
  "offset": zod.number().min(getAppProductsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppProductsQueryLimitMax).optional(),
  "search": zod.string().optional(),
  "ids": zod.array(zod.string()).optional(),
  "activeOnly": zod.boolean().optional(),
  "sellingModes": zod.array(zod.enum(['with_date_and_time', 'with_date_only', 'without_date'])).optional()
})

export const getAppProductsResponseTariffSettingsMinSaleQuantityMin = 0;

export const getAppProductsResponseTariffSettingsMinSaleQuantityMax = 100;
export const getAppProductsResponseTariffSettingsMaxSaleQuantityMin = 0;

export const getAppProductsResponseTariffSettingsMaxSaleQuantityMax = 100;
export const getAppProductsResponseMinSaleTariffQuantityMin = 0;

export const getAppProductsResponseMinSaleTariffQuantityMax = 100;
export const getAppProductsResponseMaxSaleTariffQuantityMin = 0;

export const getAppProductsResponseMaxSaleTariffQuantityMax = 100;


export const getAppProductsResponseItem = zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppProductsResponseTariffSettingsMinSaleQuantityMin).max(getAppProductsResponseTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppProductsResponseTariffSettingsMaxSaleQuantityMin).max(getAppProductsResponseTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppProductsResponseMinSaleTariffQuantityMin).max(getAppProductsResponseMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppProductsResponseMaxSaleTariffQuantityMin).max(getAppProductsResponseMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppProductsResponse = zod.array(getAppProductsResponseItem)


export const createAppProductBodyTariffSettingsMinSaleQuantityMin = 0;

export const createAppProductBodyTariffSettingsMinSaleQuantityMax = 100;
export const createAppProductBodyTariffSettingsMaxSaleQuantityMin = 0;

export const createAppProductBodyTariffSettingsMaxSaleQuantityMax = 100;


export const createAppProductBody = zod.object({
  "active": zod.boolean(),
  "name": zod.string(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "internalName": zod.string().nullish(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(createAppProductBodyTariffSettingsMinSaleQuantityMin).max(createAppProductBodyTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(createAppProductBodyTariffSettingsMaxSaleQuantityMin).max(createAppProductBodyTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
})
})


export const getProductResponseTariffSettingsMinSaleQuantityMin = 0;

export const getProductResponseTariffSettingsMinSaleQuantityMax = 100;
export const getProductResponseTariffSettingsMaxSaleQuantityMin = 0;

export const getProductResponseTariffSettingsMaxSaleQuantityMax = 100;
export const getProductResponseMinSaleTariffQuantityMin = 0;

export const getProductResponseMinSaleTariffQuantityMax = 100;
export const getProductResponseMaxSaleTariffQuantityMin = 0;

export const getProductResponseMaxSaleTariffQuantityMax = 100;


export const getProductResponse = zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductResponseTariffSettingsMinSaleQuantityMin).max(getProductResponseTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductResponseTariffSettingsMaxSaleQuantityMin).max(getProductResponseTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductResponseMinSaleTariffQuantityMin).max(getProductResponseMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductResponseMaxSaleTariffQuantityMin).max(getProductResponseMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const getProductTariffGroupsQueryOffsetMin = 0;
export const getProductTariffGroupsQueryLimitMax = 100;


export const getProductTariffGroupsQueryParams = zod.object({
  "offset": zod.number().min(getProductTariffGroupsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getProductTariffGroupsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product', 'priceInfo'])).optional()
})

export const getProductTariffGroupsResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductTariffGroupsResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductTariffGroupsResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductTariffGroupsResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductTariffGroupsResponseProductMinSaleTariffQuantityMin = 0;

export const getProductTariffGroupsResponseProductMinSaleTariffQuantityMax = 100;
export const getProductTariffGroupsResponseProductMaxSaleTariffQuantityMin = 0;

export const getProductTariffGroupsResponseProductMaxSaleTariffQuantityMax = 100;


export const getProductTariffGroupsResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductTariffGroupsResponseProductTariffSettingsMinSaleQuantityMin).max(getProductTariffGroupsResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductTariffGroupsResponseProductTariffSettingsMaxSaleQuantityMin).max(getProductTariffGroupsResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductTariffGroupsResponseProductMinSaleTariffQuantityMin).max(getProductTariffGroupsResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductTariffGroupsResponseProductMaxSaleTariffQuantityMin).max(getProductTariffGroupsResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})
export const getProductTariffGroupsResponse = zod.array(getProductTariffGroupsResponseItem)


export const createProductTariffGroupBody = zod.object({
  "name": zod.string()
})


export const getProductNextSchedulingQueryParams = zod.object({
  "include": zod.array(zod.enum(['tariffGroup', 'tariffGroup.priceInfo', 'finalTariffGroup', 'finalTariffGroup.priceInfo', 'product', 'product.defaultTariffGroupPriceInfo', 'aggregatedRecurrenceCount'])).optional()
})

export const getProductNextSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductNextSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductNextSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductNextSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductNextSchedulingResponseTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getProductNextSchedulingResponseTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getProductNextSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getProductNextSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getProductNextSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductNextSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductNextSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductNextSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductNextSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getProductNextSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getProductNextSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getProductNextSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getProductNextSchedulingResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductNextSchedulingResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductNextSchedulingResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductNextSchedulingResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductNextSchedulingResponseProductMinSaleTariffQuantityMin = 0;

export const getProductNextSchedulingResponseProductMinSaleTariffQuantityMax = 100;
export const getProductNextSchedulingResponseProductMaxSaleTariffQuantityMin = 0;

export const getProductNextSchedulingResponseProductMaxSaleTariffQuantityMax = 100;


export const getProductNextSchedulingResponse = zod.object({
  "id": zod.string(),
  "beginDate": zod.string().date(),
  "consumableFromTime": zod.string().nullish(),
  "consumableToTime": zod.string().nullish(),
  "calendarIntervalMinutes": zod.number().nullish(),
  "antecedenceHours": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish(),
  "antecedenceHoursReferenceTime": zod.string().nullish(),
  "durationMinutes": zod.number(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "tariffGroupId": zod.string().nullable(),
  "tariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductNextSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getProductNextSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductNextSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getProductNextSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductNextSchedulingResponseTariffGroupProductMinSaleTariffQuantityMin).max(getProductNextSchedulingResponseTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductNextSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMin).max(getProductNextSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "finalTariffGroupId": zod.string().nullish(),
  "finalTariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductNextSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getProductNextSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductNextSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getProductNextSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductNextSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMin).max(getProductNextSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductNextSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMin).max(getProductNextSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductNextSchedulingResponseProductTariffSettingsMinSaleQuantityMin).max(getProductNextSchedulingResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductNextSchedulingResponseProductTariffSettingsMaxSaleQuantityMin).max(getProductNextSchedulingResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductNextSchedulingResponseProductMinSaleTariffQuantityMin).max(getProductNextSchedulingResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductNextSchedulingResponseProductMaxSaleTariffQuantityMin).max(getProductNextSchedulingResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceModifierId": zod.string().nullable(),
  "aggregatedRecurrenceCount": zod.object({
  "total": zod.number(),
  "previous": zod.number(),
  "next": zod.number(),
  "futurePrevious": zod.number()
}).optional(),
  "appliesAt": zod.string().date(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const getArrangedProductDetailedDefaultTariffGroupQueryParams = zod.object({
  "include": zod.array(zod.enum(['tariffs', 'tariffs.type', 'tariffs.group', 'tariffs.product', 'priceInfo'])).optional()
})

export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductMinSaleTariffQuantityMin = 0;

export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductMinSaleTariffQuantityMax = 100;
export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductMaxSaleTariffQuantityMin = 0;

export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductMaxSaleTariffQuantityMax = 100;
export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMinSaleTariffQuantityMin = 0;

export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMinSaleTariffQuantityMax = 100;
export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMaxSaleTariffQuantityMin = 0;

export const getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMaxSaleTariffQuantityMax = 100;


export const getArrangedProductDetailedDefaultTariffGroupResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().optional(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMinSaleQuantityMin).max(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin).max(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductMinSaleTariffQuantityMin).max(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductMaxSaleTariffQuantityMin).max(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin).max(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin).max(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMinSaleTariffQuantityMin).max(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMaxSaleTariffQuantityMin).max(getArrangedProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}))
})


export const getTariffGroupQueryParams = zod.object({
  "include": zod.array(zod.enum(['product', 'priceInfo'])).optional()
})

export const getTariffGroupResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getTariffGroupResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getTariffGroupResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getTariffGroupResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getTariffGroupResponseProductMinSaleTariffQuantityMin = 0;

export const getTariffGroupResponseProductMinSaleTariffQuantityMax = 100;
export const getTariffGroupResponseProductMaxSaleTariffQuantityMin = 0;

export const getTariffGroupResponseProductMaxSaleTariffQuantityMax = 100;


export const getTariffGroupResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getTariffGroupResponseProductTariffSettingsMinSaleQuantityMin).max(getTariffGroupResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getTariffGroupResponseProductTariffSettingsMaxSaleQuantityMin).max(getTariffGroupResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getTariffGroupResponseProductMinSaleTariffQuantityMin).max(getTariffGroupResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getTariffGroupResponseProductMaxSaleTariffQuantityMin).max(getTariffGroupResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})


export const getTariffGroupTariffsQueryOffsetMin = 0;
export const getTariffGroupTariffsQueryLimitMax = 100;


export const getTariffGroupTariffsQueryParams = zod.object({
  "offset": zod.number().min(getTariffGroupTariffsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getTariffGroupTariffsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['type', 'group'])).optional()
})

export const getTariffGroupTariffsResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getTariffGroupTariffsResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getTariffGroupTariffsResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getTariffGroupTariffsResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getTariffGroupTariffsResponseProductMinSaleTariffQuantityMin = 0;

export const getTariffGroupTariffsResponseProductMinSaleTariffQuantityMax = 100;
export const getTariffGroupTariffsResponseProductMaxSaleTariffQuantityMin = 0;

export const getTariffGroupTariffsResponseProductMaxSaleTariffQuantityMax = 100;
export const getTariffGroupTariffsResponseGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getTariffGroupTariffsResponseGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getTariffGroupTariffsResponseGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getTariffGroupTariffsResponseGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getTariffGroupTariffsResponseGroupProductMinSaleTariffQuantityMin = 0;

export const getTariffGroupTariffsResponseGroupProductMinSaleTariffQuantityMax = 100;
export const getTariffGroupTariffsResponseGroupProductMaxSaleTariffQuantityMin = 0;

export const getTariffGroupTariffsResponseGroupProductMaxSaleTariffQuantityMax = 100;


export const getTariffGroupTariffsResponseItem = zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getTariffGroupTariffsResponseProductTariffSettingsMinSaleQuantityMin).max(getTariffGroupTariffsResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getTariffGroupTariffsResponseProductTariffSettingsMaxSaleQuantityMin).max(getTariffGroupTariffsResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getTariffGroupTariffsResponseProductMinSaleTariffQuantityMin).max(getTariffGroupTariffsResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getTariffGroupTariffsResponseProductMaxSaleTariffQuantityMin).max(getTariffGroupTariffsResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getTariffGroupTariffsResponseGroupProductTariffSettingsMinSaleQuantityMin).max(getTariffGroupTariffsResponseGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getTariffGroupTariffsResponseGroupProductTariffSettingsMaxSaleQuantityMin).max(getTariffGroupTariffsResponseGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getTariffGroupTariffsResponseGroupProductMinSaleTariffQuantityMin).max(getTariffGroupTariffsResponseGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getTariffGroupTariffsResponseGroupProductMaxSaleTariffQuantityMin).max(getTariffGroupTariffsResponseGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})
export const getTariffGroupTariffsResponse = zod.array(getTariffGroupTariffsResponseItem)


export const createTariffGroupTariffBodyPriceCentsMin = 0;
export const createTariffGroupTariffBodyOldPriceCentsMin = 0;
export const createTariffGroupTariffBodyDescriptionMax = 180;


export const createTariffGroupTariffBody = zod.object({
  "tariffTypeId": zod.string(),
  "priceCents": zod.number().min(createTariffGroupTariffBodyPriceCentsMin),
  "isDependent": zod.boolean(),
  "oldPriceCents": zod.number().min(createTariffGroupTariffBodyOldPriceCentsMin).nullish(),
  "description": zod.string().max(createTariffGroupTariffBodyDescriptionMax).nullish()
})


export const getTariffQueryParams = zod.object({
  "include": zod.array(zod.enum(['type', 'group'])).optional()
})

export const getTariffResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getTariffResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getTariffResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getTariffResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getTariffResponseProductMinSaleTariffQuantityMin = 0;

export const getTariffResponseProductMinSaleTariffQuantityMax = 100;
export const getTariffResponseProductMaxSaleTariffQuantityMin = 0;

export const getTariffResponseProductMaxSaleTariffQuantityMax = 100;
export const getTariffResponseGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getTariffResponseGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getTariffResponseGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getTariffResponseGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getTariffResponseGroupProductMinSaleTariffQuantityMin = 0;

export const getTariffResponseGroupProductMinSaleTariffQuantityMax = 100;
export const getTariffResponseGroupProductMaxSaleTariffQuantityMin = 0;

export const getTariffResponseGroupProductMaxSaleTariffQuantityMax = 100;


export const getTariffResponse = zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getTariffResponseProductTariffSettingsMinSaleQuantityMin).max(getTariffResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getTariffResponseProductTariffSettingsMaxSaleQuantityMin).max(getTariffResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getTariffResponseProductMinSaleTariffQuantityMin).max(getTariffResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getTariffResponseProductMaxSaleTariffQuantityMin).max(getTariffResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getTariffResponseGroupProductTariffSettingsMinSaleQuantityMin).max(getTariffResponseGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getTariffResponseGroupProductTariffSettingsMaxSaleQuantityMin).max(getTariffResponseGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getTariffResponseGroupProductMinSaleTariffQuantityMin).max(getTariffResponseGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getTariffResponseGroupProductMaxSaleTariffQuantityMin).max(getTariffResponseGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})


export const createAppTariffTypeBody = zod.object({
  "name": zod.string()
})


export const getAppTariffTypesQueryOffsetMin = 0;
export const getAppTariffTypesQueryLimitMax = 100;


export const getAppTariffTypesQueryParams = zod.object({
  "offset": zod.number().min(getAppTariffTypesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppTariffTypesQueryLimitMax).optional(),
  "search": zod.string().optional()
})

export const getAppTariffTypesResponseItem = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppTariffTypesResponse = zod.array(getAppTariffTypesResponseItem)


export const getTariffTypeResponse = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const getAppPriceModifiersQueryOffsetMin = 0;
export const getAppPriceModifiersQueryLimitMax = 100;


export const getAppPriceModifiersQueryParams = zod.object({
  "offset": zod.number().min(getAppPriceModifiersQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppPriceModifiersQueryLimitMax).optional()
})

export const getAppPriceModifiersResponseItem = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "componentTypes": zod.array(zod.enum(['tariff'])),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})
export const getAppPriceModifiersResponse = zod.array(getAppPriceModifiersResponseItem)


export const createAppPriceModifierBodyMutatorsItemConditionsItemDateDiffMin = 0;
export const createAppPriceModifierBodyMutatorsItemConditionsItemOccupationRateMin = 0;

export const createAppPriceModifierBodyMutatorsItemConditionsItemOccupationRateMax = 1;


export const createAppPriceModifierBody = zod.object({
  "name": zod.string(),
  "componentTypes": zod.array(zod.enum(['tariff'])),
  "mutators": zod.array(zod.object({
  "description": zod.string().nullable(),
  "conditions": zod.array(zod.object({
  "operator": zod.enum(['evaluating_tariff', 'check_products', 'check_tariff_types', 'check_tariff_groups', 'schedule_datetime_gte', 'schedule_datetime_lte', 'purchase_datetime_gte', 'purchase_datetime_lte', 'schedule_date_diff_gte', 'schedule_date_diff_lte', 'tariff_occupation_gte', 'tariff_occupation_lte', 'product_occupation_gte', 'product_occupation_lte']),
  "negative": zod.boolean(),
  "datetime": zod.string().datetime().optional(),
  "dateDiff": zod.number().min(createAppPriceModifierBodyMutatorsItemConditionsItemDateDiffMin).optional(),
  "occupationRate": zod.number().min(createAppPriceModifierBodyMutatorsItemConditionsItemOccupationRateMin).max(createAppPriceModifierBodyMutatorsItemConditionsItemOccupationRateMax).optional(),
  "productIds": zod.array(zod.string()).optional(),
  "tariffTypeIds": zod.array(zod.string()).optional(),
  "tariffGroupIds": zod.array(zod.string()).optional()
})),
  "actuator": zod.object({
  "operator": zod.enum(['set_value_cents', 'modify_percent', 'modify_cents', 'return_value', 'set_max_cents', 'set_min_cents']),
  "valueCents": zod.number().optional(),
  "valuePercent": zod.number().optional(),
  "roundingMethod": zod.enum(['round', 'ceil', 'floor']).optional(),
  "followOn": zod.boolean().optional()
})
}))
})


export const getPriceModifierResponse = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "componentTypes": zod.array(zod.enum(['tariff'])),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})


export const getPriceModifierMutatorsQueryOffsetMin = 0;
export const getPriceModifierMutatorsQueryLimitMax = 100;


export const getPriceModifierMutatorsQueryParams = zod.object({
  "offset": zod.number().min(getPriceModifierMutatorsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getPriceModifierMutatorsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['conditions', 'conditions.entityParameters', 'conditions.entityParameters.product', 'conditions.entityParameters.tariffType', 'conditions.entityParameters.tariffGroup'])).optional()
})

export const getPriceModifierMutatorsResponseConditionsItemDateDiffMin = 0;
export const getPriceModifierMutatorsResponseConditionsItemOccupationRateMin = 0;

export const getPriceModifierMutatorsResponseConditionsItemOccupationRateMax = 1;


export const getPriceModifierMutatorsResponseItem = zod.object({
  "id": zod.string(),
  "order": zod.number(),
  "description": zod.string().nullable(),
  "conditions": zod.array(zod.object({
  "id": zod.string(),
  "operator": zod.enum(['evaluating_tariff', 'check_products', 'check_tariff_types', 'check_tariff_groups', 'schedule_datetime_gte', 'schedule_datetime_lte', 'purchase_datetime_gte', 'purchase_datetime_lte', 'schedule_date_diff_gte', 'schedule_date_diff_lte', 'tariff_occupation_gte', 'tariff_occupation_lte', 'product_occupation_gte', 'product_occupation_lte']),
  "negative": zod.boolean(),
  "datetime": zod.string().datetime().optional(),
  "dateDiff": zod.number().min(getPriceModifierMutatorsResponseConditionsItemDateDiffMin).optional(),
  "occupationRate": zod.number().min(getPriceModifierMutatorsResponseConditionsItemOccupationRateMin).max(getPriceModifierMutatorsResponseConditionsItemOccupationRateMax).optional(),
  "entityParameters": zod.array(zod.object({
  "id": zod.string(),
  "productId": zod.string().optional(),
  "tariffTypeId": zod.string().optional(),
  "tariffGroupId": zod.string().optional()
})).optional()
})).optional(),
  "actuator": zod.object({
  "operator": zod.enum(['set_value_cents', 'modify_percent', 'modify_cents', 'return_value', 'set_max_cents', 'set_min_cents']),
  "valueCents": zod.number().optional(),
  "valuePercent": zod.number().optional(),
  "roundingMethod": zod.enum(['round', 'ceil', 'floor']).optional(),
  "followOn": zod.boolean().optional()
})
})
export const getPriceModifierMutatorsResponse = zod.array(getPriceModifierMutatorsResponseItem)


export const getAppDirectLinksQueryOffsetMin = 0;
export const getAppDirectLinksQueryLimitMax = 100;


export const getAppDirectLinksQueryParams = zod.object({
  "offset": zod.number().min(getAppDirectLinksQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppDirectLinksQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['customer', 'sale', 'sale.coupon', 'sale.coupon.products', 'sale.customer', 'sale.origin', 'moldCart', 'moldCart.coupon', 'origin'])).optional(),
  "types": zod.array(zod.enum(['sale', 'cart', 'payment'])).optional(),
  "ids": zod.array(zod.string()).optional(),
  "sortBy": zod.enum(['recent']).optional()
})

export const getAppDirectLinksResponseTotalAmountCentsMin = 0;
export const getAppDirectLinksResponseSaleAmountCentsMin = 0;
export const getAppDirectLinksResponseSalePartialAmountCentsMin = 0;
export const getAppDirectLinksResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppDirectLinksResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppDirectLinksResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppDirectLinksResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppDirectLinksResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getAppDirectLinksResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getAppDirectLinksResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppDirectLinksResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getAppDirectLinksResponseItem = zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "type": zod.enum(['sale', 'cart', 'payment']),
  "status": zod.enum(['created', 'expired', 'payment_complete', 'canceled', 'payment_voided', 'payment_chargeback']).optional(),
  "isExpired": zod.boolean(),
  "totalAmountCents": zod.number().min(getAppDirectLinksResponseTotalAmountCentsMin),
  "customMessage": zod.string().nullish(),
  "enablePayment": zod.boolean().nullish(),
  "customerId": zod.string().nullish(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "originId": zod.string().nullish(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "moldCartId": zod.string().nullish(),
  "moldCart": zod.object({
  "id": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "saleId": zod.string().nullish(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getAppDirectLinksResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getAppDirectLinksResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppDirectLinksResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getAppDirectLinksResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppDirectLinksResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppDirectLinksResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppDirectLinksResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getAppDirectLinksResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppDirectLinksResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getAppDirectLinksResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "checkoutUrl": zod.string(),
  "expiresAt": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})
export const getAppDirectLinksResponse = zod.array(getAppDirectLinksResponseItem)


export const createAppDirectLinkBodyAmountCentsMin = 0;


export const createAppDirectLinkBody = zod.object({
  "type": zod.enum(['sale', 'cart', 'payment']),
  "saleOriginId": zod.string().nullish(),
  "enablePayment": zod.boolean().nullish(),
  "customMessage": zod.string().nullish(),
  "customerId": zod.string().nullish(),
  "customer": zod.object({
  "firstName": zod.string(),
  "lastName": zod.string(),
  "identity": zod.string().nullish(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullish(),
  "corporateName": zod.string().nullish(),
  "countryCode": zod.string().nullish(),
  "birthdate": zod.string().date().nullish(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullish(),
  "email": zod.string().email().nullish(),
  "phone": zod.string().nullish()
}).optional(),
  "maxInstallments": zod.number().nullish(),
  "minInstallmentAmountCents": zod.number().nullish(),
  "paymentMethodIds": zod.array(zod.string()),
  "trustMode": zod.boolean().optional(),
  "amountCents": zod.number().min(createAppDirectLinkBodyAmountCentsMin).nullish(),
  "items": zod.array(zod.object({
  "productId": zod.string(),
  "partnershipItemId": zod.string().optional(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "quantity": zod.number()
})).min(1),
  "resources": zod.array(zod.object({
  "id": zod.string(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "quantity": zod.number()
}))
})).nullish(),
  "autoAllocateResources": zod.boolean().nullish(),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "inputValue": zod.string().nullish(),
  "quantity": zod.number().nullish()
})).nullish(),
  "productPackageUnitId": zod.string().nullish(),
  "productPackageProductId": zod.string().nullish()
})),
  "couponId": zod.string().nullish(),
  "expiresAt": zod.string().datetime(),
  "notification": zod.object({
  "email": zod.boolean().optional(),
  "sms": zod.boolean().optional()
}).optional(),
  "verifications": zod.object({
  "ignoreTariffDependency": zod.boolean().optional(),
  "ignoreSchedulingAntecedence": zod.boolean().optional()
}).nullish()
})


export const getDirectLinkQueryParams = zod.object({
  "include": zod.array(zod.enum(['customer', 'sale', 'sale.coupon', 'sale.coupon.products', 'sale.customer', 'sale.origin', 'moldCart', 'moldCart.coupon', 'origin'])).optional()
})

export const getDirectLinkResponseTotalAmountCentsMin = 0;
export const getDirectLinkResponseSaleAmountCentsMin = 0;
export const getDirectLinkResponseSalePartialAmountCentsMin = 0;
export const getDirectLinkResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getDirectLinkResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getDirectLinkResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDirectLinkResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDirectLinkResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getDirectLinkResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getDirectLinkResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getDirectLinkResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getDirectLinkResponse = zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "type": zod.enum(['sale', 'cart', 'payment']),
  "status": zod.enum(['created', 'expired', 'payment_complete', 'canceled', 'payment_voided', 'payment_chargeback']).optional(),
  "isExpired": zod.boolean(),
  "totalAmountCents": zod.number().min(getDirectLinkResponseTotalAmountCentsMin),
  "customMessage": zod.string().nullish(),
  "enablePayment": zod.boolean().nullish(),
  "customerId": zod.string().nullish(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "originId": zod.string().nullish(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "moldCartId": zod.string().nullish(),
  "moldCart": zod.object({
  "id": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "saleId": zod.string().nullish(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getDirectLinkResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getDirectLinkResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDirectLinkResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getDirectLinkResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDirectLinkResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getDirectLinkResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDirectLinkResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getDirectLinkResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDirectLinkResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getDirectLinkResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "checkoutUrl": zod.string(),
  "expiresAt": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})


export const getProductDetailedSchedulingsQueryOffsetMin = 0;
export const getProductDetailedSchedulingsQueryLimitMax = 100;


export const getProductDetailedSchedulingsQueryParams = zod.object({
  "offset": zod.number().min(getProductDetailedSchedulingsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getProductDetailedSchedulingsQueryLimitMax).optional(),
  "since": zod.string().date(),
  "until": zod.string().date(),
  "include": zod.array(zod.enum(['finalTariffGroup.tariffs.type'])).optional(),
  "ignoreAntecedence": zod.boolean().optional()
})

export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMinSaleTariffQuantityMin = 0;

export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMinSaleTariffQuantityMax = 100;
export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMaxSaleTariffQuantityMin = 0;

export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMaxSaleTariffQuantityMax = 100;
export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMinSaleTariffQuantityMin = 0;

export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMinSaleTariffQuantityMax = 100;
export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMaxSaleTariffQuantityMin = 0;

export const getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMaxSaleTariffQuantityMax = 100;


export const getProductDetailedSchedulingsResponseItem = zod.object({
  "id": zod.string(),
  "date": zod.string().date(),
  "consumableFromTime": zod.string().nullish(),
  "consumableToTime": zod.string().nullish(),
  "calendarIntervalMinutes": zod.number().nullish(),
  "durationMinutes": zod.number(),
  "antecedenceHours": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish(),
  "antecedenceHoursReferenceTime": zod.string().nullish(),
  "availableScheduleTimes": zod.array(zod.object({
  "time": zod.string(),
  "minutes": zod.number()
})).nullish(),
  "finalTariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().optional(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMinSaleQuantityMin).max(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMaxSaleQuantityMin).max(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMinSaleTariffQuantityMin).max(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMaxSaleTariffQuantityMin).max(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMinSaleQuantityMin).max(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin).max(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMinSaleTariffQuantityMin).max(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMaxSaleTariffQuantityMin).max(getProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}))
}),
  "requiresSeatSelection": zod.boolean(),
  "occupationRates": zod.object({
  "scheduleQuantities": zod.array(zod.object({
  "time": zod.string().nullable(),
  "minutes": zod.number().nullable(),
  "occupation": zod.object({
  "availableAmount": zod.number().nullable(),
  "occupationRate": zod.number().nullable()
})
})),
  "tariffScheduleQuantities": zod.array(zod.object({
  "tariffId": zod.string(),
  "occupationSchedule": zod.array(zod.object({
  "time": zod.string().nullable(),
  "minutes": zod.number().nullable(),
  "occupation": zod.object({
  "availableAmount": zod.number().nullable(),
  "occupationRate": zod.number().nullable()
})
}))
})),
  "additionalScheduleQuantities": zod.array(zod.object({
  "additionalId": zod.string(),
  "occupationSchedule": zod.array(zod.object({
  "time": zod.string().nullable(),
  "minutes": zod.number().nullable(),
  "occupation": zod.object({
  "availableAmount": zod.number().nullable(),
  "occupationRate": zod.number().nullable()
})
}))
}))
}),
  "tariffScheduleUnitPrices": zod.array(zod.object({
  "time": zod.string().nullable(),
  "tariffs": zod.array(zod.object({
  "tariffId": zod.string(),
  "priceCents": zod.number()
}))
}))
})
export const getProductDetailedSchedulingsResponse = zod.array(getProductDetailedSchedulingsResponseItem)


export const getProductImagesQueryOffsetMin = 0;
export const getProductImagesQueryLimitMax = 100;


export const getProductImagesQueryParams = zod.object({
  "offset": zod.number().min(getProductImagesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getProductImagesQueryLimitMax).optional()
})

export const getProductImagesResponseItem = zod.object({
  "id": zod.string(),
  "active": zod.boolean(),
  "thumbnail": zod.boolean(),
  "productId": zod.string(),
  "imageId": zod.string(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getProductImagesResponse = zod.array(getProductImagesResponseItem)


export const getImageResponse = zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const getAdditionalQueryParams = zod.object({
  "include": zod.array(zod.enum(['product'])).optional()
})

export const getAdditionalResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getAdditionalResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getAdditionalResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAdditionalResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAdditionalResponseProductMinSaleTariffQuantityMin = 0;

export const getAdditionalResponseProductMinSaleTariffQuantityMax = 100;
export const getAdditionalResponseProductMaxSaleTariffQuantityMin = 0;

export const getAdditionalResponseProductMaxSaleTariffQuantityMax = 100;


export const getAdditionalResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAdditionalResponseProductTariffSettingsMinSaleQuantityMin).max(getAdditionalResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAdditionalResponseProductTariffSettingsMaxSaleQuantityMin).max(getAdditionalResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAdditionalResponseProductMinSaleTariffQuantityMin).max(getAdditionalResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAdditionalResponseProductMaxSaleTariffQuantityMin).max(getAdditionalResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})


export const getAppSalesQueryOffsetMin = 0;
export const getAppSalesQueryLimitMax = 100;


export const getAppSalesQueryParams = zod.object({
  "offset": zod.number().min(getAppSalesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppSalesQueryLimitMax).optional(),
  "currentState": zod.array(zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled'])).optional(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "refundRequestStatus": zod.array(zod.enum(['pending', 'approved', 'denied'])).optional(),
  "tagIds": zod.array(zod.string()).optional(),
  "include": zod.array(zod.enum(['coupon', 'coupon.products', 'coupon.products.product', 'customer', 'origin', 'order', 'order.decisiveTransaction', 'order.decisiveTransaction.paymentMethod', 'aggregatedVouchers', 'tags', 'aggregatedRefundRequests', 'decisiveTransaction', 'decisiveTransaction.paymentMethod'])).optional(),
  "search": zod.string().optional(),
  "couponIds": zod.array(zod.string()).optional(),
  "saleOriginIds": zod.array(zod.string()).optional(),
  "sources": zod.array(zod.enum(['site', 'direct_link', 'direct_sale', 'external', 'resale'])).optional(),
  "attributes": zod.array(zod.string()).optional(),
  "sortBy": zod.enum(['recent', 'latestUpdated']).optional()
})

export const getAppSalesResponseAmountCentsMin = 0;
export const getAppSalesResponsePartialAmountCentsMin = 0;
export const getAppSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppSalesResponseCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getAppSalesResponseCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getAppSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getAppSalesResponseItem = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getAppSalesResponseAmountCentsMin),
  "partialAmountCents": zod.number().min(getAppSalesResponsePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getAppSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppSalesResponseCouponProductsItemProductMinSaleTariffQuantityMin).max(getAppSalesResponseCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMin).max(getAppSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
})
export const getAppSalesResponse = zod.array(getAppSalesResponseItem)


export const getBankOptionsResponseItem = zod.object({
  "code": zod.string(),
  "shortName": zod.string(),
  "longName": zod.string()
})
export const getBankOptionsResponse = zod.array(getBankOptionsResponseItem)


export const getPublicPartnershipProposalQueryParams = zod.object({
  "include": zod.array(zod.enum(['provider', 'provider.info'])).optional()
})

export const getPublicPartnershipProposalResponse = zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
})


export const getPublicPartnershipProposalItemsQueryOffsetMin = 0;
export const getPublicPartnershipProposalItemsQueryLimitMax = 100;


export const getPublicPartnershipProposalItemsQueryParams = zod.object({
  "offset": zod.number().min(getPublicPartnershipProposalItemsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getPublicPartnershipProposalItemsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product', 'product.defaultTariffGroupPriceInfo', 'product.defaultTariff'])).optional()
})

export const getPublicPartnershipProposalItemsResponseItem = zod.object({
  "id": zod.string(),
  "allProducts": zod.boolean(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "productId": zod.string().nullish(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}).nullish(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])),
  "commissionType": zod.enum(['amount_cents', 'percentage_fee']),
  "commissionAmountCents": zod.number().nullish(),
  "commissionPercentageFee": zod.number().nullish(),
  "includePlanFees": zod.boolean(),
  "planFeeDistributionMode": zod.enum(['proportional', 'provider_backed', 'agent_backed'])
})
export const getPublicPartnershipProposalItemsResponse = zod.array(getPublicPartnershipProposalItemsResponseItem)


export const getAppProvidedPartnershipsQueryOffsetMin = 0;
export const getAppProvidedPartnershipsQueryLimitMax = 100;


export const getAppProvidedPartnershipsQueryParams = zod.object({
  "offset": zod.number().min(getAppProvidedPartnershipsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppProvidedPartnershipsQueryLimitMax).optional(),
  "currentStatus": zod.array(zod.enum(['pending', 'ongoing', 'declined', 'deactivated'])).optional(),
  "sortBy": zod.enum(['recent']).optional(),
  "include": zod.array(zod.enum(['provider', 'provider.info', 'agent', 'agent.info', 'publicProposal', 'publicProposal.provider', 'publicProposal.provider.info'])).optional(),
  "search": zod.string().optional(),
  "partnershipProposalIds": zod.array(zod.string()).optional(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])).optional()
})

export const getAppProvidedPartnershipsResponseItem = zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})
export const getAppProvidedPartnershipsResponse = zod.array(getAppProvidedPartnershipsResponseItem)


export const getAppValidProvidedPartnershipsQueryOffsetMin = 0;
export const getAppValidProvidedPartnershipsQueryLimitMax = 100;


export const getAppValidProvidedPartnershipsQueryParams = zod.object({
  "offset": zod.number().min(getAppValidProvidedPartnershipsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppValidProvidedPartnershipsQueryLimitMax).optional(),
  "currentStatus": zod.array(zod.enum(['pending', 'ongoing', 'declined', 'deactivated'])).optional(),
  "sortBy": zod.enum(['recent']).optional(),
  "include": zod.array(zod.enum(['provider', 'provider.info', 'agent', 'agent.info', 'publicProposal', 'publicProposal.provider', 'publicProposal.provider.info'])).optional(),
  "search": zod.string().optional(),
  "partnershipProposalIds": zod.array(zod.string()).optional(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])).optional()
})

export const getAppValidProvidedPartnershipsResponseItem = zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})
export const getAppValidProvidedPartnershipsResponse = zod.array(getAppValidProvidedPartnershipsResponseItem)


export const getAppArrangedPartnershipsQueryOffsetMin = 0;
export const getAppArrangedPartnershipsQueryLimitMax = 100;


export const getAppArrangedPartnershipsQueryParams = zod.object({
  "offset": zod.number().min(getAppArrangedPartnershipsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppArrangedPartnershipsQueryLimitMax).optional(),
  "currentStatus": zod.array(zod.enum(['pending', 'ongoing', 'declined', 'deactivated'])).optional(),
  "sortBy": zod.enum(['recent']).optional(),
  "include": zod.array(zod.enum(['provider', 'provider.info', 'agent', 'agent.info', 'publicProposal', 'publicProposal.provider', 'publicProposal.provider.info'])).optional(),
  "search": zod.string().optional(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])).optional()
})

export const getAppArrangedPartnershipsResponseItem = zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})
export const getAppArrangedPartnershipsResponse = zod.array(getAppArrangedPartnershipsResponseItem)


export const getAppValidArrangedPartnershipsQueryOffsetMin = 0;
export const getAppValidArrangedPartnershipsQueryLimitMax = 100;


export const getAppValidArrangedPartnershipsQueryParams = zod.object({
  "offset": zod.number().min(getAppValidArrangedPartnershipsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppValidArrangedPartnershipsQueryLimitMax).optional(),
  "currentStatus": zod.array(zod.enum(['pending', 'ongoing', 'declined', 'deactivated'])).optional(),
  "sortBy": zod.enum(['recent']).optional(),
  "include": zod.array(zod.enum(['provider', 'provider.info', 'agent', 'agent.info', 'publicProposal', 'publicProposal.provider', 'publicProposal.provider.info'])).optional(),
  "search": zod.string().optional(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])).optional()
})

export const getAppValidArrangedPartnershipsResponseItem = zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})
export const getAppValidArrangedPartnershipsResponse = zod.array(getAppValidArrangedPartnershipsResponseItem)


export const createAppArrangedPartnershipQueryParams = zod.object({
  "include": zod.array(zod.enum(['provider', 'provider.info', 'agent', 'agent.info', 'publicProposal', 'publicProposal.provider', 'publicProposal.provider.info'])).optional()
})

export const createAppArrangedPartnershipBody = zod.object({
  "proposalId": zod.string()
})


export const updateArrangedPartnershipBody = zod.object({
  "affiliationCode": zod.string()
})

export const updateArrangedPartnershipResponse = zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})


export const getPartnershipQueryParams = zod.object({
  "include": zod.array(zod.enum(['provider', 'provider.info', 'agent', 'agent.info', 'publicProposal', 'publicProposal.provider', 'publicProposal.provider.info'])).optional()
})

export const getPartnershipResponse = zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})


export const updatePartnershipBody = zod.object({
  "status": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string()
})

export const updatePartnershipResponse = zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})


export const getPartnershipItemsQueryOffsetMin = 0;
export const getPartnershipItemsQueryLimitMax = 100;


export const getPartnershipItemsQueryParams = zod.object({
  "offset": zod.number().min(getPartnershipItemsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getPartnershipItemsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product', 'product.defaultTariffGroupPriceInfo', 'product.defaultTariff'])).optional()
})

export const getPartnershipItemsResponseItem = zod.object({
  "id": zod.string(),
  "allProducts": zod.boolean(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "productId": zod.string().nullish(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}).nullish(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])),
  "commissionType": zod.enum(['amount_cents', 'percentage_fee']),
  "commissionAmountCents": zod.number().nullish(),
  "commissionPercentageFee": zod.number().nullish(),
  "includePlanFees": zod.boolean(),
  "planFeeDistributionMode": zod.enum(['proportional', 'provider_backed', 'agent_backed'])
})
export const getPartnershipItemsResponse = zod.array(getPartnershipItemsResponseItem)


export const getPartnershipCouponsQueryOffsetMin = 0;
export const getPartnershipCouponsQueryLimitMax = 100;


export const getPartnershipCouponsQueryParams = zod.object({
  "offset": zod.number().min(getPartnershipCouponsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getPartnershipCouponsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['products', 'usage'])).optional()
})

export const getPartnershipCouponsResponseItem = zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullish(),
  "maxUse": zod.number().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "maxUsePerUser": zod.number().nullish(),
  "usage": zod.number().optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "internalName": zod.string().nullable()
})).nullish(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).optional(),
  "isPartnershipDefault": zod.boolean().nullish(),
  "constraintType": zod.enum(['include', 'exclude']).nullish(),
  "description": zod.string().nullish(),
  "availableAt": zod.string().datetime().nullish(),
  "minAmountCents": zod.number().nullish(),
  "usageRestrictions": zod.array(zod.object({
  "type": zod.enum(['range', 'date_only', 'week_days']),
  "date": zod.string().date().nullish(),
  "fromDate": zod.string().datetime().nullish(),
  "toDate": zod.string().datetime().nullish(),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).nullish()
})).nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})
export const getPartnershipCouponsResponse = zod.array(getPartnershipCouponsResponseItem)


export const getProvidedPartnershipSaleItemsQueryOffsetMin = 0;
export const getProvidedPartnershipSaleItemsQueryLimitMax = 100;


export const getProvidedPartnershipSaleItemsQueryParams = zod.object({
  "offset": zod.number().min(getProvidedPartnershipSaleItemsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getProvidedPartnershipSaleItemsQueryLimitMax).optional(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "include": zod.array(zod.enum(['product', 'sale', 'partnershipItem.product', 'partnershipItem.partnership', 'partnershipItem.partnership.provider', 'partnershipItem.partnership.agent', 'partnershipItem.partnership.publicProposal', 'reservation', 'reservation.product', 'partnershipProvider', 'partnershipAgent', 'productPackageProduct', 'productPackageProduct.productPackage'])).optional(),
  "sortBy": zod.enum(['recent']).optional()
})

export const getProvidedPartnershipSaleItemsResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseProductMinSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseProductMinSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseProductMaxSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseProductMaxSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseSaleAmountCentsMin = 0;
export const getProvidedPartnershipSaleItemsResponseSalePartialAmountCentsMin = 0;
export const getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseReservationProductMinSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseReservationProductMinSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseReservationProductMaxSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseReservationProductMaxSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseReservationSaleAmountCentsMin = 0;
export const getProvidedPartnershipSaleItemsResponseReservationSalePartialAmountCentsMin = 0;
export const getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseTariffsItemQuantityMin = 0;
export const getProvidedPartnershipSaleItemsResponseTariffsItemUnitPriceCentsMin = 0;
export const getProvidedPartnershipSaleItemsResponseResourcesItemPriceCentsMin = 0;
export const getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseAdditionalsItemUnitPriceCentsMin = 0;
export const getProvidedPartnershipSaleItemsResponseAdditionalsItemUnitQuantityMin = 0;
export const getProvidedPartnershipSaleItemsResponseAdditionalsItemPriceCentsMin = 0;
export const getProvidedPartnershipSaleItemsResponseCouponProductProductTariffSettingsMinSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseCouponProductProductTariffSettingsMinSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseCouponProductProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseCouponProductProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseCouponProductProductMinSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseCouponProductProductMinSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseCouponProductProductMaxSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseCouponProductProductMaxSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMax = 100;
export const getProvidedPartnershipSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMin = 0;

export const getProvidedPartnershipSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMax = 100;


export const getProvidedPartnershipSaleItemsResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseProductTariffSettingsMinSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseProductTariffSettingsMaxSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseProductMinSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseProductMaxSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getProvidedPartnershipSaleItemsResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getProvidedPartnershipSaleItemsResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "reservationId": zod.string().nullish(),
  "reservation": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseReservationProductMinSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseReservationProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseReservationProductMaxSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseReservationProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string().nullable(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getProvidedPartnershipSaleItemsResponseReservationSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getProvidedPartnershipSaleItemsResponseReservationSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "name": zod.string(),
  "description": zod.string().nullish(),
  "createdAt": zod.string().datetime()
}).optional(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(getProvidedPartnershipSaleItemsResponseTariffsItemQuantityMin),
  "unitPriceCents": zod.number().min(getProvidedPartnershipSaleItemsResponseTariffsItemUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
})),
  "resources": zod.array(zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(getProvidedPartnershipSaleItemsResponseResourcesItemPriceCentsMin),
  "createdAt": zod.string().datetime()
})),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(getProvidedPartnershipSaleItemsResponseAdditionalsItemUnitPriceCentsMin),
  "unitQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseAdditionalsItemUnitQuantityMin),
  "priceCents": zod.number().min(getProvidedPartnershipSaleItemsResponseAdditionalsItemPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
})),
  "partnershipItemId": zod.string().nullable(),
  "partnershipItem": zod.object({
  "id": zod.string(),
  "allProducts": zod.boolean(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "productId": zod.string().nullish(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}).nullish(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])),
  "commissionType": zod.enum(['amount_cents', 'percentage_fee']),
  "commissionAmountCents": zod.number().nullish(),
  "commissionPercentageFee": zod.number().nullish(),
  "includePlanFees": zod.boolean(),
  "planFeeDistributionMode": zod.enum(['proportional', 'provider_backed', 'agent_backed'])
}).optional(),
  "partnershipProviderId": zod.string().nullable(),
  "partnershipProvider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "partnershipAgentId": zod.string().nullable(),
  "partnershipAgent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "priceCents": zod.number(),
  "couponProduct": zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseCouponProductProductTariffSettingsMinSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseCouponProductProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseCouponProductProductTariffSettingsMaxSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseCouponProductProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseCouponProductProductMinSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseCouponProductProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseCouponProductProductMaxSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseCouponProductProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
}).optional(),
  "productPackageUnitId": zod.string().nullable(),
  "productPackageProductId": zod.string().nullable(),
  "productPackageProduct": zod.object({
  "id": zod.string(),
  "productPackageId": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMin).max(getProvidedPartnershipSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProvidedPartnershipSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMin).max(getProvidedPartnershipSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "productPackage": zod.object({
  "id": zod.string(),
  "appId": zod.string().optional(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).optional(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "imageId": zod.string().nullable(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "description": zod.string().nullable(),
  "shortDescription": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "voucherGuidelines": zod.string().nullable(),
  "deletedAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "discountPercentage": zod.number().optional(),
  "position": zod.number().optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getProvidedPartnershipSaleItemsResponse = zod.array(getProvidedPartnershipSaleItemsResponseItem)


export const updateArrangedCouponBody = zod.object({
  "code": zod.string(),
  "discountPercentage": zod.number(),
  "isPartnershipDefault": zod.boolean()
})

export const updateArrangedCouponResponse = zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullish(),
  "maxUse": zod.number().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "maxUsePerUser": zod.number().nullish(),
  "usage": zod.number().optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "internalName": zod.string().nullable()
})).nullish(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).optional(),
  "isPartnershipDefault": zod.boolean().nullish(),
  "constraintType": zod.enum(['include', 'exclude']).nullish(),
  "description": zod.string().nullish(),
  "availableAt": zod.string().datetime().nullish(),
  "minAmountCents": zod.number().nullish(),
  "usageRestrictions": zod.array(zod.object({
  "type": zod.enum(['range', 'date_only', 'week_days']),
  "date": zod.string().date().nullish(),
  "fromDate": zod.string().datetime().nullish(),
  "toDate": zod.string().datetime().nullish(),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).nullish()
})).nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})


export const approvePartnershipQueryParams = zod.object({
  "include": zod.array(zod.enum(['provider', 'provider.info', 'agent', 'agent.info', 'publicProposal', 'publicProposal.provider', 'publicProposal.provider.info'])).optional()
})

export const approvePartnershipResponse = zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})


export const declinePartnershipQueryParams = zod.object({
  "include": zod.array(zod.enum(['provider', 'provider.info', 'agent', 'agent.info', 'publicProposal', 'publicProposal.provider', 'publicProposal.provider.info'])).optional()
})

export const declinePartnershipResponse = zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
})


export const getAppArrangedSaleItemsQueryOffsetMin = 0;
export const getAppArrangedSaleItemsQueryLimitMax = 100;


export const getAppArrangedSaleItemsQueryParams = zod.object({
  "offset": zod.number().min(getAppArrangedSaleItemsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppArrangedSaleItemsQueryLimitMax).optional(),
  "currentState": zod.array(zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled'])).optional(),
  "providerIds": zod.array(zod.string()).optional(),
  "partnershipProposalIds": zod.array(zod.string()).optional(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "sinceScheduleDate": zod.string().datetime().nullish(),
  "untilScheduleDate": zod.string().datetime().nullish(),
  "sortBy": zod.enum(['recent']).optional(),
  "include": zod.array(zod.enum(['product', 'sale', 'partnershipItem.product', 'partnershipItem.partnership', 'partnershipItem.partnership.provider', 'partnershipItem.partnership.agent', 'partnershipItem.partnership.publicProposal', 'decisiveTransactionValues', 'productPackageProduct', 'productPackageProduct.productPackage'])).optional(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])).optional()
})

export const getAppArrangedSaleItemsResponseDecisiveTransactionValuesTotalAmountCentsMin = 0;
export const getAppArrangedSaleItemsResponseDecisiveTransactionValuesCommissionedAmountCentsMin = 0;
export const getAppArrangedSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppArrangedSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppArrangedSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppArrangedSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppArrangedSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMin = 0;

export const getAppArrangedSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMax = 100;
export const getAppArrangedSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMin = 0;

export const getAppArrangedSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMax = 100;


export const getAppArrangedSaleItemsResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "createdAt": zod.string().datetime()
}).optional(),
  "partnershipItemId": zod.string().optional(),
  "partnershipItem": zod.object({
  "id": zod.string(),
  "allProducts": zod.boolean(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "productId": zod.string().nullish(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}).nullish(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])),
  "commissionType": zod.enum(['amount_cents', 'percentage_fee']),
  "commissionAmountCents": zod.number().nullish(),
  "commissionPercentageFee": zod.number().nullish(),
  "includePlanFees": zod.boolean(),
  "planFeeDistributionMode": zod.enum(['proportional', 'provider_backed', 'agent_backed'])
}).optional(),
  "scheduleDate": zod.string().date().nullish(),
  "decisiveTransactionValues": zod.object({
  "totalAmountCents": zod.number().min(getAppArrangedSaleItemsResponseDecisiveTransactionValuesTotalAmountCentsMin),
  "commissionedAmountCents": zod.number().min(getAppArrangedSaleItemsResponseDecisiveTransactionValuesCommissionedAmountCentsMin)
}).nullish(),
  "productPackageUnitId": zod.string().nullable(),
  "productPackageProductId": zod.string().nullable(),
  "productPackageProduct": zod.object({
  "id": zod.string(),
  "productPackageId": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppArrangedSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMin).max(getAppArrangedSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppArrangedSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMin).max(getAppArrangedSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppArrangedSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMin).max(getAppArrangedSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppArrangedSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMin).max(getAppArrangedSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "productPackage": zod.object({
  "id": zod.string(),
  "appId": zod.string().optional(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).optional(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "imageId": zod.string().nullable(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "description": zod.string().nullable(),
  "shortDescription": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "voucherGuidelines": zod.string().nullable(),
  "deletedAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "discountPercentage": zod.number().optional(),
  "position": zod.number().optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullable()
})
export const getAppArrangedSaleItemsResponse = zod.array(getAppArrangedSaleItemsResponseItem)


export const getAppArrangedProvidersQueryOffsetMin = 0;
export const getAppArrangedProvidersQueryLimitMax = 100;


export const getAppArrangedProvidersQueryParams = zod.object({
  "offset": zod.number().min(getAppArrangedProvidersQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppArrangedProvidersQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['info'])).optional()
})

export const getAppArrangedProvidersResponseItem = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
})
export const getAppArrangedProvidersResponse = zod.array(getAppArrangedProvidersResponseItem)


export const getAppPartnershipProposalsQueryOffsetMin = 0;
export const getAppPartnershipProposalsQueryLimitMax = 100;


export const getAppPartnershipProposalsQueryParams = zod.object({
  "offset": zod.number().min(getAppPartnershipProposalsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppPartnershipProposalsQueryLimitMax).optional()
})

export const getAppPartnershipProposalsResponseItem = zod.object({
  "id": zod.string(),
  "shareCode": zod.string().nullish(),
  "providerId": zod.string(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "isPublic": zod.boolean(),
  "allowsCounterProposal": zod.boolean(),
  "autoApproves": zod.boolean(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppPartnershipProposalsResponse = zod.array(getAppPartnershipProposalsResponseItem)


export const getAppArrangedSaleItemsAggregatedInfoQueryParams = zod.object({
  "currentState": zod.array(zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled'])).optional(),
  "providerIds": zod.array(zod.string()).optional(),
  "partnershipProposalIds": zod.array(zod.string()).optional(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "sinceScheduleDate": zod.string().datetime().nullish(),
  "untilScheduleDate": zod.string().datetime().nullish(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])).optional()
})

export const getAppArrangedSaleItemsAggregatedInfoResponseTotalSaleItemsMin = 0;
export const getAppArrangedSaleItemsAggregatedInfoResponseTotalAmountCentsMin = 0;
export const getAppArrangedSaleItemsAggregatedInfoResponseCommissionedAmountCentsMin = 0;


export const getAppArrangedSaleItemsAggregatedInfoResponse = zod.object({
  "totalSaleItems": zod.number().min(getAppArrangedSaleItemsAggregatedInfoResponseTotalSaleItemsMin),
  "totalAmountCents": zod.number().min(getAppArrangedSaleItemsAggregatedInfoResponseTotalAmountCentsMin),
  "commissionedAmountCents": zod.number().min(getAppArrangedSaleItemsAggregatedInfoResponseCommissionedAmountCentsMin)
})


export const getAppRefundRequestsQueryOffsetMin = 0;
export const getAppRefundRequestsQueryLimitMax = 100;


export const getAppRefundRequestsQueryParams = zod.object({
  "offset": zod.number().min(getAppRefundRequestsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppRefundRequestsQueryLimitMax).optional(),
  "search": zod.string().nullish(),
  "status": zod.array(zod.enum(['pending', 'approved', 'denied'])).nullish(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "include": zod.array(zod.enum(['sale', 'sale.customer', 'sale.aggregatedVouchers'])).optional(),
  "sortBy": zod.enum(['recent']).optional()
})

export const getAppRefundRequestsResponseSaleAmountCentsMin = 0;
export const getAppRefundRequestsResponseSalePartialAmountCentsMin = 0;
export const getAppRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppRefundRequestsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getAppRefundRequestsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getAppRefundRequestsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppRefundRequestsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getAppRefundRequestsResponseItem = zod.object({
  "id": zod.string(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getAppRefundRequestsResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getAppRefundRequestsResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getAppRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppRefundRequestsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getAppRefundRequestsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppRefundRequestsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getAppRefundRequestsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "status": zod.enum(['pending', 'approved', 'denied']),
  "observation": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppRefundRequestsResponse = zod.array(getAppRefundRequestsResponseItem)


export const getSaleRefundInsightsResponse = zod.object({
  "saleId": zod.string(),
  "isCreationDateInsidePeriod": zod.boolean(),
  "hasOpenRefundRequests": zod.boolean(),
  "hasConsumedVouchers": zod.boolean()
})


export const getAppIncomeSchedulingsQueryOffsetMin = 0;
export const getAppIncomeSchedulingsQueryLimitMax = 100;


export const getAppIncomeSchedulingsQueryParams = zod.object({
  "offset": zod.number().min(getAppIncomeSchedulingsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppIncomeSchedulingsQueryLimitMax).optional(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "dateField": zod.enum(['scheduledAt', 'plannedAt', 'createdAt']).optional(),
  "sortBy": zod.enum(['oldestScheduledAt', 'createdAt', 'updatedAt']).optional(),
  "sortDirection": zod.enum(['asc', 'desc']).optional(),
  "include": zod.array(zod.enum(['transaction', 'transaction.paymentMethod', 'transaction.paymentGateway', 'transaction.sale', 'transaction.sale.customer', 'transaction.payment', 'transaction.directLink', 'transaction.order'])).optional()
})

export const getAppIncomeSchedulingsResponseTransactionSaleAmountCentsMin = 0;
export const getAppIncomeSchedulingsResponseTransactionSalePartialAmountCentsMin = 0;
export const getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getAppIncomeSchedulingsResponseTransactionDirectLinkTotalAmountCentsMin = 0;
export const getAppIncomeSchedulingsResponseTransactionDirectLinkSaleAmountCentsMin = 0;
export const getAppIncomeSchedulingsResponseTransactionDirectLinkSalePartialAmountCentsMin = 0;
export const getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getAppIncomeSchedulingsResponseItem = zod.object({
  "id": zod.string(),
  "transactionId": zod.string(),
  "transaction": zod.object({
  "id": zod.string(),
  "amountCents": zod.number(),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "paymentGatewayId": zod.string(),
  "paymentGateway": zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "name": zod.string()
}).optional(),
  "saleId": zod.string().nullable(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getAppIncomeSchedulingsResponseTransactionSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getAppIncomeSchedulingsResponseTransactionSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getAppIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "paymentId": zod.string().nullable(),
  "payment": zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "customerName": zod.string(),
  "customerEmail": zod.string(),
  "customerIdentity": zod.string(),
  "customerPhone": zod.string(),
  "customerSelected": zod.array(zod.enum(['customer_email', 'customer_identity', 'customer_phone'])),
  "partialAmountCents": zod.number(),
  "maxInstallmentsSum": zod.number(),
  "currentState": zod.enum(['created', 'expired', 'pending', 'payment_complete', 'payment_voided', 'payment_chargeback']),
  "deadlineDate": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "directLinkId": zod.string().nullable(),
  "directLink": zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "type": zod.enum(['sale', 'cart', 'payment']),
  "status": zod.enum(['created', 'expired', 'payment_complete', 'canceled', 'payment_voided', 'payment_chargeback']).optional(),
  "isExpired": zod.boolean(),
  "totalAmountCents": zod.number().min(getAppIncomeSchedulingsResponseTransactionDirectLinkTotalAmountCentsMin),
  "customMessage": zod.string().nullish(),
  "enablePayment": zod.boolean().nullish(),
  "customerId": zod.string().nullish(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "originId": zod.string().nullish(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "moldCartId": zod.string().nullish(),
  "moldCart": zod.object({
  "id": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "saleId": zod.string().nullish(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getAppIncomeSchedulingsResponseTransactionDirectLinkSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getAppIncomeSchedulingsResponseTransactionDirectLinkSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getAppIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "checkoutUrl": zod.string(),
  "expiresAt": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish()
}).optional(),
  "eventType": zod.enum(['credit', 'debit', 'fee_credit', 'fee_debit', 'chargeback_debit', 'refund_debit', 'anti_fraud_fee_debit']),
  "grossAmountCents": zod.number(),
  "amountCents": zod.number(),
  "grossDifferenceCents": zod.number(),
  "installmentTotal": zod.number(),
  "installmentNumber": zod.number(),
  "availableAt": zod.string().datetime(),
  "performedAt": zod.string().datetime().nullable(),
  "plannedAt": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppIncomeSchedulingsResponse = zod.array(getAppIncomeSchedulingsResponseItem)


export const getSaleIncomeSchedulingsQueryOffsetMin = 0;
export const getSaleIncomeSchedulingsQueryLimitMax = 100;


export const getSaleIncomeSchedulingsQueryParams = zod.object({
  "offset": zod.number().min(getSaleIncomeSchedulingsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getSaleIncomeSchedulingsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['transaction', 'transaction.paymentMethod', 'transaction.paymentGateway', 'transaction.sale', 'transaction.sale.customer', 'transaction.payment', 'transaction.directLink', 'transaction.order'])).optional(),
  "sortBy": zod.enum(['oldestScheduledAt', 'createdAt', 'updatedAt']).optional()
})

export const getSaleIncomeSchedulingsResponseTransactionSaleAmountCentsMin = 0;
export const getSaleIncomeSchedulingsResponseTransactionSalePartialAmountCentsMin = 0;
export const getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getSaleIncomeSchedulingsResponseTransactionDirectLinkTotalAmountCentsMin = 0;
export const getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleAmountCentsMin = 0;
export const getSaleIncomeSchedulingsResponseTransactionDirectLinkSalePartialAmountCentsMin = 0;
export const getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getSaleIncomeSchedulingsResponseItem = zod.object({
  "id": zod.string(),
  "transactionId": zod.string(),
  "transaction": zod.object({
  "id": zod.string(),
  "amountCents": zod.number(),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "paymentGatewayId": zod.string(),
  "paymentGateway": zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "name": zod.string()
}).optional(),
  "saleId": zod.string().nullable(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getSaleIncomeSchedulingsResponseTransactionSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getSaleIncomeSchedulingsResponseTransactionSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getSaleIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "paymentId": zod.string().nullable(),
  "payment": zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "customerName": zod.string(),
  "customerEmail": zod.string(),
  "customerIdentity": zod.string(),
  "customerPhone": zod.string(),
  "customerSelected": zod.array(zod.enum(['customer_email', 'customer_identity', 'customer_phone'])),
  "partialAmountCents": zod.number(),
  "maxInstallmentsSum": zod.number(),
  "currentState": zod.enum(['created', 'expired', 'pending', 'payment_complete', 'payment_voided', 'payment_chargeback']),
  "deadlineDate": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "directLinkId": zod.string().nullable(),
  "directLink": zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "type": zod.enum(['sale', 'cart', 'payment']),
  "status": zod.enum(['created', 'expired', 'payment_complete', 'canceled', 'payment_voided', 'payment_chargeback']).optional(),
  "isExpired": zod.boolean(),
  "totalAmountCents": zod.number().min(getSaleIncomeSchedulingsResponseTransactionDirectLinkTotalAmountCentsMin),
  "customMessage": zod.string().nullish(),
  "enablePayment": zod.boolean().nullish(),
  "customerId": zod.string().nullish(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "originId": zod.string().nullish(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "moldCartId": zod.string().nullish(),
  "moldCart": zod.object({
  "id": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "saleId": zod.string().nullish(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getSaleIncomeSchedulingsResponseTransactionDirectLinkSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getSaleIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "checkoutUrl": zod.string(),
  "expiresAt": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish()
}).optional(),
  "eventType": zod.enum(['credit', 'debit', 'fee_credit', 'fee_debit', 'chargeback_debit', 'refund_debit', 'anti_fraud_fee_debit']),
  "grossAmountCents": zod.number(),
  "amountCents": zod.number(),
  "grossDifferenceCents": zod.number(),
  "installmentTotal": zod.number(),
  "installmentNumber": zod.number(),
  "availableAt": zod.string().datetime(),
  "performedAt": zod.string().datetime().nullable(),
  "plannedAt": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getSaleIncomeSchedulingsResponse = zod.array(getSaleIncomeSchedulingsResponseItem)


export const getTransactionIncomeSchedulingsQueryOffsetMin = 0;
export const getTransactionIncomeSchedulingsQueryLimitMax = 100;


export const getTransactionIncomeSchedulingsQueryParams = zod.object({
  "offset": zod.number().min(getTransactionIncomeSchedulingsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getTransactionIncomeSchedulingsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['transaction', 'transaction.paymentMethod', 'transaction.paymentGateway', 'transaction.sale', 'transaction.sale.customer', 'transaction.payment', 'transaction.directLink', 'transaction.order'])).optional(),
  "sortBy": zod.enum(['oldestScheduledAt', 'createdAt', 'updatedAt']).optional()
})

export const getTransactionIncomeSchedulingsResponseTransactionSaleAmountCentsMin = 0;
export const getTransactionIncomeSchedulingsResponseTransactionSalePartialAmountCentsMin = 0;
export const getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkTotalAmountCentsMin = 0;
export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleAmountCentsMin = 0;
export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkSalePartialAmountCentsMin = 0;
export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getTransactionIncomeSchedulingsResponseItem = zod.object({
  "id": zod.string(),
  "transactionId": zod.string(),
  "transaction": zod.object({
  "id": zod.string(),
  "amountCents": zod.number(),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "paymentGatewayId": zod.string(),
  "paymentGateway": zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "name": zod.string()
}).optional(),
  "saleId": zod.string().nullable(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getTransactionIncomeSchedulingsResponseTransactionSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "paymentId": zod.string().nullable(),
  "payment": zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "customerName": zod.string(),
  "customerEmail": zod.string(),
  "customerIdentity": zod.string(),
  "customerPhone": zod.string(),
  "customerSelected": zod.array(zod.enum(['customer_email', 'customer_identity', 'customer_phone'])),
  "partialAmountCents": zod.number(),
  "maxInstallmentsSum": zod.number(),
  "currentState": zod.enum(['created', 'expired', 'pending', 'payment_complete', 'payment_voided', 'payment_chargeback']),
  "deadlineDate": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "directLinkId": zod.string().nullable(),
  "directLink": zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "type": zod.enum(['sale', 'cart', 'payment']),
  "status": zod.enum(['created', 'expired', 'payment_complete', 'canceled', 'payment_voided', 'payment_chargeback']).optional(),
  "isExpired": zod.boolean(),
  "totalAmountCents": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionDirectLinkTotalAmountCentsMin),
  "customMessage": zod.string().nullish(),
  "enablePayment": zod.boolean().nullish(),
  "customerId": zod.string().nullish(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "originId": zod.string().nullish(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "moldCartId": zod.string().nullish(),
  "moldCart": zod.object({
  "id": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "saleId": zod.string().nullish(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionDirectLinkSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getTransactionIncomeSchedulingsResponseTransactionDirectLinkSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "checkoutUrl": zod.string(),
  "expiresAt": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish()
}).optional(),
  "eventType": zod.enum(['credit', 'debit', 'fee_credit', 'fee_debit', 'chargeback_debit', 'refund_debit', 'anti_fraud_fee_debit']),
  "grossAmountCents": zod.number(),
  "amountCents": zod.number(),
  "grossDifferenceCents": zod.number(),
  "installmentTotal": zod.number(),
  "installmentNumber": zod.number(),
  "availableAt": zod.string().datetime(),
  "performedAt": zod.string().datetime().nullable(),
  "plannedAt": zod.string().datetime(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getTransactionIncomeSchedulingsResponse = zod.array(getTransactionIncomeSchedulingsResponseItem)


export const getSaleRefundRequestsQueryOffsetMin = 0;
export const getSaleRefundRequestsQueryLimitMax = 100;


export const getSaleRefundRequestsQueryParams = zod.object({
  "search": zod.string().nullish(),
  "status": zod.array(zod.enum(['pending', 'approved', 'denied'])).nullish(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "include": zod.array(zod.enum(['sale', 'sale.customer', 'sale.aggregatedVouchers'])).optional(),
  "offset": zod.number().min(getSaleRefundRequestsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getSaleRefundRequestsQueryLimitMax).optional()
})

export const getSaleRefundRequestsResponseSaleAmountCentsMin = 0;
export const getSaleRefundRequestsResponseSalePartialAmountCentsMin = 0;
export const getSaleRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleRefundRequestsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getSaleRefundRequestsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getSaleRefundRequestsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getSaleRefundRequestsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getSaleRefundRequestsResponseItem = zod.object({
  "id": zod.string(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getSaleRefundRequestsResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getSaleRefundRequestsResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getSaleRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getSaleRefundRequestsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleRefundRequestsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getSaleRefundRequestsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleRefundRequestsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getSaleRefundRequestsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "status": zod.enum(['pending', 'approved', 'denied']),
  "observation": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getSaleRefundRequestsResponse = zod.array(getSaleRefundRequestsResponseItem)


export const createSaleRefundRequestQueryParams = zod.object({
  "search": zod.string().nullish(),
  "status": zod.array(zod.enum(['pending', 'approved', 'denied'])).nullish(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "include": zod.array(zod.enum(['sale', 'sale.customer', 'sale.aggregatedVouchers'])).optional()
})

export const createSaleRefundRequestBody = zod.object({
  "observation": zod.string().nullish()
})


export const getRefundRequestQueryParams = zod.object({
  "include": zod.array(zod.enum(['sale', 'sale.customer', 'sale.aggregatedVouchers'])).optional()
})

export const getRefundRequestResponseSaleAmountCentsMin = 0;
export const getRefundRequestResponseSalePartialAmountCentsMin = 0;
export const getRefundRequestResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getRefundRequestResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getRefundRequestResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getRefundRequestResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getRefundRequestResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getRefundRequestResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getRefundRequestResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getRefundRequestResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getRefundRequestResponse = zod.object({
  "id": zod.string(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getRefundRequestResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getRefundRequestResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getRefundRequestResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getRefundRequestResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getRefundRequestResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getRefundRequestResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getRefundRequestResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getRefundRequestResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getRefundRequestResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getRefundRequestResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "status": zod.enum(['pending', 'approved', 'denied']),
  "observation": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const getSaleQueryParams = zod.object({
  "include": zod.array(zod.enum(['coupon', 'coupon.products', 'coupon.products.product', 'customer', 'origin', 'order', 'order.decisiveTransaction', 'order.decisiveTransaction.paymentMethod', 'aggregatedVouchers', 'tags', 'aggregatedRefundRequests', 'decisiveTransaction', 'decisiveTransaction.paymentMethod'])).optional()
})

export const getSaleResponseAmountCentsMin = 0;
export const getSaleResponsePartialAmountCentsMin = 0;
export const getSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleResponseCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getSaleResponseCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getSaleResponse = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getSaleResponseAmountCentsMin),
  "partialAmountCents": zod.number().min(getSaleResponsePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleResponseCouponProductsItemProductMinSaleTariffQuantityMin).max(getSaleResponseCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMin).max(getSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
})


export const getSaleItemsQueryOffsetMin = 0;
export const getSaleItemsQueryLimitMax = 100;


export const getSaleItemsQueryParams = zod.object({
  "offset": zod.number().min(getSaleItemsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getSaleItemsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product', 'product.thumbnail', 'reservation', 'productPackageProduct', 'productPackageProduct.productPackage'])).optional()
})

export const getSaleItemsResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleItemsResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleItemsResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleItemsResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleItemsResponseProductMinSaleTariffQuantityMin = 0;

export const getSaleItemsResponseProductMinSaleTariffQuantityMax = 100;
export const getSaleItemsResponseProductMaxSaleTariffQuantityMin = 0;

export const getSaleItemsResponseProductMaxSaleTariffQuantityMax = 100;
export const getSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleItemsResponseReservationProductMinSaleTariffQuantityMin = 0;

export const getSaleItemsResponseReservationProductMinSaleTariffQuantityMax = 100;
export const getSaleItemsResponseReservationProductMaxSaleTariffQuantityMin = 0;

export const getSaleItemsResponseReservationProductMaxSaleTariffQuantityMax = 100;
export const getSaleItemsResponseReservationSaleAmountCentsMin = 0;
export const getSaleItemsResponseReservationSalePartialAmountCentsMin = 0;
export const getSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMin = 0;

export const getSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMax = 100;
export const getSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMin = 0;

export const getSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMax = 100;


export const getSaleItemsResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleItemsResponseProductTariffSettingsMinSaleQuantityMin).max(getSaleItemsResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleItemsResponseProductTariffSettingsMaxSaleQuantityMin).max(getSaleItemsResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleItemsResponseProductMinSaleTariffQuantityMin).max(getSaleItemsResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleItemsResponseProductMaxSaleTariffQuantityMin).max(getSaleItemsResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "formattedScheduleTime": zod.string().nullish(),
  "reservationId": zod.string().nullish(),
  "reservation": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMin).max(getSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMin).max(getSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleItemsResponseReservationProductMinSaleTariffQuantityMin).max(getSaleItemsResponseReservationProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleItemsResponseReservationProductMaxSaleTariffQuantityMin).max(getSaleItemsResponseReservationProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string().nullable(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getSaleItemsResponseReservationSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getSaleItemsResponseReservationSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "name": zod.string(),
  "description": zod.string().nullish(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "productPackageUnitId": zod.string().nullable(),
  "productPackageProductId": zod.string().nullable(),
  "productPackageProduct": zod.object({
  "id": zod.string(),
  "productPackageId": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMin).max(getSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMin).max(getSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMin).max(getSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMin).max(getSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "productPackage": zod.object({
  "id": zod.string(),
  "appId": zod.string().optional(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).optional(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "imageId": zod.string().nullable(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "description": zod.string().nullable(),
  "shortDescription": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "voucherGuidelines": zod.string().nullable(),
  "deletedAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "discountPercentage": zod.number().optional(),
  "position": zod.number().optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getSaleItemsResponse = zod.array(getSaleItemsResponseItem)


export const getDetailedSaleItemsQueryOffsetMin = 0;
export const getDetailedSaleItemsQueryLimitMax = 100;


export const getDetailedSaleItemsQueryParams = zod.object({
  "offset": zod.number().min(getDetailedSaleItemsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getDetailedSaleItemsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product', 'product.thumbnail', 'sale', 'sale.customer', 'sale.decisiveTransaction', 'reservation', 'tariffs.tariff', 'tariffs.tariff.type', 'resources.resource', 'additionals.additional', 'partnershipItem', 'partnershipItem.partnership', 'partnershipAgent', 'partnershipProvider', 'couponProduct', 'productPackageProduct', 'productPackageProduct.productPackage'])).optional()
})

export const getDetailedSaleItemsResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseProductMinSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseProductMinSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseProductMaxSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseProductMaxSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseSaleAmountCentsMin = 0;
export const getDetailedSaleItemsResponseSalePartialAmountCentsMin = 0;
export const getDetailedSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseReservationProductMinSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseReservationProductMinSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseReservationProductMaxSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseReservationProductMaxSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseReservationSaleAmountCentsMin = 0;
export const getDetailedSaleItemsResponseReservationSalePartialAmountCentsMin = 0;
export const getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseTariffsItemQuantityMin = 0;
export const getDetailedSaleItemsResponseTariffsItemUnitPriceCentsMin = 0;
export const getDetailedSaleItemsResponseResourcesItemPriceCentsMin = 0;
export const getDetailedSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseAdditionalsItemUnitPriceCentsMin = 0;
export const getDetailedSaleItemsResponseAdditionalsItemUnitQuantityMin = 0;
export const getDetailedSaleItemsResponseAdditionalsItemPriceCentsMin = 0;
export const getDetailedSaleItemsResponseCouponProductProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseCouponProductProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseCouponProductProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseCouponProductProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseCouponProductProductMinSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseCouponProductProductMinSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseCouponProductProductMaxSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseCouponProductProductMaxSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMax = 100;
export const getDetailedSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMin = 0;

export const getDetailedSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMax = 100;


export const getDetailedSaleItemsResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedSaleItemsResponseProductTariffSettingsMinSaleQuantityMin).max(getDetailedSaleItemsResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedSaleItemsResponseProductTariffSettingsMaxSaleQuantityMin).max(getDetailedSaleItemsResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseProductMinSaleTariffQuantityMin).max(getDetailedSaleItemsResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseProductMaxSaleTariffQuantityMin).max(getDetailedSaleItemsResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getDetailedSaleItemsResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getDetailedSaleItemsResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getDetailedSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getDetailedSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getDetailedSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getDetailedSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "reservationId": zod.string().nullish(),
  "reservation": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMin).max(getDetailedSaleItemsResponseReservationProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMin).max(getDetailedSaleItemsResponseReservationProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseReservationProductMinSaleTariffQuantityMin).max(getDetailedSaleItemsResponseReservationProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseReservationProductMaxSaleTariffQuantityMin).max(getDetailedSaleItemsResponseReservationProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string().nullable(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getDetailedSaleItemsResponseReservationSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getDetailedSaleItemsResponseReservationSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getDetailedSaleItemsResponseReservationSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "name": zod.string(),
  "description": zod.string().nullish(),
  "createdAt": zod.string().datetime()
}).optional(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(getDetailedSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(getDetailedSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(getDetailedSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(getDetailedSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getDetailedSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getDetailedSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(getDetailedSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(getDetailedSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(getDetailedSaleItemsResponseTariffsItemQuantityMin),
  "unitPriceCents": zod.number().min(getDetailedSaleItemsResponseTariffsItemUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
})),
  "resources": zod.array(zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(getDetailedSaleItemsResponseResourcesItemPriceCentsMin),
  "createdAt": zod.string().datetime()
})),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(getDetailedSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getDetailedSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(getDetailedSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(getDetailedSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(getDetailedSaleItemsResponseAdditionalsItemUnitPriceCentsMin),
  "unitQuantity": zod.number().min(getDetailedSaleItemsResponseAdditionalsItemUnitQuantityMin),
  "priceCents": zod.number().min(getDetailedSaleItemsResponseAdditionalsItemPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
})),
  "partnershipItemId": zod.string().nullable(),
  "partnershipItem": zod.object({
  "id": zod.string(),
  "allProducts": zod.boolean(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "productId": zod.string().nullish(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}).nullish(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])),
  "commissionType": zod.enum(['amount_cents', 'percentage_fee']),
  "commissionAmountCents": zod.number().nullish(),
  "commissionPercentageFee": zod.number().nullish(),
  "includePlanFees": zod.boolean(),
  "planFeeDistributionMode": zod.enum(['proportional', 'provider_backed', 'agent_backed'])
}).optional(),
  "partnershipProviderId": zod.string().nullable(),
  "partnershipProvider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "partnershipAgentId": zod.string().nullable(),
  "partnershipAgent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "priceCents": zod.number(),
  "couponProduct": zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedSaleItemsResponseCouponProductProductTariffSettingsMinSaleQuantityMin).max(getDetailedSaleItemsResponseCouponProductProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedSaleItemsResponseCouponProductProductTariffSettingsMaxSaleQuantityMin).max(getDetailedSaleItemsResponseCouponProductProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseCouponProductProductMinSaleTariffQuantityMin).max(getDetailedSaleItemsResponseCouponProductProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseCouponProductProductMaxSaleTariffQuantityMin).max(getDetailedSaleItemsResponseCouponProductProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
}).optional(),
  "productPackageUnitId": zod.string().nullable(),
  "productPackageProductId": zod.string().nullable(),
  "productPackageProduct": zod.object({
  "id": zod.string(),
  "productPackageId": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMin).max(getDetailedSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMin).max(getDetailedSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMin).max(getDetailedSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMin).max(getDetailedSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "productPackage": zod.object({
  "id": zod.string(),
  "appId": zod.string().optional(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).optional(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "imageId": zod.string().nullable(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "description": zod.string().nullable(),
  "shortDescription": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "voucherGuidelines": zod.string().nullable(),
  "deletedAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "discountPercentage": zod.number().optional(),
  "position": zod.number().optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getDetailedSaleItemsResponse = zod.array(getDetailedSaleItemsResponseItem)


export const getSaleReservationsQueryOffsetMin = 0;
export const getSaleReservationsQueryLimitMax = 100;


export const getSaleReservationsQueryParams = zod.object({
  "offset": zod.number().min(getSaleReservationsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getSaleReservationsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product', 'product.thumbnail', 'sale', 'sale.customer'])).optional()
})

export const getSaleReservationsResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleReservationsResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleReservationsResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleReservationsResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleReservationsResponseProductMinSaleTariffQuantityMin = 0;

export const getSaleReservationsResponseProductMinSaleTariffQuantityMax = 100;
export const getSaleReservationsResponseProductMaxSaleTariffQuantityMin = 0;

export const getSaleReservationsResponseProductMaxSaleTariffQuantityMax = 100;
export const getSaleReservationsResponseSaleAmountCentsMin = 0;
export const getSaleReservationsResponseSalePartialAmountCentsMin = 0;
export const getSaleReservationsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleReservationsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleReservationsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleReservationsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleReservationsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getSaleReservationsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getSaleReservationsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getSaleReservationsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getSaleReservationsResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleReservationsResponseProductTariffSettingsMinSaleQuantityMin).max(getSaleReservationsResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleReservationsResponseProductTariffSettingsMaxSaleQuantityMin).max(getSaleReservationsResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleReservationsResponseProductMinSaleTariffQuantityMin).max(getSaleReservationsResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleReservationsResponseProductMaxSaleTariffQuantityMin).max(getSaleReservationsResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string().nullable(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getSaleReservationsResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getSaleReservationsResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleReservationsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getSaleReservationsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleReservationsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getSaleReservationsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleReservationsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getSaleReservationsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleReservationsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getSaleReservationsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "name": zod.string(),
  "description": zod.string().nullish(),
  "createdAt": zod.string().datetime()
})
export const getSaleReservationsResponse = zod.array(getSaleReservationsResponseItem)


export const getReservationQueryParams = zod.object({
  "include": zod.array(zod.enum(['product', 'product.thumbnail', 'sale', 'sale.customer'])).optional()
})

export const getReservationResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationResponseProductMinSaleTariffQuantityMin = 0;

export const getReservationResponseProductMinSaleTariffQuantityMax = 100;
export const getReservationResponseProductMaxSaleTariffQuantityMin = 0;

export const getReservationResponseProductMaxSaleTariffQuantityMax = 100;
export const getReservationResponseSaleAmountCentsMin = 0;
export const getReservationResponseSalePartialAmountCentsMin = 0;
export const getReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getReservationResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationResponseProductTariffSettingsMinSaleQuantityMin).max(getReservationResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationResponseProductTariffSettingsMaxSaleQuantityMin).max(getReservationResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationResponseProductMinSaleTariffQuantityMin).max(getReservationResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationResponseProductMaxSaleTariffQuantityMin).max(getReservationResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string().nullable(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getReservationResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getReservationResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "name": zod.string(),
  "description": zod.string().nullish(),
  "createdAt": zod.string().datetime()
})


export const getReservationTariffsQueryOffsetMin = 0;
export const getReservationTariffsQueryLimitMax = 100;


export const getReservationTariffsQueryParams = zod.object({
  "offset": zod.number().min(getReservationTariffsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getReservationTariffsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['tariff', 'tariff.type', 'tariff.group'])).optional()
})

export const getReservationTariffsResponseTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationTariffsResponseTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationTariffsResponseTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationTariffsResponseTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationTariffsResponseTariffProductMinSaleTariffQuantityMin = 0;

export const getReservationTariffsResponseTariffProductMinSaleTariffQuantityMax = 100;
export const getReservationTariffsResponseTariffProductMaxSaleTariffQuantityMin = 0;

export const getReservationTariffsResponseTariffProductMaxSaleTariffQuantityMax = 100;
export const getReservationTariffsResponseTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationTariffsResponseTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationTariffsResponseTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationTariffsResponseTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationTariffsResponseTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getReservationTariffsResponseTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getReservationTariffsResponseTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getReservationTariffsResponseTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getReservationTariffsResponseQuantityMin = 0;


export const getReservationTariffsResponseItem = zod.object({
  "id": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationTariffsResponseTariffProductTariffSettingsMinSaleQuantityMin).max(getReservationTariffsResponseTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationTariffsResponseTariffProductTariffSettingsMaxSaleQuantityMin).max(getReservationTariffsResponseTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationTariffsResponseTariffProductMinSaleTariffQuantityMin).max(getReservationTariffsResponseTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationTariffsResponseTariffProductMaxSaleTariffQuantityMin).max(getReservationTariffsResponseTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationTariffsResponseTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getReservationTariffsResponseTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationTariffsResponseTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getReservationTariffsResponseTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationTariffsResponseTariffGroupProductMinSaleTariffQuantityMin).max(getReservationTariffsResponseTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationTariffsResponseTariffGroupProductMaxSaleTariffQuantityMin).max(getReservationTariffsResponseTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(getReservationTariffsResponseQuantityMin),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getReservationTariffsResponse = zod.array(getReservationTariffsResponseItem)


export const rescheduleReservationBody = zod.object({
  "scheduleDate": zod.string().date(),
  "scheduleTime": zod.string().nullish()
}).and(zod.object({
  "verifications": zod.object({
  "ignoreSchedulings": zod.boolean().optional(),
  "ignoreAvailabilities": zod.boolean().optional(),
  "ignoreResources": zod.boolean().optional(),
  "ignoreSchedulingAntecedence": zod.boolean().optional()
}).optional()
}))

export const rescheduleReservationResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const rescheduleReservationResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const rescheduleReservationResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const rescheduleReservationResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const rescheduleReservationResponseProductMinSaleTariffQuantityMin = 0;

export const rescheduleReservationResponseProductMinSaleTariffQuantityMax = 100;
export const rescheduleReservationResponseProductMaxSaleTariffQuantityMin = 0;

export const rescheduleReservationResponseProductMaxSaleTariffQuantityMax = 100;
export const rescheduleReservationResponseSaleAmountCentsMin = 0;
export const rescheduleReservationResponseSalePartialAmountCentsMin = 0;
export const rescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const rescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const rescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const rescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const rescheduleReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const rescheduleReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const rescheduleReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const rescheduleReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const rescheduleReservationResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(rescheduleReservationResponseProductTariffSettingsMinSaleQuantityMin).max(rescheduleReservationResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(rescheduleReservationResponseProductTariffSettingsMaxSaleQuantityMin).max(rescheduleReservationResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(rescheduleReservationResponseProductMinSaleTariffQuantityMin).max(rescheduleReservationResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(rescheduleReservationResponseProductMaxSaleTariffQuantityMin).max(rescheduleReservationResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string().nullable(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(rescheduleReservationResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(rescheduleReservationResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(rescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(rescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(rescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(rescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(rescheduleReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(rescheduleReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(rescheduleReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(rescheduleReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "name": zod.string(),
  "description": zod.string().nullish(),
  "createdAt": zod.string().datetime()
})


export const secureRescheduleReservationBody = zod.object({
  "scheduleDate": zod.string().date(),
  "scheduleTime": zod.string().nullish()
})

export const secureRescheduleReservationResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const secureRescheduleReservationResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const secureRescheduleReservationResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const secureRescheduleReservationResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const secureRescheduleReservationResponseProductMinSaleTariffQuantityMin = 0;

export const secureRescheduleReservationResponseProductMinSaleTariffQuantityMax = 100;
export const secureRescheduleReservationResponseProductMaxSaleTariffQuantityMin = 0;

export const secureRescheduleReservationResponseProductMaxSaleTariffQuantityMax = 100;
export const secureRescheduleReservationResponseSaleAmountCentsMin = 0;
export const secureRescheduleReservationResponseSalePartialAmountCentsMin = 0;
export const secureRescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const secureRescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const secureRescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const secureRescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const secureRescheduleReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const secureRescheduleReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const secureRescheduleReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const secureRescheduleReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const secureRescheduleReservationResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(secureRescheduleReservationResponseProductTariffSettingsMinSaleQuantityMin).max(secureRescheduleReservationResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(secureRescheduleReservationResponseProductTariffSettingsMaxSaleQuantityMin).max(secureRescheduleReservationResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(secureRescheduleReservationResponseProductMinSaleTariffQuantityMin).max(secureRescheduleReservationResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(secureRescheduleReservationResponseProductMaxSaleTariffQuantityMin).max(secureRescheduleReservationResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string().nullable(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(secureRescheduleReservationResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(secureRescheduleReservationResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(secureRescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(secureRescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(secureRescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(secureRescheduleReservationResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(secureRescheduleReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(secureRescheduleReservationResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(secureRescheduleReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(secureRescheduleReservationResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "name": zod.string(),
  "description": zod.string().nullish(),
  "createdAt": zod.string().datetime()
})


export const getAppVouchersQueryOffsetMin = 0;
export const getAppVouchersQueryLimitMax = 100;


export const getAppVouchersQueryParams = zod.object({
  "offset": zod.number().min(getAppVouchersQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppVouchersQueryLimitMax).optional(),
  "since": zod.string().date().optional(),
  "until": zod.string().date().optional(),
  "revokedStatus": zod.enum(['all', 'active', 'revoked']).optional(),
  "consumedStatus": zod.enum(['all', 'open', 'consumed', 'exceptional']).optional(),
  "searchStatus": zod.enum(['all', 'open', 'consumed', 'revoked']).optional(),
  "searchTerm": zod.string().nullish(),
  "sortBy": zod.enum(['oldest', 'recent', 'consumption']).optional(),
  "include": zod.array(zod.enum(['product', 'sale', 'sale.customer', 'sale.order', 'sale.order.decisiveTransaction', 'sale.order.decisiveTransaction.paymentMethod', 'sale.decisiveTransaction', 'sale.decisiveTransaction.paymentMethod', 'tariffs.tariff', 'tariffs.tariff.type', 'tariffs.tariff.group', 'tariffs.saleTariff', 'tariffs.saleTariff.tariff', 'tariffs.saleTariff.tariff.type', 'tariffs.saleTariff.tariff.group', 'additionals.additional', 'additionals.saleAdditional', 'resources.saleResource.resource'])).optional()
})

export const getAppVouchersResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppVouchersResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppVouchersResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppVouchersResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppVouchersResponseProductMinSaleTariffQuantityMin = 0;

export const getAppVouchersResponseProductMinSaleTariffQuantityMax = 100;
export const getAppVouchersResponseProductMaxSaleTariffQuantityMin = 0;

export const getAppVouchersResponseProductMaxSaleTariffQuantityMax = 100;
export const getAppVouchersResponseSaleAmountCentsMin = 0;
export const getAppVouchersResponseSalePartialAmountCentsMin = 0;
export const getAppVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getAppVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getAppVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getAppVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const getAppVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const getAppVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const getAppVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const getAppVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getAppVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getAppVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getAppVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getAppVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin = 0;

export const getAppVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax = 100;
export const getAppVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin = 0;

export const getAppVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax = 100;
export const getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getAppVouchersResponseTariffsItemSaleTariffQuantityMin = 0;
export const getAppVouchersResponseTariffsItemSaleTariffUnitPriceCentsMin = 0;
export const getAppVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const getAppVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const getAppVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getAppVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin = 0;

export const getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax = 100;
export const getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getAppVouchersResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin = 0;
export const getAppVouchersResponseAdditionalsItemSaleAdditionalUnitQuantityMin = 0;
export const getAppVouchersResponseAdditionalsItemSaleAdditionalPriceCentsMin = 0;
export const getAppVouchersResponseResourcesItemSaleResourcePriceCentsMin = 0;


export const getAppVouchersResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppVouchersResponseProductTariffSettingsMinSaleQuantityMin).max(getAppVouchersResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppVouchersResponseProductTariffSettingsMaxSaleQuantityMin).max(getAppVouchersResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppVouchersResponseProductMinSaleTariffQuantityMin).max(getAppVouchersResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppVouchersResponseProductMaxSaleTariffQuantityMin).max(getAppVouchersResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getAppVouchersResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getAppVouchersResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getAppVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getAppVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getAppVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "saleItemId": zod.string().nullable(),
  "predecessorId": zod.string().nullable(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "formattedScheduleTime": zod.string().nullish(),
  "code": zod.string(),
  "secret": zod.string(),
  "barcode": zod.string().nullish(),
  "qrCodeContent": zod.string(),
  "finalAmountCents": zod.number().nullable(),
  "fullAmountCents": zod.number().nullable(),
  "tariffs": zod.array(zod.object({
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(getAppVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(getAppVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(getAppVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(getAppVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getAppVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getAppVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(getAppVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(getAppVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "saleTariffId": zod.string(),
  "saleTariff": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin).max(getAppVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin).max(getAppVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin).max(getAppVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin).max(getAppVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin).max(getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin).max(getAppVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(getAppVouchersResponseTariffsItemSaleTariffQuantityMin),
  "unitPriceCents": zod.number().min(getAppVouchersResponseTariffsItemSaleTariffUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number(),
  "discountPerUnit": zod.array(zod.number())
})),
  "additionals": zod.array(zod.object({
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(getAppVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getAppVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(getAppVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(getAppVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "saleAdditionalId": zod.string(),
  "saleAdditional": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin).max(getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin).max(getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin).max(getAppVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(getAppVouchersResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin),
  "unitQuantity": zod.number().min(getAppVouchersResponseAdditionalsItemSaleAdditionalUnitQuantityMin),
  "priceCents": zod.number().min(getAppVouchersResponseAdditionalsItemSaleAdditionalPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "resources": zod.array(zod.object({
  "saleResourceId": zod.string(),
  "saleResource": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(getAppVouchersResponseResourcesItemSaleResourcePriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "createdAt": zod.string().datetime(),
  "consumedAt": zod.string().datetime().nullish(),
  "revokedAt": zod.string().datetime().nullish(),
  "exceptionallyConsumedAt": zod.string().datetime().nullish(),
  "expiresAt": zod.string().datetime().nullish()
})
export const getAppVouchersResponse = zod.array(getAppVouchersResponseItem)


export const getSaleVouchersQueryOffsetMin = 0;
export const getSaleVouchersQueryLimitMax = 100;


export const getSaleVouchersQueryParams = zod.object({
  "offset": zod.number().min(getSaleVouchersQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getSaleVouchersQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product', 'sale', 'sale.customer', 'sale.order', 'sale.order.decisiveTransaction', 'sale.order.decisiveTransaction.paymentMethod', 'sale.decisiveTransaction', 'sale.decisiveTransaction.paymentMethod', 'tariffs.tariff', 'tariffs.tariff.type', 'tariffs.tariff.group', 'tariffs.saleTariff', 'tariffs.saleTariff.tariff', 'tariffs.saleTariff.tariff.type', 'tariffs.saleTariff.tariff.group', 'additionals.additional', 'additionals.saleAdditional', 'resources.saleResource.resource'])).optional(),
  "revokedStatus": zod.enum(['all', 'active', 'revoked']).optional(),
  "consumedStatus": zod.enum(['all', 'open', 'consumed', 'exceptional']).optional(),
  "sortBy": zod.enum(['oldest', 'recent', 'consumption']).optional()
})

export const getSaleVouchersResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleVouchersResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleVouchersResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleVouchersResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleVouchersResponseProductMinSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseProductMinSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseProductMaxSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseProductMaxSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseSaleAmountCentsMin = 0;
export const getSaleVouchersResponseSalePartialAmountCentsMin = 0;
export const getSaleVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseTariffsItemSaleTariffQuantityMin = 0;
export const getSaleVouchersResponseTariffsItemSaleTariffUnitPriceCentsMin = 0;
export const getSaleVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getSaleVouchersResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin = 0;
export const getSaleVouchersResponseAdditionalsItemSaleAdditionalUnitQuantityMin = 0;
export const getSaleVouchersResponseAdditionalsItemSaleAdditionalPriceCentsMin = 0;
export const getSaleVouchersResponseResourcesItemSaleResourcePriceCentsMin = 0;


export const getSaleVouchersResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleVouchersResponseProductTariffSettingsMinSaleQuantityMin).max(getSaleVouchersResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleVouchersResponseProductTariffSettingsMaxSaleQuantityMin).max(getSaleVouchersResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleVouchersResponseProductMinSaleTariffQuantityMin).max(getSaleVouchersResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleVouchersResponseProductMaxSaleTariffQuantityMin).max(getSaleVouchersResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getSaleVouchersResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getSaleVouchersResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getSaleVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getSaleVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getSaleVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getSaleVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "saleItemId": zod.string().nullable(),
  "predecessorId": zod.string().nullable(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "formattedScheduleTime": zod.string().nullish(),
  "code": zod.string(),
  "secret": zod.string(),
  "barcode": zod.string().nullish(),
  "qrCodeContent": zod.string(),
  "finalAmountCents": zod.number().nullable(),
  "fullAmountCents": zod.number().nullable(),
  "tariffs": zod.array(zod.object({
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(getSaleVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(getSaleVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(getSaleVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(getSaleVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getSaleVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getSaleVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(getSaleVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(getSaleVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "saleTariffId": zod.string(),
  "saleTariff": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin).max(getSaleVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin).max(getSaleVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin).max(getSaleVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin).max(getSaleVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin).max(getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin).max(getSaleVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(getSaleVouchersResponseTariffsItemSaleTariffQuantityMin),
  "unitPriceCents": zod.number().min(getSaleVouchersResponseTariffsItemSaleTariffUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number(),
  "discountPerUnit": zod.array(zod.number())
})),
  "additionals": zod.array(zod.object({
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(getSaleVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getSaleVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(getSaleVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(getSaleVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "saleAdditionalId": zod.string(),
  "saleAdditional": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin).max(getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin).max(getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin).max(getSaleVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(getSaleVouchersResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin),
  "unitQuantity": zod.number().min(getSaleVouchersResponseAdditionalsItemSaleAdditionalUnitQuantityMin),
  "priceCents": zod.number().min(getSaleVouchersResponseAdditionalsItemSaleAdditionalPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "resources": zod.array(zod.object({
  "saleResourceId": zod.string(),
  "saleResource": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(getSaleVouchersResponseResourcesItemSaleResourcePriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "createdAt": zod.string().datetime(),
  "consumedAt": zod.string().datetime().nullish(),
  "revokedAt": zod.string().datetime().nullish(),
  "exceptionallyConsumedAt": zod.string().datetime().nullish(),
  "expiresAt": zod.string().datetime().nullish()
})
export const getSaleVouchersResponse = zod.array(getSaleVouchersResponseItem)


export const getReservationVouchersQueryOffsetMin = 0;
export const getReservationVouchersQueryLimitMax = 100;


export const getReservationVouchersQueryParams = zod.object({
  "offset": zod.number().min(getReservationVouchersQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getReservationVouchersQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product', 'sale', 'sale.customer', 'sale.order', 'sale.order.decisiveTransaction', 'sale.order.decisiveTransaction.paymentMethod', 'sale.decisiveTransaction', 'sale.decisiveTransaction.paymentMethod', 'tariffs.tariff', 'tariffs.tariff.type', 'tariffs.tariff.group', 'tariffs.saleTariff', 'tariffs.saleTariff.tariff', 'tariffs.saleTariff.tariff.type', 'tariffs.saleTariff.tariff.group', 'additionals.additional', 'additionals.saleAdditional', 'resources.saleResource.resource'])).optional(),
  "revokedStatus": zod.enum(['all', 'active', 'revoked']).optional(),
  "consumedStatus": zod.enum(['all', 'open', 'consumed', 'exceptional']).optional(),
  "sortBy": zod.enum(['oldest', 'recent', 'consumption']).optional()
})

export const getReservationVouchersResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationVouchersResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationVouchersResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationVouchersResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationVouchersResponseProductMinSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseProductMinSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseProductMaxSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseProductMaxSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseSaleAmountCentsMin = 0;
export const getReservationVouchersResponseSalePartialAmountCentsMin = 0;
export const getReservationVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseTariffsItemSaleTariffQuantityMin = 0;
export const getReservationVouchersResponseTariffsItemSaleTariffUnitPriceCentsMin = 0;
export const getReservationVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getReservationVouchersResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin = 0;
export const getReservationVouchersResponseAdditionalsItemSaleAdditionalUnitQuantityMin = 0;
export const getReservationVouchersResponseAdditionalsItemSaleAdditionalPriceCentsMin = 0;
export const getReservationVouchersResponseResourcesItemSaleResourcePriceCentsMin = 0;


export const getReservationVouchersResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationVouchersResponseProductTariffSettingsMinSaleQuantityMin).max(getReservationVouchersResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationVouchersResponseProductTariffSettingsMaxSaleQuantityMin).max(getReservationVouchersResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationVouchersResponseProductMinSaleTariffQuantityMin).max(getReservationVouchersResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationVouchersResponseProductMaxSaleTariffQuantityMin).max(getReservationVouchersResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getReservationVouchersResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getReservationVouchersResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getReservationVouchersResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getReservationVouchersResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getReservationVouchersResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getReservationVouchersResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "saleItemId": zod.string().nullable(),
  "predecessorId": zod.string().nullable(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "formattedScheduleTime": zod.string().nullish(),
  "code": zod.string(),
  "secret": zod.string(),
  "barcode": zod.string().nullish(),
  "qrCodeContent": zod.string(),
  "finalAmountCents": zod.number().nullable(),
  "fullAmountCents": zod.number().nullable(),
  "tariffs": zod.array(zod.object({
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(getReservationVouchersResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(getReservationVouchersResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(getReservationVouchersResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(getReservationVouchersResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getReservationVouchersResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getReservationVouchersResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(getReservationVouchersResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(getReservationVouchersResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "saleTariffId": zod.string(),
  "saleTariff": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin).max(getReservationVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin).max(getReservationVouchersResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin).max(getReservationVouchersResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin).max(getReservationVouchersResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin).max(getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin).max(getReservationVouchersResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(getReservationVouchersResponseTariffsItemSaleTariffQuantityMin),
  "unitPriceCents": zod.number().min(getReservationVouchersResponseTariffsItemSaleTariffUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number(),
  "discountPerUnit": zod.array(zod.number())
})),
  "additionals": zod.array(zod.object({
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(getReservationVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getReservationVouchersResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(getReservationVouchersResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(getReservationVouchersResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "saleAdditionalId": zod.string(),
  "saleAdditional": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin).max(getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin).max(getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin).max(getReservationVouchersResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(getReservationVouchersResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin),
  "unitQuantity": zod.number().min(getReservationVouchersResponseAdditionalsItemSaleAdditionalUnitQuantityMin),
  "priceCents": zod.number().min(getReservationVouchersResponseAdditionalsItemSaleAdditionalPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "resources": zod.array(zod.object({
  "saleResourceId": zod.string(),
  "saleResource": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(getReservationVouchersResponseResourcesItemSaleResourcePriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "createdAt": zod.string().datetime(),
  "consumedAt": zod.string().datetime().nullish(),
  "revokedAt": zod.string().datetime().nullish(),
  "exceptionallyConsumedAt": zod.string().datetime().nullish(),
  "expiresAt": zod.string().datetime().nullish()
})
export const getReservationVouchersResponse = zod.array(getReservationVouchersResponseItem)


export const getVoucherQueryParams = zod.object({
  "include": zod.array(zod.enum(['product', 'sale', 'sale.customer', 'sale.order', 'sale.order.decisiveTransaction', 'sale.order.decisiveTransaction.paymentMethod', 'sale.decisiveTransaction', 'sale.decisiveTransaction.paymentMethod', 'tariffs.tariff', 'tariffs.tariff.type', 'tariffs.tariff.group', 'tariffs.saleTariff', 'tariffs.saleTariff.tariff', 'tariffs.saleTariff.tariff.type', 'tariffs.saleTariff.tariff.group', 'additionals.additional', 'additionals.saleAdditional', 'resources.saleResource.resource'])).optional()
})

export const getVoucherResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getVoucherResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getVoucherResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getVoucherResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getVoucherResponseProductMinSaleTariffQuantityMin = 0;

export const getVoucherResponseProductMinSaleTariffQuantityMax = 100;
export const getVoucherResponseProductMaxSaleTariffQuantityMin = 0;

export const getVoucherResponseProductMaxSaleTariffQuantityMax = 100;
export const getVoucherResponseSaleAmountCentsMin = 0;
export const getVoucherResponseSalePartialAmountCentsMin = 0;
export const getVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const getVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const getVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const getVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const getVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin = 0;

export const getVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax = 100;
export const getVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin = 0;

export const getVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax = 100;
export const getVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getVoucherResponseTariffsItemSaleTariffQuantityMin = 0;
export const getVoucherResponseTariffsItemSaleTariffUnitPriceCentsMin = 0;
export const getVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const getVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const getVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin = 0;

export const getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax = 100;
export const getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getVoucherResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin = 0;
export const getVoucherResponseAdditionalsItemSaleAdditionalUnitQuantityMin = 0;
export const getVoucherResponseAdditionalsItemSaleAdditionalPriceCentsMin = 0;
export const getVoucherResponseResourcesItemSaleResourcePriceCentsMin = 0;


export const getVoucherResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getVoucherResponseProductTariffSettingsMinSaleQuantityMin).max(getVoucherResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getVoucherResponseProductTariffSettingsMaxSaleQuantityMin).max(getVoucherResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getVoucherResponseProductMinSaleTariffQuantityMin).max(getVoucherResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getVoucherResponseProductMaxSaleTariffQuantityMin).max(getVoucherResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getVoucherResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getVoucherResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "saleItemId": zod.string().nullable(),
  "predecessorId": zod.string().nullable(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "formattedScheduleTime": zod.string().nullish(),
  "code": zod.string(),
  "secret": zod.string(),
  "barcode": zod.string().nullish(),
  "qrCodeContent": zod.string(),
  "finalAmountCents": zod.number().nullable(),
  "fullAmountCents": zod.number().nullable(),
  "tariffs": zod.array(zod.object({
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(getVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(getVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(getVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(getVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(getVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(getVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "saleTariffId": zod.string(),
  "saleTariff": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin).max(getVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin).max(getVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin).max(getVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin).max(getVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin).max(getVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin).max(getVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(getVoucherResponseTariffsItemSaleTariffQuantityMin),
  "unitPriceCents": zod.number().min(getVoucherResponseTariffsItemSaleTariffUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number(),
  "discountPerUnit": zod.array(zod.number())
})),
  "additionals": zod.array(zod.object({
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(getVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(getVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(getVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "saleAdditionalId": zod.string(),
  "saleAdditional": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin).max(getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin).max(getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin).max(getVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(getVoucherResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin),
  "unitQuantity": zod.number().min(getVoucherResponseAdditionalsItemSaleAdditionalUnitQuantityMin),
  "priceCents": zod.number().min(getVoucherResponseAdditionalsItemSaleAdditionalPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "resources": zod.array(zod.object({
  "saleResourceId": zod.string(),
  "saleResource": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(getVoucherResponseResourcesItemSaleResourcePriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "createdAt": zod.string().datetime(),
  "consumedAt": zod.string().datetime().nullish(),
  "revokedAt": zod.string().datetime().nullish(),
  "exceptionallyConsumedAt": zod.string().datetime().nullish(),
  "expiresAt": zod.string().datetime().nullish()
})


export const getAppQrCodeVoucherQueryParams = zod.object({
  "content": zod.string(),
  "include": zod.array(zod.enum(['product', 'sale', 'sale.customer', 'sale.order', 'sale.order.decisiveTransaction', 'sale.order.decisiveTransaction.paymentMethod', 'sale.decisiveTransaction', 'sale.decisiveTransaction.paymentMethod', 'tariffs.tariff', 'tariffs.tariff.type', 'tariffs.tariff.group', 'tariffs.saleTariff', 'tariffs.saleTariff.tariff', 'tariffs.saleTariff.tariff.type', 'tariffs.saleTariff.tariff.group', 'additionals.additional', 'additionals.saleAdditional', 'resources.saleResource.resource'])).optional()
})

export const getAppQrCodeVoucherResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseProductMinSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseProductMinSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseProductMaxSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseProductMaxSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseSaleAmountCentsMin = 0;
export const getAppQrCodeVoucherResponseSalePartialAmountCentsMin = 0;
export const getAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseTariffsItemSaleTariffQuantityMin = 0;
export const getAppQrCodeVoucherResponseTariffsItemSaleTariffUnitPriceCentsMin = 0;
export const getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin = 0;
export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalUnitQuantityMin = 0;
export const getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalPriceCentsMin = 0;
export const getAppQrCodeVoucherResponseResourcesItemSaleResourcePriceCentsMin = 0;


export const getAppQrCodeVoucherResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseProductTariffSettingsMinSaleQuantityMin).max(getAppQrCodeVoucherResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseProductTariffSettingsMaxSaleQuantityMin).max(getAppQrCodeVoucherResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseProductMinSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseProductMaxSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getAppQrCodeVoucherResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(getAppQrCodeVoucherResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "saleItemId": zod.string().nullable(),
  "predecessorId": zod.string().nullable(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "formattedScheduleTime": zod.string().nullish(),
  "code": zod.string(),
  "secret": zod.string(),
  "barcode": zod.string().nullish(),
  "qrCodeContent": zod.string(),
  "finalAmountCents": zod.number().nullable(),
  "fullAmountCents": zod.number().nullable(),
  "tariffs": zod.array(zod.object({
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "saleTariffId": zod.string(),
  "saleTariff": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(getAppQrCodeVoucherResponseTariffsItemSaleTariffQuantityMin),
  "unitPriceCents": zod.number().min(getAppQrCodeVoucherResponseTariffsItemSaleTariffUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number(),
  "discountPerUnit": zod.array(zod.number())
})),
  "additionals": zod.array(zod.object({
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "saleAdditionalId": zod.string(),
  "saleAdditional": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin).max(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin).max(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin),
  "unitQuantity": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalUnitQuantityMin),
  "priceCents": zod.number().min(getAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "resources": zod.array(zod.object({
  "saleResourceId": zod.string(),
  "saleResource": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(getAppQrCodeVoucherResponseResourcesItemSaleResourcePriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "createdAt": zod.string().datetime(),
  "consumedAt": zod.string().datetime().nullish(),
  "revokedAt": zod.string().datetime().nullish(),
  "exceptionallyConsumedAt": zod.string().datetime().nullish(),
  "expiresAt": zod.string().datetime().nullish()
})


export const consumeAppQrCodeVoucherQueryParams = zod.object({
  "include": zod.array(zod.enum(['product', 'sale', 'sale.customer', 'sale.order', 'sale.order.decisiveTransaction', 'sale.order.decisiveTransaction.paymentMethod', 'sale.decisiveTransaction', 'sale.decisiveTransaction.paymentMethod', 'tariffs.tariff', 'tariffs.tariff.type', 'tariffs.tariff.group', 'tariffs.saleTariff', 'tariffs.saleTariff.tariff', 'tariffs.saleTariff.tariff.type', 'tariffs.saleTariff.tariff.group', 'additionals.additional', 'additionals.saleAdditional', 'resources.saleResource.resource'])).optional()
})

export const consumeAppQrCodeVoucherBody = zod.object({
  "content": zod.string()
})

export const consumeAppQrCodeVoucherResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseProductMinSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseProductMinSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseProductMaxSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseProductMaxSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseSaleAmountCentsMin = 0;
export const consumeAppQrCodeVoucherResponseSalePartialAmountCentsMin = 0;
export const consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffQuantityMin = 0;
export const consumeAppQrCodeVoucherResponseTariffsItemSaleTariffUnitPriceCentsMin = 0;
export const consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin = 0;

export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax = 100;
export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin = 0;
export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalUnitQuantityMin = 0;
export const consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalPriceCentsMin = 0;
export const consumeAppQrCodeVoucherResponseResourcesItemSaleResourcePriceCentsMin = 0;


export const consumeAppQrCodeVoucherResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseProductTariffSettingsMinSaleQuantityMin).max(consumeAppQrCodeVoucherResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseProductTariffSettingsMaxSaleQuantityMin).max(consumeAppQrCodeVoucherResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseProductMinSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseProductMaxSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(consumeAppQrCodeVoucherResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(consumeAppQrCodeVoucherResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "saleItemId": zod.string().nullable(),
  "predecessorId": zod.string().nullable(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "formattedScheduleTime": zod.string().nullish(),
  "code": zod.string(),
  "secret": zod.string(),
  "barcode": zod.string().nullish(),
  "qrCodeContent": zod.string(),
  "finalAmountCents": zod.number().nullable(),
  "fullAmountCents": zod.number().nullable(),
  "tariffs": zod.array(zod.object({
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "saleTariffId": zod.string(),
  "saleTariff": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffQuantityMin),
  "unitPriceCents": zod.number().min(consumeAppQrCodeVoucherResponseTariffsItemSaleTariffUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number(),
  "discountPerUnit": zod.array(zod.number())
})),
  "additionals": zod.array(zod.object({
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "saleAdditionalId": zod.string(),
  "saleAdditional": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin).max(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin).max(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin).max(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin),
  "unitQuantity": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalUnitQuantityMin),
  "priceCents": zod.number().min(consumeAppQrCodeVoucherResponseAdditionalsItemSaleAdditionalPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "resources": zod.array(zod.object({
  "saleResourceId": zod.string(),
  "saleResource": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(consumeAppQrCodeVoucherResponseResourcesItemSaleResourcePriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "createdAt": zod.string().datetime(),
  "consumedAt": zod.string().datetime().nullish(),
  "revokedAt": zod.string().datetime().nullish(),
  "exceptionallyConsumedAt": zod.string().datetime().nullish(),
  "expiresAt": zod.string().datetime().nullish()
})


export const getAppEventsQueryOffsetMin = 0;
export const getAppEventsQueryLimitMax = 100;


export const getAppEventsQueryParams = zod.object({
  "offset": zod.number().min(getAppEventsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppEventsQueryLimitMax).optional(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "sortBy": zod.enum(['recent', 'oldest']).optional(),
  "eventTypes": zod.array(zod.enum(['SALE_STATE_CHANGE', 'INCOME_SCHEDULINGS_UPDATE', 'sale.create', 'sale.update', 'saleItem.reschedule', 'reservation.reschedule', 'voucher.create', 'voucher.consume', 'voucher.revoke', 'saleTag.create', 'saleTag.delete'])).optional()
})

export const getAppEventsResponseItem = zod.object({
  "id": zod.string(),
  "eventType": zod.enum(['SALE_STATE_CHANGE', 'INCOME_SCHEDULINGS_UPDATE', 'sale.create', 'sale.update', 'saleItem.reschedule', 'reservation.reschedule', 'voucher.create', 'voucher.consume', 'voucher.revoke', 'saleTag.create', 'saleTag.delete']),
  "metadata": zod.object({
  "saleId": zod.string(),
  "stateFrom": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']).nullable(),
  "stateTo": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled'])
}).or(zod.object({
  "transactionId": zod.string(),
  "saleId": zod.string().nullish(),
  "paymentId": zod.string().nullish(),
  "directLinkId": zod.string().nullish()
})).or(zod.object({
  "saleId": zod.string()
})).or(zod.object({
  "saleId": zod.string()
})).or(zod.object({
  "reservationId": zod.string(),
  "scheduleDate": zod.string().optional(),
  "scheduleTime": zod.string().optional()
})).or(zod.object({
  "saleItemId": zod.string(),
  "saleId": zod.string(),
  "scheduleDate": zod.string().optional(),
  "scheduleTime": zod.string().optional()
})).or(zod.object({
  "voucherId": zod.string()
})).or(zod.object({
  "voucherId": zod.string(),
  "exceptionalConsumption": zod.boolean()
})).or(zod.object({
  "voucherId": zod.string()
})).or(zod.object({
  "saleTagId": zod.string(),
  "saleId": zod.string(),
  "tagId": zod.string()
})).or(zod.object({
  "saleTagId": zod.string(),
  "saleId": zod.string(),
  "tagId": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "acknowledgedAt": zod.string().datetime().nullable()
})
export const getAppEventsResponse = zod.array(getAppEventsResponseItem)


export const acknowledgeAppEventResponse = zod.object({
  "id": zod.string(),
  "eventType": zod.enum(['SALE_STATE_CHANGE', 'INCOME_SCHEDULINGS_UPDATE', 'sale.create', 'sale.update', 'saleItem.reschedule', 'reservation.reschedule', 'voucher.create', 'voucher.consume', 'voucher.revoke', 'saleTag.create', 'saleTag.delete']),
  "metadata": zod.object({
  "saleId": zod.string(),
  "stateFrom": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']).nullable(),
  "stateTo": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled'])
}).or(zod.object({
  "transactionId": zod.string(),
  "saleId": zod.string().nullish(),
  "paymentId": zod.string().nullish(),
  "directLinkId": zod.string().nullish()
})).or(zod.object({
  "saleId": zod.string()
})).or(zod.object({
  "saleId": zod.string()
})).or(zod.object({
  "reservationId": zod.string(),
  "scheduleDate": zod.string().optional(),
  "scheduleTime": zod.string().optional()
})).or(zod.object({
  "saleItemId": zod.string(),
  "saleId": zod.string(),
  "scheduleDate": zod.string().optional(),
  "scheduleTime": zod.string().optional()
})).or(zod.object({
  "voucherId": zod.string()
})).or(zod.object({
  "voucherId": zod.string(),
  "exceptionalConsumption": zod.boolean()
})).or(zod.object({
  "voucherId": zod.string()
})).or(zod.object({
  "saleTagId": zod.string(),
  "saleId": zod.string(),
  "tagId": zod.string()
})).or(zod.object({
  "saleTagId": zod.string(),
  "saleId": zod.string(),
  "tagId": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "acknowledgedAt": zod.string().datetime().nullable()
})


export const getAppWebhooksQueryOffsetMin = 0;
export const getAppWebhooksQueryLimitMax = 100;


export const getAppWebhooksQueryParams = zod.object({
  "offset": zod.number().min(getAppWebhooksQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppWebhooksQueryLimitMax).optional(),
  "ids": zod.array(zod.string()).optional(),
  "eventTypes": zod.array(zod.enum(['SALE_STATE_CHANGE', 'INCOME_SCHEDULINGS_UPDATE', 'sale.create', 'sale.update', 'saleItem.reschedule', 'reservation.reschedule', 'voucher.create', 'voucher.consume', 'voucher.revoke', 'saleTag.create', 'saleTag.delete'])).optional(),
  "enabledStatus": zod.enum(['all', 'enabled', 'disabled']).optional()
})

export const getAppWebhooksResponseItem = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "url": zod.string(),
  "headers": zod.record(zod.string(), zod.string()).nullable(),
  "eventTypes": zod.array(zod.enum(['SALE_STATE_CHANGE', 'INCOME_SCHEDULINGS_UPDATE', 'sale.create', 'sale.update', 'saleItem.reschedule', 'reservation.reschedule', 'voucher.create', 'voucher.consume', 'voucher.revoke', 'saleTag.create', 'saleTag.delete'])),
  "enabled": zod.boolean(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppWebhooksResponse = zod.array(getAppWebhooksResponseItem)


export const createAppWebhookBody = zod.object({
  "url": zod.string(),
  "headers": zod.record(zod.string(), zod.string()).nullish(),
  "eventTypes": zod.array(zod.enum(['SALE_STATE_CHANGE', 'INCOME_SCHEDULINGS_UPDATE', 'sale.create', 'sale.update', 'saleItem.reschedule', 'reservation.reschedule', 'voucher.create', 'voucher.consume', 'voucher.revoke', 'saleTag.create', 'saleTag.delete'])).min(1),
  "enabled": zod.boolean()
})


export const getWebhookResponse = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "url": zod.string(),
  "headers": zod.record(zod.string(), zod.string()).nullable(),
  "eventTypes": zod.array(zod.enum(['SALE_STATE_CHANGE', 'INCOME_SCHEDULINGS_UPDATE', 'sale.create', 'sale.update', 'saleItem.reschedule', 'reservation.reschedule', 'voucher.create', 'voucher.consume', 'voucher.revoke', 'saleTag.create', 'saleTag.delete'])),
  "enabled": zod.boolean(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const getWebhookRequestAttemptsQueryLimitMax = 100;


export const getWebhookRequestAttemptsQueryParams = zod.object({
  "eventTypes": zod.array(zod.enum(['SALE_STATE_CHANGE', 'INCOME_SCHEDULINGS_UPDATE', 'sale.create', 'sale.update', 'saleItem.reschedule', 'reservation.reschedule', 'voucher.create', 'voucher.consume', 'voucher.revoke', 'saleTag.create', 'saleTag.delete'])).optional(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "limit": zod.number().min(1).max(getWebhookRequestAttemptsQueryLimitMax).optional(),
  "cursor": zod.string().nullish()
})

export const getWebhookRequestAttemptsResponseItem = zod.object({
  "id": zod.string(),
  "httpStatus": zod.number().nullable(),
  "response": zod.string().nullable()
})
export const getWebhookRequestAttemptsResponse = zod.array(getWebhookRequestAttemptsResponseItem)


export const getAppCouponsQueryOffsetMin = 0;
export const getAppCouponsQueryLimitMax = 100;


export const getAppCouponsQueryParams = zod.object({
  "offset": zod.number().min(getAppCouponsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppCouponsQueryLimitMax).optional(),
  "filter": zod.enum(['active', 'all', 'archived']).optional(),
  "search": zod.string().optional(),
  "include": zod.array(zod.enum(['products', 'usage', 'partnership', 'partnership.provider', 'partnership.agent', 'partnership.proposal'])).optional(),
  "productIds": zod.array(zod.string()).optional(),
  "validSince": zod.string().datetime().nullish(),
  "validUntil": zod.string().datetime().nullish(),
  "belongsToPartnership": zod.boolean().optional()
})

export const getAppCouponsResponseItem = zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullish(),
  "maxUse": zod.number().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "maxUsePerUser": zod.number().nullish(),
  "usage": zod.number().optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "internalName": zod.string().nullable()
})).nullish(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).optional(),
  "isPartnershipDefault": zod.boolean().nullish(),
  "constraintType": zod.enum(['include', 'exclude']).nullish(),
  "description": zod.string().nullish(),
  "availableAt": zod.string().datetime().nullish(),
  "minAmountCents": zod.number().nullish(),
  "usageRestrictions": zod.array(zod.object({
  "type": zod.enum(['range', 'date_only', 'week_days']),
  "date": zod.string().date().nullish(),
  "fromDate": zod.string().datetime().nullish(),
  "toDate": zod.string().datetime().nullish(),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).nullish()
})).nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})
export const getAppCouponsResponse = zod.array(getAppCouponsResponseItem)


export const createAppCouponBody = zod.object({
  "code": zod.string(),
  "type": zod.enum(['nominal', 'percentage']),
  "usageType": zod.enum(['general', 'product']),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullish(),
  "maxUse": zod.number().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "maxUsePerUser": zod.number().nullish(),
  "productIds": zod.array(zod.string()).nullish(),
  "constraintType": zod.enum(['include', 'exclude']).nullish(),
  "description": zod.string().nullish(),
  "availableAt": zod.string().datetime().nullish(),
  "minAmountCents": zod.number().nullish(),
  "usageRestrictions": zod.array(zod.object({
  "type": zod.enum(['range', 'date_only', 'week_days']),
  "date": zod.string().date().nullish(),
  "fromDate": zod.string().datetime().nullish(),
  "toDate": zod.string().datetime().nullish(),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).nullish()
})).nullish(),
  "createdAt": zod.string().datetime().optional(),
  "updatedAt": zod.string().datetime().optional(),
  "deletedAt": zod.string().datetime().nullish()
})


export const validateAppCouponCodeQueryParams = zod.object({
  "include": zod.array(zod.enum(['coupon.products', 'coupon.usage'])).optional()
})

export const validateAppCouponCodeBody = zod.object({
  "code": zod.string(),
  "productIds": zod.array(zod.string()).nullish(),
  "customerId": zod.string().nullish()
})

export const validateAppCouponCodeResponse = zod.object({
  "valid": zod.boolean(),
  "coupon": zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullish(),
  "maxUse": zod.number().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "maxUsePerUser": zod.number().nullish(),
  "usage": zod.number().optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "internalName": zod.string().nullable()
})).nullish(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).optional(),
  "isPartnershipDefault": zod.boolean().nullish(),
  "constraintType": zod.enum(['include', 'exclude']).nullish(),
  "description": zod.string().nullish(),
  "availableAt": zod.string().datetime().nullish(),
  "minAmountCents": zod.number().nullish(),
  "usageRestrictions": zod.array(zod.object({
  "type": zod.enum(['range', 'date_only', 'week_days']),
  "date": zod.string().date().nullish(),
  "fromDate": zod.string().datetime().nullish(),
  "toDate": zod.string().datetime().nullish(),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).nullish()
})).nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})
})


export const getCouponResponse = zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullish(),
  "maxUse": zod.number().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "maxUsePerUser": zod.number().nullish(),
  "usage": zod.number().optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "internalName": zod.string().nullable()
})).nullish(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).optional(),
  "isPartnershipDefault": zod.boolean().nullish(),
  "constraintType": zod.enum(['include', 'exclude']).nullish(),
  "description": zod.string().nullish(),
  "availableAt": zod.string().datetime().nullish(),
  "minAmountCents": zod.number().nullish(),
  "usageRestrictions": zod.array(zod.object({
  "type": zod.enum(['range', 'date_only', 'week_days']),
  "date": zod.string().date().nullish(),
  "fromDate": zod.string().datetime().nullish(),
  "toDate": zod.string().datetime().nullish(),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).nullish()
})).nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})


export const updateCouponBody = zod.object({
  "code": zod.string(),
  "type": zod.enum(['nominal', 'percentage']),
  "usageType": zod.enum(['general', 'product']),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullish(),
  "maxUse": zod.number().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "maxUsePerUser": zod.number().nullish(),
  "productIds": zod.array(zod.string()).nullish(),
  "constraintType": zod.enum(['include', 'exclude']).nullish(),
  "description": zod.string().nullish(),
  "availableAt": zod.string().datetime().nullish(),
  "minAmountCents": zod.number().nullish(),
  "usageRestrictions": zod.array(zod.object({
  "type": zod.enum(['range', 'date_only', 'week_days']),
  "date": zod.string().date().nullish(),
  "fromDate": zod.string().datetime().nullish(),
  "toDate": zod.string().datetime().nullish(),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).nullish()
})).nullish(),
  "createdAt": zod.string().datetime().optional(),
  "updatedAt": zod.string().datetime().optional(),
  "deletedAt": zod.string().datetime().nullish()
})

export const updateCouponResponse = zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullish(),
  "maxUse": zod.number().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "maxUsePerUser": zod.number().nullish(),
  "usage": zod.number().optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "internalName": zod.string().nullable()
})).nullish(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).optional(),
  "isPartnershipDefault": zod.boolean().nullish(),
  "constraintType": zod.enum(['include', 'exclude']).nullish(),
  "description": zod.string().nullish(),
  "availableAt": zod.string().datetime().nullish(),
  "minAmountCents": zod.number().nullish(),
  "usageRestrictions": zod.array(zod.object({
  "type": zod.enum(['range', 'date_only', 'week_days']),
  "date": zod.string().date().nullish(),
  "fromDate": zod.string().datetime().nullish(),
  "toDate": zod.string().datetime().nullish(),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).nullish()
})).nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})


export const getCouponProductsQueryOffsetMin = 0;
export const getCouponProductsQueryLimitMax = 100;


export const getCouponProductsQueryParams = zod.object({
  "offset": zod.number().min(getCouponProductsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getCouponProductsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product'])).optional()
})

export const getCouponProductsResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getCouponProductsResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getCouponProductsResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getCouponProductsResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getCouponProductsResponseProductMinSaleTariffQuantityMin = 0;

export const getCouponProductsResponseProductMinSaleTariffQuantityMax = 100;
export const getCouponProductsResponseProductMaxSaleTariffQuantityMin = 0;

export const getCouponProductsResponseProductMaxSaleTariffQuantityMax = 100;


export const getCouponProductsResponseItem = zod.object({
  "id": zod.string(),
  "couponId": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getCouponProductsResponseProductTariffSettingsMinSaleQuantityMin).max(getCouponProductsResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getCouponProductsResponseProductTariffSettingsMaxSaleQuantityMin).max(getCouponProductsResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getCouponProductsResponseProductMinSaleTariffQuantityMin).max(getCouponProductsResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getCouponProductsResponseProductMaxSaleTariffQuantityMin).max(getCouponProductsResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getCouponProductsResponse = zod.array(getCouponProductsResponseItem)


export const restoreCouponResponse = zod.object({
  "id": zod.string(),
  "code": zod.string(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullish(),
  "maxUse": zod.number().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "maxUsePerUser": zod.number().nullish(),
  "usage": zod.number().optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "internalName": zod.string().nullable()
})).nullish(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).optional(),
  "isPartnershipDefault": zod.boolean().nullish(),
  "constraintType": zod.enum(['include', 'exclude']).nullish(),
  "description": zod.string().nullish(),
  "availableAt": zod.string().datetime().nullish(),
  "minAmountCents": zod.number().nullish(),
  "usageRestrictions": zod.array(zod.object({
  "type": zod.enum(['range', 'date_only', 'week_days']),
  "date": zod.string().date().nullish(),
  "fromDate": zod.string().datetime().nullish(),
  "toDate": zod.string().datetime().nullish(),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).nullish()
})).nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})


export const createAppExternalSaleBodyAmountCentsMin = 0;


export const createAppExternalSaleBody = zod.object({
  "customerId": zod.string().nullish(),
  "customer": zod.object({
  "firstName": zod.string(),
  "lastName": zod.string(),
  "identity": zod.string().nullish(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullish(),
  "corporateName": zod.string().nullish(),
  "countryCode": zod.string().nullish(),
  "birthdate": zod.string().date().nullish(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullish(),
  "email": zod.string().email().nullish(),
  "phone": zod.string().nullish()
}).optional(),
  "items": zod.array(zod.object({
  "productId": zod.string(),
  "partnershipItemId": zod.string().optional(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "quantity": zod.number()
})).min(1),
  "resources": zod.array(zod.object({
  "id": zod.string(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "quantity": zod.number()
}))
})).nullish(),
  "autoAllocateResources": zod.boolean().nullish(),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "inputValue": zod.string().nullish(),
  "quantity": zod.number().nullish()
})).nullish(),
  "productPackageUnitId": zod.string().nullish(),
  "productPackageProductId": zod.string().nullish()
})),
  "amountCents": zod.number().min(createAppExternalSaleBodyAmountCentsMin).optional(),
  "attributes": zod.array(zod.string()).min(1),
  "verifications": zod.object({
  "ignoreSchedulings": zod.boolean().optional(),
  "ignoreAvailabilities": zod.boolean().optional(),
  "ignoreResources": zod.boolean().optional(),
  "ignoreTariffDependency": zod.boolean().optional(),
  "ignoreSchedulingAntecedence": zod.boolean().optional()
}).optional()
})


export const cancelExternalSaleResponseAmountCentsMin = 0;
export const cancelExternalSaleResponsePartialAmountCentsMin = 0;
export const cancelExternalSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const cancelExternalSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const cancelExternalSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const cancelExternalSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const cancelExternalSaleResponseCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const cancelExternalSaleResponseCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const cancelExternalSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const cancelExternalSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const cancelExternalSaleResponse = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(cancelExternalSaleResponseAmountCentsMin),
  "partialAmountCents": zod.number().min(cancelExternalSaleResponsePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(cancelExternalSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(cancelExternalSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(cancelExternalSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(cancelExternalSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(cancelExternalSaleResponseCouponProductsItemProductMinSaleTariffQuantityMin).max(cancelExternalSaleResponseCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(cancelExternalSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMin).max(cancelExternalSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
})


export const getDirectSalePaymentOptionsQueryOffsetMin = 0;
export const getDirectSalePaymentOptionsQueryLimitMax = 100;


export const getDirectSalePaymentOptionsQueryParams = zod.object({
  "offset": zod.number().min(getDirectSalePaymentOptionsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getDirectSalePaymentOptionsQueryLimitMax).optional(),
  "sessionId": zod.string().optional()
})

export const getDirectSalePaymentOptionsResponseItem = zod.object({
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "discountPercentage": zod.number().nullish()
}),
  "softDescriptor": zod.string().nullish(),
  "amountCents": zod.number(),
  "brands": zod.array(zod.object({
  "code": zod.string()
})).optional(),
  "installmentOptions": zod.array(zod.object({
  "division": zod.number(),
  "amountCents": zod.number(),
  "installmentCents": zod.number()
})),
  "fingerprints": zod.array(zod.object({
  "type": zod.enum(['cybersource', 'clearsale']),
  "data": zod.record(zod.string(), zod.any())
})).nullish()
})
export const getDirectSalePaymentOptionsResponse = zod.array(getDirectSalePaymentOptionsResponseItem)


export const getDirectSaleTransactionQueryParams = zod.object({
  "include": zod.array(zod.enum(['paymentMethod'])).optional()
})

export const getDirectSaleTransactionResponse = zod.object({
  "id": zod.string(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "discountPercentage": zod.number().nullish()
}).optional(),
  "pending": zod.boolean(),
  "paymentUrl": zod.string().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "pix": zod.object({
  "code": zod.string(),
  "qrCode": zod.string(),
  "receiverInfo": zod.object({
  "financialInstitution": zod.string(),
  "companyName": zod.string(),
  "cnpj": zod.string()
})
}).nullish(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded'])
}).nullable()


export const getAppTagsQueryOffsetMin = 0;
export const getAppTagsQueryLimitMax = 100;


export const getAppTagsQueryParams = zod.object({
  "offset": zod.number().min(getAppTagsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppTagsQueryLimitMax).optional(),
  "search": zod.string().optional()
})

export const getAppTagsResponseItem = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})
export const getAppTagsResponse = zod.array(getAppTagsResponseItem)


export const createAppTagBody = zod.object({
  "name": zod.string()
})


export const updateTagBody = zod.object({
  "name": zod.string()
})

export const updateTagResponse = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})


export const getSaleTagsQueryOffsetMin = 0;
export const getSaleTagsQueryLimitMax = 100;


export const getSaleTagsQueryParams = zod.object({
  "offset": zod.number().min(getSaleTagsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getSaleTagsQueryLimitMax).optional()
})

export const getSaleTagsResponseItem = zod.object({
  "id": zod.string(),
  "tagId": zod.string(),
  "saleId": zod.string(),
  "tag": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
}),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getSaleTagsResponse = zod.array(getSaleTagsResponseItem)


export const createSaleTagBody = zod.object({
  "tagId": zod.string()
})


export const consumeVoucherBody = zod.object({
  "secret": zod.string().optional()
})

export const consumeVoucherResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherResponseProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherResponseProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherResponseProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherResponseProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherResponseSaleAmountCentsMin = 0;
export const consumeVoucherResponseSalePartialAmountCentsMin = 0;
export const consumeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherResponseTariffsItemSaleTariffQuantityMin = 0;
export const consumeVoucherResponseTariffsItemSaleTariffUnitPriceCentsMin = 0;
export const consumeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin = 0;
export const consumeVoucherResponseAdditionalsItemSaleAdditionalUnitQuantityMin = 0;
export const consumeVoucherResponseAdditionalsItemSaleAdditionalPriceCentsMin = 0;
export const consumeVoucherResponseResourcesItemSaleResourcePriceCentsMin = 0;


export const consumeVoucherResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherResponseProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherResponseProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherResponseProductMinSaleTariffQuantityMin).max(consumeVoucherResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherResponseProductMaxSaleTariffQuantityMin).max(consumeVoucherResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(consumeVoucherResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(consumeVoucherResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(consumeVoucherResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(consumeVoucherResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "saleItemId": zod.string().nullable(),
  "predecessorId": zod.string().nullable(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "formattedScheduleTime": zod.string().nullish(),
  "code": zod.string(),
  "secret": zod.string(),
  "barcode": zod.string().nullish(),
  "qrCodeContent": zod.string(),
  "finalAmountCents": zod.number().nullable(),
  "fullAmountCents": zod.number().nullable(),
  "tariffs": zod.array(zod.object({
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(consumeVoucherResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(consumeVoucherResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(consumeVoucherResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(consumeVoucherResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "saleTariffId": zod.string(),
  "saleTariff": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin).max(consumeVoucherResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin).max(consumeVoucherResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin).max(consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin).max(consumeVoucherResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(consumeVoucherResponseTariffsItemSaleTariffQuantityMin),
  "unitPriceCents": zod.number().min(consumeVoucherResponseTariffsItemSaleTariffUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number(),
  "discountPerUnit": zod.array(zod.number())
})),
  "additionals": zod.array(zod.object({
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(consumeVoucherResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(consumeVoucherResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "saleAdditionalId": zod.string(),
  "saleAdditional": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin).max(consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin).max(consumeVoucherResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(consumeVoucherResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin),
  "unitQuantity": zod.number().min(consumeVoucherResponseAdditionalsItemSaleAdditionalUnitQuantityMin),
  "priceCents": zod.number().min(consumeVoucherResponseAdditionalsItemSaleAdditionalPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "resources": zod.array(zod.object({
  "saleResourceId": zod.string(),
  "saleResource": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(consumeVoucherResponseResourcesItemSaleResourcePriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "createdAt": zod.string().datetime(),
  "consumedAt": zod.string().datetime().nullish(),
  "revokedAt": zod.string().datetime().nullish(),
  "exceptionallyConsumedAt": zod.string().datetime().nullish(),
  "expiresAt": zod.string().datetime().nullish()
})


export const consumeVoucherExceptionallyResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseSaleAmountCentsMin = 0;
export const consumeVoucherExceptionallyResponseSalePartialAmountCentsMin = 0;
export const consumeVoucherExceptionallyResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffQuantityMin = 0;
export const consumeVoucherExceptionallyResponseTariffsItemSaleTariffUnitPriceCentsMin = 0;
export const consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin = 0;

export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax = 100;
export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin = 0;
export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalUnitQuantityMin = 0;
export const consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalPriceCentsMin = 0;
export const consumeVoucherExceptionallyResponseResourcesItemSaleResourcePriceCentsMin = 0;


export const consumeVoucherExceptionallyResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherExceptionallyResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherExceptionallyResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseProductMinSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseProductMaxSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(consumeVoucherExceptionallyResponseSaleAmountCentsMin),
  "partialAmountCents": zod.number().min(consumeVoucherExceptionallyResponseSalePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherExceptionallyResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherExceptionallyResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "saleItemId": zod.string().nullable(),
  "predecessorId": zod.string().nullable(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "formattedScheduleTime": zod.string().nullish(),
  "code": zod.string(),
  "secret": zod.string(),
  "barcode": zod.string().nullish(),
  "qrCodeContent": zod.string(),
  "finalAmountCents": zod.number().nullable(),
  "fullAmountCents": zod.number().nullable(),
  "tariffs": zod.array(zod.object({
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "saleTariffId": zod.string(),
  "saleTariff": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseTariffsItemSaleTariffTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemSaleTariffQuantityMin),
  "unitPriceCents": zod.number().min(consumeVoucherExceptionallyResponseTariffsItemSaleTariffUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number(),
  "discountPerUnit": zod.array(zod.number())
})),
  "additionals": zod.array(zod.object({
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "saleAdditionalId": zod.string(),
  "saleAdditional": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMin).max(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMin).max(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMin).max(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalUnitPriceCentsMin),
  "unitQuantity": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalUnitQuantityMin),
  "priceCents": zod.number().min(consumeVoucherExceptionallyResponseAdditionalsItemSaleAdditionalPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "resources": zod.array(zod.object({
  "saleResourceId": zod.string(),
  "saleResource": zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(consumeVoucherExceptionallyResponseResourcesItemSaleResourcePriceCentsMin),
  "createdAt": zod.string().datetime()
}).optional(),
  "quantity": zod.number().min(1),
  "unitPriceCents": zod.number(),
  "fullPriceCents": zod.number(),
  "finalPriceCents": zod.number()
})),
  "createdAt": zod.string().datetime(),
  "consumedAt": zod.string().datetime().nullish(),
  "revokedAt": zod.string().datetime().nullish(),
  "exceptionallyConsumedAt": zod.string().datetime().nullish(),
  "expiresAt": zod.string().datetime().nullish()
})


export const sendVoucherBody = zod.object({
  "email": zod.boolean().optional(),
  "sms": zod.boolean().optional()
})


export const getCustomerResponse = zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
})


export const getCustomerSalesQueryOffsetMin = 0;
export const getCustomerSalesQueryLimitMax = 100;


export const getCustomerSalesQueryParams = zod.object({
  "offset": zod.number().min(getCustomerSalesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getCustomerSalesQueryLimitMax).optional(),
  "currentState": zod.array(zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled'])).optional(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "include": zod.array(zod.enum(['coupon', 'coupon.products', 'coupon.products.product', 'customer', 'origin', 'order', 'order.decisiveTransaction', 'order.decisiveTransaction.paymentMethod', 'aggregatedVouchers', 'tags', 'aggregatedRefundRequests', 'decisiveTransaction', 'decisiveTransaction.paymentMethod'])).optional(),
  "sortBy": zod.enum(['recent']).optional()
})

export const getCustomerSalesResponseAmountCentsMin = 0;
export const getCustomerSalesResponsePartialAmountCentsMin = 0;
export const getCustomerSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getCustomerSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getCustomerSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getCustomerSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getCustomerSalesResponseCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getCustomerSalesResponseCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getCustomerSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getCustomerSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getCustomerSalesResponseItem = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getCustomerSalesResponseAmountCentsMin),
  "partialAmountCents": zod.number().min(getCustomerSalesResponsePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getCustomerSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getCustomerSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getCustomerSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getCustomerSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getCustomerSalesResponseCouponProductsItemProductMinSaleTariffQuantityMin).max(getCustomerSalesResponseCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getCustomerSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMin).max(getCustomerSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
})
export const getCustomerSalesResponse = zod.array(getCustomerSalesResponseItem)


export const createAppDirectSaleBody = zod.object({
  "checkoutId": zod.string().optional(),
  "providerId": zod.string().optional(),
  "customerId": zod.string().nullish(),
  "customer": zod.object({
  "firstName": zod.string(),
  "lastName": zod.string(),
  "identity": zod.string().nullish(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullish(),
  "corporateName": zod.string().nullish(),
  "countryCode": zod.string().nullish(),
  "birthdate": zod.string().date().nullish(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullish(),
  "email": zod.string().email().nullish(),
  "phone": zod.string().nullish()
}).optional(),
  "items": zod.array(zod.object({
  "productId": zod.string(),
  "partnershipItemId": zod.string().optional(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "quantity": zod.number()
})).min(1),
  "resources": zod.array(zod.object({
  "id": zod.string(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "quantity": zod.number()
}))
})).nullish(),
  "autoAllocateResources": zod.boolean().nullish(),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "inputValue": zod.string().nullish(),
  "quantity": zod.number().nullish()
})).nullish(),
  "productPackageUnitId": zod.string().nullish(),
  "productPackageProductId": zod.string().nullish()
}))
})


export const getAppDirectSalesQueryOffsetMin = 0;
export const getAppDirectSalesQueryLimitMax = 100;


export const getAppDirectSalesQueryParams = zod.object({
  "offset": zod.number().min(getAppDirectSalesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppDirectSalesQueryLimitMax).optional(),
  "currentState": zod.array(zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled'])).optional(),
  "since": zod.string().datetime().nullish(),
  "until": zod.string().datetime().nullish(),
  "appIds": zod.array(zod.string()).optional(),
  "productIds": zod.array(zod.string()).optional(),
  "include": zod.array(zod.enum(['app', 'customer', 'aggregatedVouchers'])).optional(),
  "sortBy": zod.enum(['recent']).optional()
})

export const getAppDirectSalesResponseAmountCentsMin = 0;
export const getAppDirectSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppDirectSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppDirectSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppDirectSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppDirectSalesResponseCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getAppDirectSalesResponseCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getAppDirectSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppDirectSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getAppDirectSalesResponseItem = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "decisiveTransactionId": zod.string().nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getAppDirectSalesResponseAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppDirectSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getAppDirectSalesResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppDirectSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppDirectSalesResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppDirectSalesResponseCouponProductsItemProductMinSaleTariffQuantityMin).max(getAppDirectSalesResponseCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppDirectSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMin).max(getAppDirectSalesResponseCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
})
export const getAppDirectSalesResponse = zod.array(getAppDirectSalesResponseItem)


export const getDetailedDirectSaleItemsQueryOffsetMin = 0;
export const getDetailedDirectSaleItemsQueryLimitMax = 100;


export const getDetailedDirectSaleItemsQueryParams = zod.object({
  "offset": zod.number().min(getDetailedDirectSaleItemsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getDetailedDirectSaleItemsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product', 'sale', 'partnershipItem.product', 'partnershipItem.partnership', 'partnershipItem.partnership.provider', 'partnershipItem.partnership.agent', 'partnershipItem.partnership.publicProposal', 'productPackageProduct', 'productPackageProduct.productPackage'])).optional()
})

export const getDetailedDirectSaleItemsResponseSaleAmountCentsMin = 0;
export const getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseTariffsItemQuantityMin = 0;
export const getDetailedDirectSaleItemsResponseTariffsItemUnitPriceCentsMin = 0;
export const getDetailedDirectSaleItemsResponseResourcesItemPriceCentsMin = 0;
export const getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseAdditionalsItemUnitPriceCentsMin = 0;
export const getDetailedDirectSaleItemsResponseAdditionalsItemUnitQuantityMin = 0;
export const getDetailedDirectSaleItemsResponseAdditionalsItemPriceCentsMin = 0;
export const getDetailedDirectSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMax = 100;
export const getDetailedDirectSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMin = 0;

export const getDetailedDirectSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMax = 100;


export const getDetailedDirectSaleItemsResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}).optional(),
  "saleId": zod.string(),
  "sale": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "decisiveTransactionId": zod.string().nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getDetailedDirectSaleItemsResponseSaleAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMin).max(getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMin).max(getDetailedDirectSaleItemsResponseSaleCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
}).optional(),
  "scheduleDate": zod.string().date().nullish(),
  "scheduleTime": zod.string().nullish(),
  "reservationId": zod.string().nullish(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "tariffId": zod.string(),
  "tariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedDirectSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMin).max(getDetailedDirectSaleItemsResponseTariffsItemTariffProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedDirectSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMin).max(getDetailedDirectSaleItemsResponseTariffsItemTariffProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedDirectSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMin).max(getDetailedDirectSaleItemsResponseTariffsItemTariffProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedDirectSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMin).max(getDetailedDirectSaleItemsResponseTariffsItemTariffProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMin).max(getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMin).max(getDetailedDirectSaleItemsResponseTariffsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "quantity": zod.number().min(getDetailedDirectSaleItemsResponseTariffsItemQuantityMin),
  "unitPriceCents": zod.number().min(getDetailedDirectSaleItemsResponseTariffsItemUnitPriceCentsMin),
  "createdAt": zod.string().datetime()
})),
  "resources": zod.array(zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "resourceId": zod.string(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "createdAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number().min(getDetailedDirectSaleItemsResponseResourcesItemPriceCentsMin),
  "createdAt": zod.string().datetime()
})),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "saleItemId": zod.string(),
  "additionalId": zod.string(),
  "additional": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMin).max(getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMin).max(getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMin).max(getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMin).max(getDetailedDirectSaleItemsResponseAdditionalsItemAdditionalProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
}).optional(),
  "unitPriceCents": zod.number().min(getDetailedDirectSaleItemsResponseAdditionalsItemUnitPriceCentsMin),
  "unitQuantity": zod.number().min(getDetailedDirectSaleItemsResponseAdditionalsItemUnitQuantityMin),
  "priceCents": zod.number().min(getDetailedDirectSaleItemsResponseAdditionalsItemPriceCentsMin),
  "data": zod.object({
  "inputLabel": zod.string().nullable(),
  "inputValue": zod.string().nullable()
}),
  "createdAt": zod.string().datetime()
})),
  "partnershipItemId": zod.string(),
  "partnershipItem": zod.object({
  "id": zod.string(),
  "allProducts": zod.boolean(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "productId": zod.string().nullish(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}).nullish(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])),
  "commissionType": zod.enum(['amount_cents', 'percentage_fee']),
  "commissionAmountCents": zod.number().nullish(),
  "commissionPercentageFee": zod.number().nullish(),
  "includePlanFees": zod.boolean(),
  "planFeeDistributionMode": zod.enum(['proportional', 'provider_backed', 'agent_backed'])
}).optional(),
  "productPackageUnitId": zod.string().nullable(),
  "productPackageProductId": zod.string().nullable(),
  "productPackageProduct": zod.object({
  "id": zod.string(),
  "productPackageId": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDetailedDirectSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMin).max(getDetailedDirectSaleItemsResponseProductPackageProductProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDetailedDirectSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMin).max(getDetailedDirectSaleItemsResponseProductPackageProductProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDetailedDirectSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMin).max(getDetailedDirectSaleItemsResponseProductPackageProductProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDetailedDirectSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMin).max(getDetailedDirectSaleItemsResponseProductPackageProductProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "productPackage": zod.object({
  "id": zod.string(),
  "appId": zod.string().optional(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).optional(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "imageId": zod.string().nullable(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "description": zod.string().nullable(),
  "shortDescription": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "voucherGuidelines": zod.string().nullable(),
  "deletedAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "discountPercentage": zod.number().optional(),
  "position": zod.number().optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getDetailedDirectSaleItemsResponse = zod.array(getDetailedDirectSaleItemsResponseItem)


export const getDirectSaleQueryParams = zod.object({
  "include": zod.array(zod.enum(['app', 'customer', 'aggregatedVouchers'])).optional()
})

export const getDirectSaleResponseAmountCentsMin = 0;
export const getDirectSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getDirectSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getDirectSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getDirectSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getDirectSaleResponseCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getDirectSaleResponseCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getDirectSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getDirectSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getDirectSaleResponse = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "decisiveTransactionId": zod.string().nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getDirectSaleResponseAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getDirectSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getDirectSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getDirectSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getDirectSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getDirectSaleResponseCouponProductsItemProductMinSaleTariffQuantityMin).max(getDirectSaleResponseCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getDirectSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMin).max(getDirectSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
})


export const getAppArrangedProductsQueryOffsetMin = 0;
export const getAppArrangedProductsQueryLimitMax = 100;


export const getAppArrangedProductsQueryParams = zod.object({
  "offset": zod.number().min(getAppArrangedProductsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppArrangedProductsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['partnershipItem', 'partnershipItem.partnership', 'partnershipItem.partnership.provider', 'partnershipItem.partnership.agent', 'partnershipItem.partnership.publicProposal', 'defaultTariffGroupPriceInfo', 'defaultTariff'])).optional(),
  "schedulingSince": zod.string().date().optional(),
  "schedulingUntil": zod.string().date().optional(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])).optional(),
  "providerIds": zod.array(zod.string()).optional(),
  "search": zod.string().optional(),
  "sortBy": zod.enum(['recent', 'name']).optional()
})

export const getAppArrangedProductsResponseItem = zod.object({
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}),
  "partnershipItemId": zod.string().optional(),
  "partnershipItem": zod.object({
  "id": zod.string(),
  "allProducts": zod.boolean(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "productId": zod.string().nullish(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}).nullish(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])),
  "commissionType": zod.enum(['amount_cents', 'percentage_fee']),
  "commissionAmountCents": zod.number().nullish(),
  "commissionPercentageFee": zod.number().nullish(),
  "includePlanFees": zod.boolean(),
  "planFeeDistributionMode": zod.enum(['proportional', 'provider_backed', 'agent_backed'])
}).optional()
})
export const getAppArrangedProductsResponse = zod.array(getAppArrangedProductsResponseItem)


export const getAppScheduledProductsQueryOffsetMin = 0;
export const getAppScheduledProductsQueryLimitMax = 100;


export const getAppScheduledProductsQueryParams = zod.object({
  "offset": zod.number().min(getAppScheduledProductsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppScheduledProductsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['defaultTariff', 'defaultTariffGroupPriceInfo'])).optional(),
  "schedulingSince": zod.string().date().optional(),
  "schedulingUntil": zod.string().date().optional(),
  "search": zod.string().optional(),
  "sortBy": zod.enum(['recent', 'name']).optional(),
  "sellingModes": zod.array(zod.enum(['with_date_and_time', 'with_date_only', 'without_date'])).optional()
})

export const getAppScheduledProductsResponseTariffSettingsMinSaleQuantityMin = 0;

export const getAppScheduledProductsResponseTariffSettingsMinSaleQuantityMax = 100;
export const getAppScheduledProductsResponseTariffSettingsMaxSaleQuantityMin = 0;

export const getAppScheduledProductsResponseTariffSettingsMaxSaleQuantityMax = 100;
export const getAppScheduledProductsResponseMinSaleTariffQuantityMin = 0;

export const getAppScheduledProductsResponseMinSaleTariffQuantityMax = 100;
export const getAppScheduledProductsResponseMaxSaleTariffQuantityMin = 0;

export const getAppScheduledProductsResponseMaxSaleTariffQuantityMax = 100;


export const getAppScheduledProductsResponseItem = zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppScheduledProductsResponseTariffSettingsMinSaleQuantityMin).max(getAppScheduledProductsResponseTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppScheduledProductsResponseTariffSettingsMaxSaleQuantityMin).max(getAppScheduledProductsResponseTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppScheduledProductsResponseMinSaleTariffQuantityMin).max(getAppScheduledProductsResponseMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppScheduledProductsResponseMaxSaleTariffQuantityMin).max(getAppScheduledProductsResponseMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppScheduledProductsResponse = zod.array(getAppScheduledProductsResponseItem)


export const getArrangedProductQueryParams = zod.object({
  "include": zod.array(zod.enum(['partnershipItem', 'partnershipItem.partnership', 'partnershipItem.partnership.provider', 'partnershipItem.partnership.agent', 'partnershipItem.partnership.publicProposal', 'defaultTariffGroupPriceInfo', 'defaultTariff'])).optional(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])).optional()
})

export const getArrangedProductResponse = zod.object({
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}),
  "partnershipItemId": zod.string().optional(),
  "partnershipItem": zod.object({
  "id": zod.string(),
  "allProducts": zod.boolean(),
  "partnershipId": zod.string().nullish(),
  "partnership": zod.object({
  "id": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "agentId": zod.string(),
  "agent": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "publicProposalId": zod.string(),
  "publicProposal": zod.object({
  "id": zod.string(),
  "shareCode": zod.string(),
  "providerId": zod.string(),
  "provider": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "name": zod.string().nullish(),
  "description": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish()
}).optional(),
  "currentStatus": zod.enum(['pending', 'ongoing', 'declined', 'deactivated']),
  "affiliationCode": zod.string().nullish(),
  "maxPercentageDiscount": zod.number().nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().optional()
}).nullish(),
  "productId": zod.string().nullish(),
  "product": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
}).nullish(),
  "associationModels": zod.array(zod.enum(['affiliation', 'direct_purchase', 'resale'])),
  "commissionType": zod.enum(['amount_cents', 'percentage_fee']),
  "commissionAmountCents": zod.number().nullish(),
  "commissionPercentageFee": zod.number().nullish(),
  "includePlanFees": zod.boolean(),
  "planFeeDistributionMode": zod.enum(['proportional', 'provider_backed', 'agent_backed'])
}).optional()
})


export const getArrangedProductDetailedSchedulingsQueryOffsetMin = 0;
export const getArrangedProductDetailedSchedulingsQueryLimitMax = 100;


export const getArrangedProductDetailedSchedulingsQueryParams = zod.object({
  "offset": zod.number().min(getArrangedProductDetailedSchedulingsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getArrangedProductDetailedSchedulingsQueryLimitMax).optional(),
  "since": zod.string().date(),
  "until": zod.string().date(),
  "include": zod.array(zod.enum(['finalTariffGroup.tariffs.type'])).optional()
})

export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMinSaleTariffQuantityMin = 0;

export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMinSaleTariffQuantityMax = 100;
export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMaxSaleTariffQuantityMin = 0;

export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMaxSaleTariffQuantityMax = 100;
export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMinSaleTariffQuantityMin = 0;

export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMinSaleTariffQuantityMax = 100;
export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMaxSaleTariffQuantityMin = 0;

export const getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMaxSaleTariffQuantityMax = 100;


export const getArrangedProductDetailedSchedulingsResponseItem = zod.object({
  "id": zod.string(),
  "date": zod.string().date(),
  "consumableFromTime": zod.string().nullish(),
  "consumableToTime": zod.string().nullish(),
  "calendarIntervalMinutes": zod.number().nullish(),
  "durationMinutes": zod.number(),
  "antecedenceHours": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish(),
  "antecedenceHoursReferenceTime": zod.string().nullish(),
  "availableScheduleTimes": zod.array(zod.object({
  "time": zod.string(),
  "minutes": zod.number()
})).nullish(),
  "finalTariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().optional(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMinSaleQuantityMin).max(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMaxSaleQuantityMin).max(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMinSaleTariffQuantityMin).max(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMaxSaleTariffQuantityMin).max(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMinSaleQuantityMin).max(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin).max(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMinSaleTariffQuantityMin).max(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMaxSaleTariffQuantityMin).max(getArrangedProductDetailedSchedulingsResponseFinalTariffGroupTariffsItemGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}))
}),
  "requiresSeatSelection": zod.boolean(),
  "occupationRates": zod.object({
  "scheduleQuantities": zod.array(zod.object({
  "time": zod.string().nullable(),
  "minutes": zod.number().nullable(),
  "occupation": zod.object({
  "availableAmount": zod.number().nullable(),
  "occupationRate": zod.number().nullable()
})
})),
  "tariffScheduleQuantities": zod.array(zod.object({
  "tariffId": zod.string(),
  "occupationSchedule": zod.array(zod.object({
  "time": zod.string().nullable(),
  "minutes": zod.number().nullable(),
  "occupation": zod.object({
  "availableAmount": zod.number().nullable(),
  "occupationRate": zod.number().nullable()
})
}))
})),
  "additionalScheduleQuantities": zod.array(zod.object({
  "additionalId": zod.string(),
  "occupationSchedule": zod.array(zod.object({
  "time": zod.string().nullable(),
  "minutes": zod.number().nullable(),
  "occupation": zod.object({
  "availableAmount": zod.number().nullable(),
  "occupationRate": zod.number().nullable()
})
}))
}))
}),
  "tariffScheduleUnitPrices": zod.array(zod.object({
  "time": zod.string().nullable(),
  "tariffs": zod.array(zod.object({
  "tariffId": zod.string(),
  "priceCents": zod.number()
}))
}))
})
export const getArrangedProductDetailedSchedulingsResponse = zod.array(getArrangedProductDetailedSchedulingsResponseItem)


export const getAppSellableProductsQueryOffsetMin = 0;
export const getAppSellableProductsQueryLimitMax = 100;


export const getAppSellableProductsQueryParams = zod.object({
  "offset": zod.number().min(getAppSellableProductsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppSellableProductsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['defaultTariff'])).optional()
})

export const getAppSellableProductsResponseItem = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
}).optional(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "name": zod.string(),
  "thumbnailUrl": zod.string().nullish(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherGuidelines": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish()
})
export const getAppSellableProductsResponse = zod.array(getAppSellableProductsResponseItem)


export const getAppSellableProductImagesQueryOffsetMin = 0;
export const getAppSellableProductImagesQueryLimitMax = 100;


export const getAppSellableProductImagesQueryParams = zod.object({
  "offset": zod.number().min(getAppSellableProductImagesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppSellableProductImagesQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['image'])).optional()
})

export const getAppSellableProductImagesResponseItem = zod.object({
  "id": zod.string(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "thumbnail": zod.boolean()
})
export const getAppSellableProductImagesResponse = zod.array(getAppSellableProductImagesResponseItem)


export const getAppSellableProductNextSchedulingResponse = zod.object({
  "id": zod.string(),
  "antecedenceMinutes": zod.number().nullable(),
  "antecedenceHoursReferenceTime": zod.string().nullable(),
  "durationMinutes": zod.number(),
  "appliesAt": zod.string().date()
})


export const getAppPartnershipProvidersQueryOffsetMin = 0;
export const getAppPartnershipProvidersQueryLimitMax = 100;


export const getAppPartnershipProvidersQueryParams = zod.object({
  "offset": zod.number().min(getAppPartnershipProvidersQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppPartnershipProvidersQueryLimitMax).optional(),
  "search": zod.string().optional(),
  "include": zod.array(zod.enum(['plan', 'info'])).optional()
})

export const getAppPartnershipProvidersResponseItem = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullish(),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "siteUrl": zod.string().nullish(),
  "info": zod.object({
  "identifier": zod.string(),
  "identifierType": zod.enum(['cpf', 'cnpj']),
  "fantasyName": zod.string(),
  "contact": zod.object({
  "name": zod.string().nullish(),
  "phone": zod.string()
}),
  "owner": zod.object({
  "name": zod.string()
}).nullish(),
  "infoStatus": zod.enum(['pending', 'approved', 'processing', 'analysing'])
}).nullish()
})
export const getAppPartnershipProvidersResponse = zod.array(getAppPartnershipProvidersResponseItem)


export const createAppExternalCustomerBody = zod.object({
  "firstName": zod.string(),
  "lastName": zod.string(),
  "identity": zod.string().nullish(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullish(),
  "corporateName": zod.string().nullish(),
  "countryCode": zod.string().nullish(),
  "birthdate": zod.string().date().nullish(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullish(),
  "email": zod.string().email().nullish(),
  "phone": zod.string().nullish()
})


export const getAppCustomersQueryOffsetMin = 0;
export const getAppCustomersQueryLimitMax = 100;


export const getAppCustomersQueryParams = zod.object({
  "offset": zod.number().min(getAppCustomersQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppCustomersQueryLimitMax).optional(),
  "search": zod.string().optional(),
  "sortBy": zod.enum(['recent']).optional()
})

export const getAppCustomersResponseItem = zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
})
export const getAppCustomersResponse = zod.array(getAppCustomersResponseItem)


export const transactDirectSaleBody = zod.object({
  "paymentMethodId": zod.string(),
  "returnUrl": zod.string().url().optional(),
  "paymentCard": zod.object({
  "cardNumber": zod.string(),
  "holder": zod.string(),
  "holderIdentity": zod.string().nullable(),
  "installments": zod.number(),
  "securityCode": zod.string(),
  "validityMonth": zod.string(),
  "validityYear": zod.string(),
  "postalCode": zod.string().nullish()
}).optional(),
  "externalAuthentication": zod.record(zod.string(), zod.any()).optional(),
  "deviceInfo": zod.object({
  "language": zod.string(),
  "screenHeight": zod.number(),
  "screenWidth": zod.number(),
  "timeZoneOffset": zod.number()
}),
  "sessionId": zod.string().nullish()
})

export const transactDirectSaleResponse = zod.object({
  "id": zod.string(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "discountPercentage": zod.number().nullish()
}).optional(),
  "pending": zod.boolean(),
  "paymentUrl": zod.string().nullish(),
  "expiresAt": zod.string().datetime().nullish(),
  "pix": zod.object({
  "code": zod.string(),
  "qrCode": zod.string(),
  "receiverInfo": zod.object({
  "financialInstitution": zod.string(),
  "companyName": zod.string(),
  "cnpj": zod.string()
})
}).nullish(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded'])
}).nullable()


export const getAppSaleOriginsQueryOffsetMin = 0;
export const getAppSaleOriginsQueryLimitMax = 100;


export const getAppSaleOriginsQueryParams = zod.object({
  "offset": zod.number().min(getAppSaleOriginsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppSaleOriginsQueryLimitMax).optional(),
  "ids": zod.array(zod.string()).optional(),
  "search": zod.string().optional(),
  "sortBy": zod.enum(['recent']).optional()
})

export const getAppSaleOriginsResponseItem = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppSaleOriginsResponse = zod.array(getAppSaleOriginsResponseItem)


export const authenticateCustomerResponse = zod.object({
  "token": zod.string()
})


export const getReservationRescheduleSchedulingsQueryOffsetMin = 0;
export const getReservationRescheduleSchedulingsQueryLimitMax = 100;


export const getReservationRescheduleSchedulingsQueryParams = zod.object({
  "offset": zod.number().min(getReservationRescheduleSchedulingsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getReservationRescheduleSchedulingsQueryLimitMax).optional(),
  "rescheduleFrom": zod.string().date(),
  "rescheduleUntil": zod.string().date()
})

export const getReservationRescheduleSchedulingsResponseItem = zod.object({
  "id": zod.string(),
  "date": zod.string().date(),
  "consumableFromTime": zod.string().nullish(),
  "consumableToTime": zod.string().nullish(),
  "calendarIntervalMinutes": zod.number().nullish(),
  "durationMinutes": zod.number(),
  "antecedenceHours": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish(),
  "antecedenceHoursReferenceTime": zod.string().nullish(),
  "availableScheduleTimes": zod.array(zod.object({
  "time": zod.string(),
  "minutes": zod.number()
})).nullish(),
  "scheduleAvailabilities": zod.array(zod.object({
  "time": zod.string().nullable(),
  "minutes": zod.number().nullable(),
  "available": zod.boolean()
}))
})
export const getReservationRescheduleSchedulingsResponse = zod.array(getReservationRescheduleSchedulingsResponseItem)


export const getReservationRelocationSeatMapQueryParams = zod.object({
  "include": zod.array(zod.enum(['areas.slots.resource.category', 'areas.slots.resource.category.chargeTypeTariffReference', 'areas.slots.resource.category.chargeTypeTariffReference.type'])).optional()
})

export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryMinCapacityPercentMin = 0;

export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryMinCapacityPercentMax = 100;
export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMinSaleTariffQuantityMin = 0;

export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMinSaleTariffQuantityMax = 100;
export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMaxSaleTariffQuantityMin = 0;

export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMaxSaleTariffQuantityMax = 100;
export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMinSaleTariffQuantityMin = 0;

export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMinSaleTariffQuantityMax = 100;
export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMaxSaleTariffQuantityMin = 0;

export const getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMaxSaleTariffQuantityMax = 100;


export const getReservationRelocationSeatMapResponse = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "areas": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "width": zod.number(),
  "height": zod.number(),
  "backgroundImage": zod.object({
  "id": zod.string().optional(),
  "alternativeText": zod.string().optional(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})).optional()
}).optional(),
  "slots": zod.array(zod.object({
  "id": zod.string(),
  "type": zod.enum(['resource']),
  "shapeType": zod.enum(['rect', 'circle']),
  "rect": zod.object({
  "top": zod.number(),
  "left": zod.number(),
  "angle": zod.number(),
  "width": zod.number(),
  "height": zod.number()
}).optional(),
  "circle": zod.object({
  "top": zod.number(),
  "left": zod.number(),
  "radius": zod.number()
}).optional(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "category": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "completeCapacityChargeType": zod.enum(['fixed_value', 'tariff_percent']).optional(),
  "minCapacityUnitCents": zod.number().nullish(),
  "allowUnderAllocation": zod.boolean(),
  "minCapacityPercent": zod.number().min(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryMinCapacityPercentMin).max(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryMinCapacityPercentMax).nullish(),
  "tariffPercentage": zod.number().nullish(),
  "chargeTypeTariffReferenceId": zod.string().nullish(),
  "chargeTypeTariffReference": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMinSaleQuantityMin).max(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMaxSaleQuantityMin).max(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMinSaleTariffQuantityMin).max(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMaxSaleTariffQuantityMin).max(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMinSaleQuantityMin).max(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMaxSaleQuantityMin).max(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMinSaleTariffQuantityMin).max(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMaxSaleTariffQuantityMin).max(getReservationRelocationSeatMapResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "color": zod.string(),
  "blocked": zod.boolean()
}).nullish(),
  "available": zod.boolean()
}).optional()
}))
}))
})


export const getAppAggregatedSchedulingsQueryOffsetMin = 0;
export const getAppAggregatedSchedulingsQueryLimitMax = 100;
export const getAppAggregatedSchedulingsQueryPreviewCountMax = 10;


export const getAppAggregatedSchedulingsQueryParams = zod.object({
  "offset": zod.number().min(getAppAggregatedSchedulingsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppAggregatedSchedulingsQueryLimitMax).optional(),
  "since": zod.string().date().nullable(),
  "until": zod.string().date().nullable(),
  "productIds": zod.array(zod.string()).optional(),
  "include": zod.array(zod.enum(['schedulings.tariffGroup', 'schedulings.tariffGroup.priceInfo', 'schedulings.tariffGroup.seatMap', 'schedulings.finalTariffGroup', 'schedulings.finalTariffGroup.priceInfo', 'schedulings.finalTariffGroup.seatMap', 'schedulings.product', 'schedulings.product.defaultTariffGroupPriceInfo', 'schedulings.aggregatedRecurrenceCount'])).optional(),
  "previewCount": zod.number().min(1).max(getAppAggregatedSchedulingsQueryPreviewCountMax).optional(),
  "sortBy": zod.enum(['recent', 'startTimeAndName']).optional()
})

export const getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemProductMinSaleTariffQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemProductMinSaleTariffQuantityMax = 100;
export const getAppAggregatedSchedulingsResponseSchedulingsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppAggregatedSchedulingsResponseSchedulingsItemProductMaxSaleTariffQuantityMax = 100;


export const getAppAggregatedSchedulingsResponseItem = zod.object({
  "date": zod.string().date(),
  "total": zod.number(),
  "schedulings": zod.array(zod.object({
  "id": zod.string(),
  "beginDate": zod.string().date(),
  "consumableFromTime": zod.string().nullish(),
  "consumableToTime": zod.string().nullish(),
  "calendarIntervalMinutes": zod.number().nullish(),
  "antecedenceHours": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish(),
  "antecedenceHoursReferenceTime": zod.string().nullish(),
  "durationMinutes": zod.number(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "tariffGroupId": zod.string().nullable(),
  "tariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductMinSaleTariffQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductMaxSaleTariffQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "finalTariffGroupId": zod.string().nullish(),
  "finalTariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductMinSaleTariffQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductMaxSaleTariffQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemFinalTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemProductTariffSettingsMinSaleQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemProductMinSaleTariffQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAggregatedSchedulingsResponseSchedulingsItemProductMaxSaleTariffQuantityMin).max(getAppAggregatedSchedulingsResponseSchedulingsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceModifierId": zod.string().nullable(),
  "aggregatedRecurrenceCount": zod.object({
  "total": zod.number(),
  "previous": zod.number(),
  "next": zod.number(),
  "futurePrevious": zod.number()
}).optional(),
  "appliesAt": zod.string().date(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}))
})
export const getAppAggregatedSchedulingsResponse = zod.array(getAppAggregatedSchedulingsResponseItem)


export const getAppSchedulingsQueryOffsetMin = 0;
export const getAppSchedulingsQueryLimitMax = 100;


export const getAppSchedulingsQueryParams = zod.object({
  "offset": zod.number().min(getAppSchedulingsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppSchedulingsQueryLimitMax).optional(),
  "since": zod.string().date().nullable(),
  "until": zod.string().date().nullable(),
  "productIds": zod.array(zod.string()).optional(),
  "include": zod.array(zod.enum(['tariffGroup', 'tariffGroup.priceInfo', 'finalTariffGroup', 'finalTariffGroup.priceInfo', 'product', 'product.defaultTariffGroupPriceInfo', 'aggregatedRecurrenceCount'])).optional(),
  "sortBy": zod.enum(['recent', 'startTimeAndName']).optional()
})

export const getAppSchedulingsResponseTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppSchedulingsResponseTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppSchedulingsResponseTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppSchedulingsResponseTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppSchedulingsResponseTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getAppSchedulingsResponseTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getAppSchedulingsResponseTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getAppSchedulingsResponseTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getAppSchedulingsResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppSchedulingsResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppSchedulingsResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppSchedulingsResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppSchedulingsResponseFinalTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getAppSchedulingsResponseFinalTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getAppSchedulingsResponseFinalTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getAppSchedulingsResponseFinalTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getAppSchedulingsResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppSchedulingsResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppSchedulingsResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppSchedulingsResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppSchedulingsResponseProductMinSaleTariffQuantityMin = 0;

export const getAppSchedulingsResponseProductMinSaleTariffQuantityMax = 100;
export const getAppSchedulingsResponseProductMaxSaleTariffQuantityMin = 0;

export const getAppSchedulingsResponseProductMaxSaleTariffQuantityMax = 100;


export const getAppSchedulingsResponseItem = zod.object({
  "id": zod.string(),
  "beginDate": zod.string().date(),
  "consumableFromTime": zod.string().nullish(),
  "consumableToTime": zod.string().nullish(),
  "calendarIntervalMinutes": zod.number().nullish(),
  "antecedenceHours": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish(),
  "antecedenceHoursReferenceTime": zod.string().nullish(),
  "durationMinutes": zod.number(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "tariffGroupId": zod.string().nullable(),
  "tariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppSchedulingsResponseTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getAppSchedulingsResponseTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppSchedulingsResponseTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getAppSchedulingsResponseTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppSchedulingsResponseTariffGroupProductMinSaleTariffQuantityMin).max(getAppSchedulingsResponseTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppSchedulingsResponseTariffGroupProductMaxSaleTariffQuantityMin).max(getAppSchedulingsResponseTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "finalTariffGroupId": zod.string().nullish(),
  "finalTariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppSchedulingsResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getAppSchedulingsResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppSchedulingsResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getAppSchedulingsResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppSchedulingsResponseFinalTariffGroupProductMinSaleTariffQuantityMin).max(getAppSchedulingsResponseFinalTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppSchedulingsResponseFinalTariffGroupProductMaxSaleTariffQuantityMin).max(getAppSchedulingsResponseFinalTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppSchedulingsResponseProductTariffSettingsMinSaleQuantityMin).max(getAppSchedulingsResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppSchedulingsResponseProductTariffSettingsMaxSaleQuantityMin).max(getAppSchedulingsResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppSchedulingsResponseProductMinSaleTariffQuantityMin).max(getAppSchedulingsResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppSchedulingsResponseProductMaxSaleTariffQuantityMin).max(getAppSchedulingsResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceModifierId": zod.string().nullable(),
  "aggregatedRecurrenceCount": zod.object({
  "total": zod.number(),
  "previous": zod.number(),
  "next": zod.number(),
  "futurePrevious": zod.number()
}).optional(),
  "appliesAt": zod.string().date(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppSchedulingsResponse = zod.array(getAppSchedulingsResponseItem)


export const getAppAggregatedAvailabilitiesQueryOffsetMin = 0;
export const getAppAggregatedAvailabilitiesQueryLimitMax = 100;
export const getAppAggregatedAvailabilitiesQueryPreviewCountMax = 10;


export const getAppAggregatedAvailabilitiesQueryParams = zod.object({
  "offset": zod.number().min(getAppAggregatedAvailabilitiesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppAggregatedAvailabilitiesQueryLimitMax).optional(),
  "since": zod.string().date().nullable(),
  "until": zod.string().date().nullable(),
  "types": zod.array(zod.enum(['per_app', 'per_products', 'per_tariffs', 'per_product_tariff_types', 'per_additionals'])).optional(),
  "affectedProductIds": zod.array(zod.string()).optional(),
  "include": zod.array(zod.enum(['availabilities.products', 'availabilities.products.defaultTariffGroupPriceInfo', 'availabilities.additionals', 'availabilities.additionals.product', 'availabilities.productTariffTypes', 'availabilities.productTariffTypes.product', 'availabilities.productTariffTypes.tariffType', 'availabilities.tariffs', 'availabilities.tariffs.type', 'availabilities.aggregatedRecurrenceCount'])).optional(),
  "previewCount": zod.number().min(1).max(getAppAggregatedAvailabilitiesQueryPreviewCountMax).optional(),
  "sortBy": zod.enum(['recent', 'startTime']).optional()
})

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemTariffSettingsMinSaleQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemTariffSettingsMinSaleQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemMinSaleTariffQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemMinSaleTariffQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemMaxSaleTariffQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemMaxSaleTariffQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductMinSaleTariffQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductMinSaleTariffQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductMaxSaleTariffQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductMinSaleTariffQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductMinSaleTariffQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductMaxSaleTariffQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductMaxSaleTariffQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductMinSaleTariffQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductMinSaleTariffQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductMaxSaleTariffQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductMinSaleTariffQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductMinSaleTariffQuantityMax = 100;
export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductMaxSaleTariffQuantityMin = 0;

export const getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductMaxSaleTariffQuantityMax = 100;


export const getAppAggregatedAvailabilitiesResponseItem = zod.object({
  "date": zod.string().date(),
  "total": zod.number(),
  "availabilities": zod.array(zod.object({
  "id": zod.string(),
  "type": zod.enum(['per_app', 'per_products', 'per_tariffs', 'per_product_tariff_types', 'per_additionals']),
  "quantity": zod.number(),
  "beginDate": zod.string().date(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "aggregatedRecurrenceCount": zod.object({
  "total": zod.number(),
  "previous": zod.number(),
  "next": zod.number(),
  "futurePrevious": zod.number()
}).optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemTariffSettingsMinSaleQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemTariffSettingsMaxSaleQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemMinSaleTariffQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemMaxSaleTariffQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductsItemMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})).nullish(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductTariffSettingsMinSaleQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductMinSaleTariffQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductMaxSaleTariffQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductTariffSettingsMinSaleQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductMinSaleTariffQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductMaxSaleTariffQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemTariffsItemGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})).nullish(),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductTariffSettingsMinSaleQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductMinSaleTariffQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductMaxSaleTariffQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemAdditionalsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})).nullish(),
  "productTariffTypes": zod.array(zod.object({
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductMinSaleTariffQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductMaxSaleTariffQuantityMin).max(getAppAggregatedAvailabilitiesResponseAvailabilitiesItemProductTariffTypesItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "tariffTypeId": zod.string(),
  "tariffType": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional()
})).nullish(),
  "occupation": zod.object({
  "occupationRate": zod.number().nullish(),
  "occupiedAmount": zod.number()
}).nullable(),
  "appliesAt": zod.string().date(),
  "fromTime": zod.string(),
  "toTime": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}))
})
export const getAppAggregatedAvailabilitiesResponse = zod.array(getAppAggregatedAvailabilitiesResponseItem)


export const createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin = 0;

export const createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax = 100;
export const createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductMinSaleTariffQuantityMin = 0;

export const createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductMinSaleTariffQuantityMax = 100;
export const createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductMaxSaleTariffQuantityMin = 0;

export const createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductMaxSaleTariffQuantityMax = 100;


export const createAppAvailabilityBatchBody = zod.object({
  "availabilities": zod.array(zod.object({
  "type": zod.enum(['per_app', 'per_products', 'per_tariffs', 'per_product_tariff_types', 'per_additionals']),
  "beginDate": zod.string().date(),
  "endDate": zod.string().date().nullish(),
  "fromTime": zod.string(),
  "toTime": zod.string(),
  "quantity": zod.number(),
  "productIds": zod.array(zod.string()).nullish(),
  "tariffIds": zod.array(zod.string()).nullish(),
  "additionalIds": zod.array(zod.string()).nullish(),
  "productTariffTypes": zod.array(zod.object({
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin).max(createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin).max(createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductMinSaleTariffQuantityMin).max(createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductMaxSaleTariffQuantityMin).max(createAppAvailabilityBatchBodyAvailabilitiesItemProductTariffTypesItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "tariffTypeId": zod.string(),
  "tariffType": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional()
})).nullish(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish()
}))
})


export const getAppAvailabilitiesQueryOffsetMin = 0;
export const getAppAvailabilitiesQueryLimitMax = 100;


export const getAppAvailabilitiesQueryParams = zod.object({
  "offset": zod.number().min(getAppAvailabilitiesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppAvailabilitiesQueryLimitMax).optional(),
  "since": zod.string().datetime(),
  "until": zod.string().datetime(),
  "types": zod.array(zod.enum(['per_app', 'per_products', 'per_tariffs', 'per_product_tariff_types', 'per_additionals'])).optional(),
  "affectedProductIds": zod.array(zod.string()).optional(),
  "include": zod.array(zod.enum(['products', 'products.defaultTariffGroupPriceInfo', 'additionals', 'additionals.product', 'productTariffTypes', 'productTariffTypes.product', 'productTariffTypes.tariffType', 'tariffs', 'tariffs.type', 'tariffs.group', 'tariffs.product', 'aggregatedRecurrenceCount'])).optional(),
  "sortBy": zod.enum(['recent', 'startTime']).optional()
})

export const getAppAvailabilitiesResponseProductsItemTariffSettingsMinSaleQuantityMin = 0;

export const getAppAvailabilitiesResponseProductsItemTariffSettingsMinSaleQuantityMax = 100;
export const getAppAvailabilitiesResponseProductsItemTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAvailabilitiesResponseProductsItemTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAvailabilitiesResponseProductsItemMinSaleTariffQuantityMin = 0;

export const getAppAvailabilitiesResponseProductsItemMinSaleTariffQuantityMax = 100;
export const getAppAvailabilitiesResponseProductsItemMaxSaleTariffQuantityMin = 0;

export const getAppAvailabilitiesResponseProductsItemMaxSaleTariffQuantityMax = 100;
export const getAppAvailabilitiesResponseTariffsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAvailabilitiesResponseTariffsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAvailabilitiesResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAvailabilitiesResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAvailabilitiesResponseTariffsItemProductMinSaleTariffQuantityMin = 0;

export const getAppAvailabilitiesResponseTariffsItemProductMinSaleTariffQuantityMax = 100;
export const getAppAvailabilitiesResponseTariffsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppAvailabilitiesResponseTariffsItemProductMaxSaleTariffQuantityMax = 100;
export const getAppAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAvailabilitiesResponseTariffsItemGroupProductMinSaleTariffQuantityMin = 0;

export const getAppAvailabilitiesResponseTariffsItemGroupProductMinSaleTariffQuantityMax = 100;
export const getAppAvailabilitiesResponseTariffsItemGroupProductMaxSaleTariffQuantityMin = 0;

export const getAppAvailabilitiesResponseTariffsItemGroupProductMaxSaleTariffQuantityMax = 100;
export const getAppAvailabilitiesResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAvailabilitiesResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAvailabilitiesResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAvailabilitiesResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAvailabilitiesResponseAdditionalsItemProductMinSaleTariffQuantityMin = 0;

export const getAppAvailabilitiesResponseAdditionalsItemProductMinSaleTariffQuantityMax = 100;
export const getAppAvailabilitiesResponseAdditionalsItemProductMaxSaleTariffQuantityMin = 0;

export const getAppAvailabilitiesResponseAdditionalsItemProductMaxSaleTariffQuantityMax = 100;
export const getAppAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAppAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAppAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAppAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAppAvailabilitiesResponseProductTariffTypesItemProductMinSaleTariffQuantityMin = 0;

export const getAppAvailabilitiesResponseProductTariffTypesItemProductMinSaleTariffQuantityMax = 100;
export const getAppAvailabilitiesResponseProductTariffTypesItemProductMaxSaleTariffQuantityMin = 0;

export const getAppAvailabilitiesResponseProductTariffTypesItemProductMaxSaleTariffQuantityMax = 100;


export const getAppAvailabilitiesResponseItem = zod.object({
  "id": zod.string(),
  "type": zod.enum(['per_app', 'per_products', 'per_tariffs', 'per_product_tariff_types', 'per_additionals']),
  "quantity": zod.number(),
  "beginDate": zod.string().date(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "aggregatedRecurrenceCount": zod.object({
  "total": zod.number(),
  "previous": zod.number(),
  "next": zod.number(),
  "futurePrevious": zod.number()
}).optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAvailabilitiesResponseProductsItemTariffSettingsMinSaleQuantityMin).max(getAppAvailabilitiesResponseProductsItemTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAvailabilitiesResponseProductsItemTariffSettingsMaxSaleQuantityMin).max(getAppAvailabilitiesResponseProductsItemTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAvailabilitiesResponseProductsItemMinSaleTariffQuantityMin).max(getAppAvailabilitiesResponseProductsItemMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAvailabilitiesResponseProductsItemMaxSaleTariffQuantityMin).max(getAppAvailabilitiesResponseProductsItemMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})).nullish(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAvailabilitiesResponseTariffsItemProductTariffSettingsMinSaleQuantityMin).max(getAppAvailabilitiesResponseTariffsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAvailabilitiesResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppAvailabilitiesResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAvailabilitiesResponseTariffsItemProductMinSaleTariffQuantityMin).max(getAppAvailabilitiesResponseTariffsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAvailabilitiesResponseTariffsItemProductMaxSaleTariffQuantityMin).max(getAppAvailabilitiesResponseTariffsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin).max(getAppAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin).max(getAppAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAvailabilitiesResponseTariffsItemGroupProductMinSaleTariffQuantityMin).max(getAppAvailabilitiesResponseTariffsItemGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAvailabilitiesResponseTariffsItemGroupProductMaxSaleTariffQuantityMin).max(getAppAvailabilitiesResponseTariffsItemGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})).nullish(),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAvailabilitiesResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMin).max(getAppAvailabilitiesResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAvailabilitiesResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMin).max(getAppAvailabilitiesResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAvailabilitiesResponseAdditionalsItemProductMinSaleTariffQuantityMin).max(getAppAvailabilitiesResponseAdditionalsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAvailabilitiesResponseAdditionalsItemProductMaxSaleTariffQuantityMin).max(getAppAvailabilitiesResponseAdditionalsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})).nullish(),
  "productTariffTypes": zod.array(zod.object({
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAppAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin).max(getAppAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAppAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin).max(getAppAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAppAvailabilitiesResponseProductTariffTypesItemProductMinSaleTariffQuantityMin).max(getAppAvailabilitiesResponseProductTariffTypesItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAppAvailabilitiesResponseProductTariffTypesItemProductMaxSaleTariffQuantityMin).max(getAppAvailabilitiesResponseProductTariffTypesItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "tariffTypeId": zod.string(),
  "tariffType": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional()
})).nullish(),
  "occupation": zod.object({
  "occupationRate": zod.number().nullish(),
  "occupiedAmount": zod.number()
}).nullable(),
  "appliesAt": zod.string().date(),
  "fromTime": zod.string(),
  "toTime": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppAvailabilitiesResponse = zod.array(getAppAvailabilitiesResponseItem)


export const createAppAvailabilityBodyProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin = 0;

export const createAppAvailabilityBodyProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax = 100;
export const createAppAvailabilityBodyProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const createAppAvailabilityBodyProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const createAppAvailabilityBodyProductTariffTypesItemProductMinSaleTariffQuantityMin = 0;

export const createAppAvailabilityBodyProductTariffTypesItemProductMinSaleTariffQuantityMax = 100;
export const createAppAvailabilityBodyProductTariffTypesItemProductMaxSaleTariffQuantityMin = 0;

export const createAppAvailabilityBodyProductTariffTypesItemProductMaxSaleTariffQuantityMax = 100;


export const createAppAvailabilityBody = zod.object({
  "type": zod.enum(['per_app', 'per_products', 'per_tariffs', 'per_product_tariff_types', 'per_additionals']),
  "beginDate": zod.string().date(),
  "endDate": zod.string().date().nullish(),
  "fromTime": zod.string(),
  "toTime": zod.string(),
  "quantity": zod.number(),
  "productIds": zod.array(zod.string()).nullish(),
  "tariffIds": zod.array(zod.string()).nullish(),
  "additionalIds": zod.array(zod.string()).nullish(),
  "productTariffTypes": zod.array(zod.object({
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(createAppAvailabilityBodyProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin).max(createAppAvailabilityBodyProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(createAppAvailabilityBodyProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin).max(createAppAvailabilityBodyProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(createAppAvailabilityBodyProductTariffTypesItemProductMinSaleTariffQuantityMin).max(createAppAvailabilityBodyProductTariffTypesItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(createAppAvailabilityBodyProductTariffTypesItemProductMaxSaleTariffQuantityMin).max(createAppAvailabilityBodyProductTariffTypesItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "tariffTypeId": zod.string(),
  "tariffType": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional()
})).nullish(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish()
})


export const getAvailabilityQueryParams = zod.object({
  "include": zod.array(zod.enum(['products', 'products.defaultTariffGroupPriceInfo', 'additionals', 'additionals.product', 'productTariffTypes', 'productTariffTypes.product', 'productTariffTypes.tariffType', 'tariffs', 'tariffs.type', 'tariffs.group', 'tariffs.product', 'aggregatedRecurrenceCount'])).optional()
})

export const getAvailabilityResponseProductsItemTariffSettingsMinSaleQuantityMin = 0;

export const getAvailabilityResponseProductsItemTariffSettingsMinSaleQuantityMax = 100;
export const getAvailabilityResponseProductsItemTariffSettingsMaxSaleQuantityMin = 0;

export const getAvailabilityResponseProductsItemTariffSettingsMaxSaleQuantityMax = 100;
export const getAvailabilityResponseProductsItemMinSaleTariffQuantityMin = 0;

export const getAvailabilityResponseProductsItemMinSaleTariffQuantityMax = 100;
export const getAvailabilityResponseProductsItemMaxSaleTariffQuantityMin = 0;

export const getAvailabilityResponseProductsItemMaxSaleTariffQuantityMax = 100;
export const getAvailabilityResponseTariffsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAvailabilityResponseTariffsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAvailabilityResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAvailabilityResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAvailabilityResponseTariffsItemProductMinSaleTariffQuantityMin = 0;

export const getAvailabilityResponseTariffsItemProductMinSaleTariffQuantityMax = 100;
export const getAvailabilityResponseTariffsItemProductMaxSaleTariffQuantityMin = 0;

export const getAvailabilityResponseTariffsItemProductMaxSaleTariffQuantityMax = 100;
export const getAvailabilityResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getAvailabilityResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getAvailabilityResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAvailabilityResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAvailabilityResponseTariffsItemGroupProductMinSaleTariffQuantityMin = 0;

export const getAvailabilityResponseTariffsItemGroupProductMinSaleTariffQuantityMax = 100;
export const getAvailabilityResponseTariffsItemGroupProductMaxSaleTariffQuantityMin = 0;

export const getAvailabilityResponseTariffsItemGroupProductMaxSaleTariffQuantityMax = 100;
export const getAvailabilityResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAvailabilityResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAvailabilityResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAvailabilityResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAvailabilityResponseAdditionalsItemProductMinSaleTariffQuantityMin = 0;

export const getAvailabilityResponseAdditionalsItemProductMinSaleTariffQuantityMax = 100;
export const getAvailabilityResponseAdditionalsItemProductMaxSaleTariffQuantityMin = 0;

export const getAvailabilityResponseAdditionalsItemProductMaxSaleTariffQuantityMax = 100;
export const getAvailabilityResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getAvailabilityResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getAvailabilityResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getAvailabilityResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getAvailabilityResponseProductTariffTypesItemProductMinSaleTariffQuantityMin = 0;

export const getAvailabilityResponseProductTariffTypesItemProductMinSaleTariffQuantityMax = 100;
export const getAvailabilityResponseProductTariffTypesItemProductMaxSaleTariffQuantityMin = 0;

export const getAvailabilityResponseProductTariffTypesItemProductMaxSaleTariffQuantityMax = 100;


export const getAvailabilityResponse = zod.object({
  "id": zod.string(),
  "type": zod.enum(['per_app', 'per_products', 'per_tariffs', 'per_product_tariff_types', 'per_additionals']),
  "quantity": zod.number(),
  "beginDate": zod.string().date(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "aggregatedRecurrenceCount": zod.object({
  "total": zod.number(),
  "previous": zod.number(),
  "next": zod.number(),
  "futurePrevious": zod.number()
}).optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAvailabilityResponseProductsItemTariffSettingsMinSaleQuantityMin).max(getAvailabilityResponseProductsItemTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAvailabilityResponseProductsItemTariffSettingsMaxSaleQuantityMin).max(getAvailabilityResponseProductsItemTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAvailabilityResponseProductsItemMinSaleTariffQuantityMin).max(getAvailabilityResponseProductsItemMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAvailabilityResponseProductsItemMaxSaleTariffQuantityMin).max(getAvailabilityResponseProductsItemMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})).nullish(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAvailabilityResponseTariffsItemProductTariffSettingsMinSaleQuantityMin).max(getAvailabilityResponseTariffsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAvailabilityResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin).max(getAvailabilityResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAvailabilityResponseTariffsItemProductMinSaleTariffQuantityMin).max(getAvailabilityResponseTariffsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAvailabilityResponseTariffsItemProductMaxSaleTariffQuantityMin).max(getAvailabilityResponseTariffsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAvailabilityResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin).max(getAvailabilityResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAvailabilityResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin).max(getAvailabilityResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAvailabilityResponseTariffsItemGroupProductMinSaleTariffQuantityMin).max(getAvailabilityResponseTariffsItemGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAvailabilityResponseTariffsItemGroupProductMaxSaleTariffQuantityMin).max(getAvailabilityResponseTariffsItemGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})).nullish(),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAvailabilityResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMin).max(getAvailabilityResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAvailabilityResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMin).max(getAvailabilityResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAvailabilityResponseAdditionalsItemProductMinSaleTariffQuantityMin).max(getAvailabilityResponseAdditionalsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAvailabilityResponseAdditionalsItemProductMaxSaleTariffQuantityMin).max(getAvailabilityResponseAdditionalsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})).nullish(),
  "productTariffTypes": zod.array(zod.object({
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getAvailabilityResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin).max(getAvailabilityResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getAvailabilityResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin).max(getAvailabilityResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getAvailabilityResponseProductTariffTypesItemProductMinSaleTariffQuantityMin).max(getAvailabilityResponseProductTariffTypesItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getAvailabilityResponseProductTariffTypesItemProductMaxSaleTariffQuantityMin).max(getAvailabilityResponseProductTariffTypesItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "tariffTypeId": zod.string(),
  "tariffType": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional()
})).nullish(),
  "occupation": zod.object({
  "occupationRate": zod.number().nullish(),
  "occupiedAmount": zod.number()
}).nullable(),
  "appliesAt": zod.string().date(),
  "fromTime": zod.string(),
  "toTime": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const updateAvailabilityBody = zod.object({
  "quantity": zod.number().optional(),
  "rule": zod.enum(['all', 'this_and_future', 'this_and_following', 'this_only', 'this_and_following_until']),
  "ruleUntilDate": zod.string().date().nullish()
})

export const updateAvailabilityResponseProductsItemTariffSettingsMinSaleQuantityMin = 0;

export const updateAvailabilityResponseProductsItemTariffSettingsMinSaleQuantityMax = 100;
export const updateAvailabilityResponseProductsItemTariffSettingsMaxSaleQuantityMin = 0;

export const updateAvailabilityResponseProductsItemTariffSettingsMaxSaleQuantityMax = 100;
export const updateAvailabilityResponseProductsItemMinSaleTariffQuantityMin = 0;

export const updateAvailabilityResponseProductsItemMinSaleTariffQuantityMax = 100;
export const updateAvailabilityResponseProductsItemMaxSaleTariffQuantityMin = 0;

export const updateAvailabilityResponseProductsItemMaxSaleTariffQuantityMax = 100;
export const updateAvailabilityResponseTariffsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const updateAvailabilityResponseTariffsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const updateAvailabilityResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const updateAvailabilityResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const updateAvailabilityResponseTariffsItemProductMinSaleTariffQuantityMin = 0;

export const updateAvailabilityResponseTariffsItemProductMinSaleTariffQuantityMax = 100;
export const updateAvailabilityResponseTariffsItemProductMaxSaleTariffQuantityMin = 0;

export const updateAvailabilityResponseTariffsItemProductMaxSaleTariffQuantityMax = 100;
export const updateAvailabilityResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const updateAvailabilityResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const updateAvailabilityResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const updateAvailabilityResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const updateAvailabilityResponseTariffsItemGroupProductMinSaleTariffQuantityMin = 0;

export const updateAvailabilityResponseTariffsItemGroupProductMinSaleTariffQuantityMax = 100;
export const updateAvailabilityResponseTariffsItemGroupProductMaxSaleTariffQuantityMin = 0;

export const updateAvailabilityResponseTariffsItemGroupProductMaxSaleTariffQuantityMax = 100;
export const updateAvailabilityResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const updateAvailabilityResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const updateAvailabilityResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const updateAvailabilityResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const updateAvailabilityResponseAdditionalsItemProductMinSaleTariffQuantityMin = 0;

export const updateAvailabilityResponseAdditionalsItemProductMinSaleTariffQuantityMax = 100;
export const updateAvailabilityResponseAdditionalsItemProductMaxSaleTariffQuantityMin = 0;

export const updateAvailabilityResponseAdditionalsItemProductMaxSaleTariffQuantityMax = 100;
export const updateAvailabilityResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin = 0;

export const updateAvailabilityResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax = 100;
export const updateAvailabilityResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const updateAvailabilityResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const updateAvailabilityResponseProductTariffTypesItemProductMinSaleTariffQuantityMin = 0;

export const updateAvailabilityResponseProductTariffTypesItemProductMinSaleTariffQuantityMax = 100;
export const updateAvailabilityResponseProductTariffTypesItemProductMaxSaleTariffQuantityMin = 0;

export const updateAvailabilityResponseProductTariffTypesItemProductMaxSaleTariffQuantityMax = 100;


export const updateAvailabilityResponse = zod.object({
  "id": zod.string(),
  "type": zod.enum(['per_app', 'per_products', 'per_tariffs', 'per_product_tariff_types', 'per_additionals']),
  "quantity": zod.number(),
  "beginDate": zod.string().date(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "aggregatedRecurrenceCount": zod.object({
  "total": zod.number(),
  "previous": zod.number(),
  "next": zod.number(),
  "futurePrevious": zod.number()
}).optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(updateAvailabilityResponseProductsItemTariffSettingsMinSaleQuantityMin).max(updateAvailabilityResponseProductsItemTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(updateAvailabilityResponseProductsItemTariffSettingsMaxSaleQuantityMin).max(updateAvailabilityResponseProductsItemTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(updateAvailabilityResponseProductsItemMinSaleTariffQuantityMin).max(updateAvailabilityResponseProductsItemMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(updateAvailabilityResponseProductsItemMaxSaleTariffQuantityMin).max(updateAvailabilityResponseProductsItemMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})).nullish(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(updateAvailabilityResponseTariffsItemProductTariffSettingsMinSaleQuantityMin).max(updateAvailabilityResponseTariffsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(updateAvailabilityResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin).max(updateAvailabilityResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(updateAvailabilityResponseTariffsItemProductMinSaleTariffQuantityMin).max(updateAvailabilityResponseTariffsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(updateAvailabilityResponseTariffsItemProductMaxSaleTariffQuantityMin).max(updateAvailabilityResponseTariffsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(updateAvailabilityResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin).max(updateAvailabilityResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(updateAvailabilityResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin).max(updateAvailabilityResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(updateAvailabilityResponseTariffsItemGroupProductMinSaleTariffQuantityMin).max(updateAvailabilityResponseTariffsItemGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(updateAvailabilityResponseTariffsItemGroupProductMaxSaleTariffQuantityMin).max(updateAvailabilityResponseTariffsItemGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})).nullish(),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(updateAvailabilityResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMin).max(updateAvailabilityResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(updateAvailabilityResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMin).max(updateAvailabilityResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(updateAvailabilityResponseAdditionalsItemProductMinSaleTariffQuantityMin).max(updateAvailabilityResponseAdditionalsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(updateAvailabilityResponseAdditionalsItemProductMaxSaleTariffQuantityMin).max(updateAvailabilityResponseAdditionalsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})).nullish(),
  "productTariffTypes": zod.array(zod.object({
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(updateAvailabilityResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin).max(updateAvailabilityResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(updateAvailabilityResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin).max(updateAvailabilityResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(updateAvailabilityResponseProductTariffTypesItemProductMinSaleTariffQuantityMin).max(updateAvailabilityResponseProductTariffTypesItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(updateAvailabilityResponseProductTariffTypesItemProductMaxSaleTariffQuantityMin).max(updateAvailabilityResponseProductTariffTypesItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "tariffTypeId": zod.string(),
  "tariffType": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional()
})).nullish(),
  "occupation": zod.object({
  "occupationRate": zod.number().nullish(),
  "occupiedAmount": zod.number()
}).nullable(),
  "appliesAt": zod.string().date(),
  "fromTime": zod.string(),
  "toTime": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const deleteAvailabilityBody = zod.object({
  "rule": zod.enum(['all', 'this_and_future', 'this_and_following', 'this_only', 'this_and_following_until']),
  "ruleUntilDate": zod.string().date().nullish()
})


export const getSchedulingQueryParams = zod.object({
  "include": zod.array(zod.enum(['tariffGroup', 'tariffGroup.priceInfo', 'finalTariffGroup', 'finalTariffGroup.priceInfo', 'product', 'product.defaultTariffGroupPriceInfo', 'aggregatedRecurrenceCount'])).optional()
})

export const getSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSchedulingResponseTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getSchedulingResponseTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMin = 0;

export const getSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMax = 100;
export const getSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const getSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const getSchedulingResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getSchedulingResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getSchedulingResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSchedulingResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSchedulingResponseProductMinSaleTariffQuantityMin = 0;

export const getSchedulingResponseProductMinSaleTariffQuantityMax = 100;
export const getSchedulingResponseProductMaxSaleTariffQuantityMin = 0;

export const getSchedulingResponseProductMaxSaleTariffQuantityMax = 100;


export const getSchedulingResponse = zod.object({
  "id": zod.string(),
  "beginDate": zod.string().date(),
  "consumableFromTime": zod.string().nullish(),
  "consumableToTime": zod.string().nullish(),
  "calendarIntervalMinutes": zod.number().nullish(),
  "antecedenceHours": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish(),
  "antecedenceHoursReferenceTime": zod.string().nullish(),
  "durationMinutes": zod.number(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "tariffGroupId": zod.string().nullable(),
  "tariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSchedulingResponseTariffGroupProductMinSaleTariffQuantityMin).max(getSchedulingResponseTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMin).max(getSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "finalTariffGroupId": zod.string().nullish(),
  "finalTariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMin).max(getSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(getSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMin).max(getSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMin).max(getSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSchedulingResponseProductTariffSettingsMinSaleQuantityMin).max(getSchedulingResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSchedulingResponseProductTariffSettingsMaxSaleQuantityMin).max(getSchedulingResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSchedulingResponseProductMinSaleTariffQuantityMin).max(getSchedulingResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSchedulingResponseProductMaxSaleTariffQuantityMin).max(getSchedulingResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceModifierId": zod.string().nullable(),
  "aggregatedRecurrenceCount": zod.object({
  "total": zod.number(),
  "previous": zod.number(),
  "next": zod.number(),
  "futurePrevious": zod.number()
}).optional(),
  "appliesAt": zod.string().date(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const updateSchedulingBody = zod.object({
  "beginDate": zod.string().date().optional(),
  "appliesAt": zod.string().date().optional(),
  "tariffGroupId": zod.string().nullish(),
  "priceModifierId": zod.string().nullish(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "consumableFromTime": zod.string().nullish(),
  "consumableToTime": zod.string().nullish(),
  "durationMinutes": zod.number().optional(),
  "calendarIntervalMinutes": zod.number().nullish(),
  "antecedenceHours": zod.number().nullish(),
  "antecedenceMinutes": zod.number().optional(),
  "antecedenceHoursReferenceTime": zod.string().nullish(),
  "rule": zod.enum(['all', 'this_and_future', 'this_and_following', 'this_only', 'this_and_following_until']),
  "ruleUntilDate": zod.string().date().nullish(),
  "overrideConflicts": zod.boolean().optional()
})

export const updateSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const updateSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const updateSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const updateSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const updateSchedulingResponseTariffGroupProductMinSaleTariffQuantityMin = 0;

export const updateSchedulingResponseTariffGroupProductMinSaleTariffQuantityMax = 100;
export const updateSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const updateSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const updateSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const updateSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const updateSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const updateSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const updateSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMin = 0;

export const updateSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMax = 100;
export const updateSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMin = 0;

export const updateSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMax = 100;
export const updateSchedulingResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const updateSchedulingResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const updateSchedulingResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const updateSchedulingResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const updateSchedulingResponseProductMinSaleTariffQuantityMin = 0;

export const updateSchedulingResponseProductMinSaleTariffQuantityMax = 100;
export const updateSchedulingResponseProductMaxSaleTariffQuantityMin = 0;

export const updateSchedulingResponseProductMaxSaleTariffQuantityMax = 100;


export const updateSchedulingResponse = zod.object({
  "id": zod.string(),
  "beginDate": zod.string().date(),
  "consumableFromTime": zod.string().nullish(),
  "consumableToTime": zod.string().nullish(),
  "calendarIntervalMinutes": zod.number().nullish(),
  "antecedenceHours": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish(),
  "antecedenceHoursReferenceTime": zod.string().nullish(),
  "durationMinutes": zod.number(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "tariffGroupId": zod.string().nullable(),
  "tariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(updateSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMin).max(updateSchedulingResponseTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(updateSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(updateSchedulingResponseTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(updateSchedulingResponseTariffGroupProductMinSaleTariffQuantityMin).max(updateSchedulingResponseTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(updateSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMin).max(updateSchedulingResponseTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "finalTariffGroupId": zod.string().nullish(),
  "finalTariffGroup": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(updateSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMin).max(updateSchedulingResponseFinalTariffGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(updateSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMin).max(updateSchedulingResponseFinalTariffGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(updateSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMin).max(updateSchedulingResponseFinalTariffGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(updateSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMin).max(updateSchedulingResponseFinalTariffGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(updateSchedulingResponseProductTariffSettingsMinSaleQuantityMin).max(updateSchedulingResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(updateSchedulingResponseProductTariffSettingsMaxSaleQuantityMin).max(updateSchedulingResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(updateSchedulingResponseProductMinSaleTariffQuantityMin).max(updateSchedulingResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(updateSchedulingResponseProductMaxSaleTariffQuantityMin).max(updateSchedulingResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceModifierId": zod.string().nullable(),
  "aggregatedRecurrenceCount": zod.object({
  "total": zod.number(),
  "previous": zod.number(),
  "next": zod.number(),
  "futurePrevious": zod.number()
}).optional(),
  "appliesAt": zod.string().date(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const deleteSchedulingBody = zod.object({
  "rule": zod.enum(['all', 'this_and_future', 'this_and_following', 'this_only', 'this_and_following_until']),
  "ruleUntilDate": zod.string().date().nullish()
})


export const getSchedulingRelatedAvailabilitiesQueryOffsetMin = 0;
export const getSchedulingRelatedAvailabilitiesQueryLimitMax = 100;


export const getSchedulingRelatedAvailabilitiesQueryParams = zod.object({
  "offset": zod.number().min(getSchedulingRelatedAvailabilitiesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getSchedulingRelatedAvailabilitiesQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['products', 'products.defaultTariffGroupPriceInfo', 'additionals', 'additionals.product', 'productTariffTypes', 'productTariffTypes.product', 'productTariffTypes.tariffType', 'tariffs', 'tariffs.type', 'tariffs.group', 'tariffs.product', 'aggregatedRecurrenceCount'])).optional()
})

export const getSchedulingRelatedAvailabilitiesResponseProductsItemTariffSettingsMinSaleQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseProductsItemTariffSettingsMinSaleQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseProductsItemTariffSettingsMaxSaleQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseProductsItemTariffSettingsMaxSaleQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseProductsItemMinSaleTariffQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseProductsItemMinSaleTariffQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseProductsItemMaxSaleTariffQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseProductsItemMaxSaleTariffQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseTariffsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseTariffsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseTariffsItemProductMinSaleTariffQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseTariffsItemProductMinSaleTariffQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseTariffsItemProductMaxSaleTariffQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseTariffsItemProductMaxSaleTariffQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductMinSaleTariffQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductMinSaleTariffQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductMaxSaleTariffQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductMaxSaleTariffQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductMinSaleTariffQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductMinSaleTariffQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductMaxSaleTariffQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductMaxSaleTariffQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductMinSaleTariffQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductMinSaleTariffQuantityMax = 100;
export const getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductMaxSaleTariffQuantityMin = 0;

export const getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductMaxSaleTariffQuantityMax = 100;


export const getSchedulingRelatedAvailabilitiesResponseItem = zod.object({
  "id": zod.string(),
  "type": zod.enum(['per_app', 'per_products', 'per_tariffs', 'per_product_tariff_types', 'per_additionals']),
  "quantity": zod.number(),
  "beginDate": zod.string().date(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "aggregatedRecurrenceCount": zod.object({
  "total": zod.number(),
  "previous": zod.number(),
  "next": zod.number(),
  "futurePrevious": zod.number()
}).optional(),
  "products": zod.array(zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseProductsItemTariffSettingsMinSaleQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseProductsItemTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseProductsItemTariffSettingsMaxSaleQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseProductsItemTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseProductsItemMinSaleTariffQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseProductsItemMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseProductsItemMaxSaleTariffQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseProductsItemMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})).nullish(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseTariffsItemProductTariffSettingsMinSaleQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseTariffsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseTariffsItemProductMinSaleTariffQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseTariffsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseTariffsItemProductMaxSaleTariffQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseTariffsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductMinSaleTariffQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductMaxSaleTariffQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseTariffsItemGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
})).nullish(),
  "additionals": zod.array(zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductMinSaleTariffQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductMaxSaleTariffQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseAdditionalsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})).nullish(),
  "productTariffTypes": zod.array(zod.object({
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductMinSaleTariffQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductMaxSaleTariffQuantityMin).max(getSchedulingRelatedAvailabilitiesResponseProductTariffTypesItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "tariffTypeId": zod.string(),
  "tariffType": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional()
})).nullish(),
  "occupation": zod.object({
  "occupationRate": zod.number().nullish(),
  "occupiedAmount": zod.number()
}).nullable(),
  "appliesAt": zod.string().date(),
  "fromTime": zod.string(),
  "toTime": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getSchedulingRelatedAvailabilitiesResponse = zod.array(getSchedulingRelatedAvailabilitiesResponseItem)


export const getSchedulingSeatMapOverviewParams = zod.object({
  "schedulingId": zod.string()
})

export const getSchedulingSeatMapOverviewQueryParams = zod.object({
  "include": zod.array(zod.enum(['areas.slots.resource.category', 'areas.slots.resource.category.chargeTypeTariffReference', 'areas.slots.resource.category.chargeTypeTariffReference.type'])).optional(),
  "scheduleTime": zod.string().optional()
})

export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryMinCapacityPercentMin = 0;

export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryMinCapacityPercentMax = 100;
export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMinSaleQuantityMin = 0;

export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMinSaleQuantityMax = 100;
export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMinSaleTariffQuantityMin = 0;

export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMinSaleTariffQuantityMax = 100;
export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMaxSaleTariffQuantityMin = 0;

export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMaxSaleTariffQuantityMax = 100;
export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMinSaleTariffQuantityMin = 0;

export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMinSaleTariffQuantityMax = 100;
export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMaxSaleTariffQuantityMin = 0;

export const getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMaxSaleTariffQuantityMax = 100;


export const getSchedulingSeatMapOverviewResponse = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "areas": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "width": zod.number(),
  "height": zod.number(),
  "backgroundImage": zod.object({
  "id": zod.string().optional(),
  "alternativeText": zod.string().optional(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})).optional()
}).optional(),
  "slots": zod.array(zod.object({
  "id": zod.string(),
  "type": zod.enum(['resource']),
  "shapeType": zod.enum(['rect', 'circle']),
  "rect": zod.object({
  "top": zod.number(),
  "left": zod.number(),
  "angle": zod.number(),
  "width": zod.number(),
  "height": zod.number()
}).optional(),
  "circle": zod.object({
  "top": zod.number(),
  "left": zod.number(),
  "radius": zod.number()
}).optional(),
  "resource": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string().nullable(),
  "capacity": zod.number(),
  "category": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "completeCapacityChargeType": zod.enum(['fixed_value', 'tariff_percent']).optional(),
  "minCapacityUnitCents": zod.number().nullish(),
  "allowUnderAllocation": zod.boolean(),
  "minCapacityPercent": zod.number().min(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryMinCapacityPercentMin).max(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryMinCapacityPercentMax).nullish(),
  "tariffPercentage": zod.number().nullish(),
  "chargeTypeTariffReferenceId": zod.string().nullish(),
  "chargeTypeTariffReference": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMinSaleQuantityMin).max(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMaxSaleQuantityMin).max(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMinSaleTariffQuantityMin).max(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMaxSaleTariffQuantityMin).max(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMinSaleQuantityMin).max(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMaxSaleQuantityMin).max(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMinSaleTariffQuantityMin).max(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMaxSaleTariffQuantityMin).max(getSchedulingSeatMapOverviewResponseAreasItemSlotsItemResourceCategoryChargeTypeTariffReferenceGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).nullish(),
  "color": zod.string(),
  "blocked": zod.boolean()
}).nullish(),
  "available": zod.boolean()
}).optional()
}))
}))
})


export const createProductSchedulingBody = zod.object({
  "tariffGroupId": zod.string().nullish(),
  "priceModifierId": zod.string().nullish(),
  "beginDate": zod.string().date(),
  "consumableFromTime": zod.string().nullish(),
  "consumableToTime": zod.string().nullish(),
  "calendarIntervalMinutes": zod.number().nullish(),
  "antecedenceHours": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish(),
  "antecedenceHoursReferenceTime": zod.string().nullish(),
  "recurrence": zod.object({
  "amount": zod.number(),
  "type": zod.enum(['days', 'weeks']),
  "weekdays": zod.array(zod.enum(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'])).optional(),
  "endDate": zod.string().date()
}).nullish(),
  "durationMinutes": zod.number(),
  "overrideConflicts": zod.boolean().optional()
})


export const getProductDetailedTariffGroupsQueryOffsetMin = 0;
export const getProductDetailedTariffGroupsQueryLimitMax = 100;


export const getProductDetailedTariffGroupsQueryParams = zod.object({
  "offset": zod.number().min(getProductDetailedTariffGroupsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getProductDetailedTariffGroupsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['tariffs', 'tariffs.type', 'tariffs.group', 'tariffs.product', 'priceInfo'])).optional()
})

export const getProductDetailedTariffGroupsResponseTariffsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductDetailedTariffGroupsResponseTariffsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductDetailedTariffGroupsResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductDetailedTariffGroupsResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductDetailedTariffGroupsResponseTariffsItemProductMinSaleTariffQuantityMin = 0;

export const getProductDetailedTariffGroupsResponseTariffsItemProductMinSaleTariffQuantityMax = 100;
export const getProductDetailedTariffGroupsResponseTariffsItemProductMaxSaleTariffQuantityMin = 0;

export const getProductDetailedTariffGroupsResponseTariffsItemProductMaxSaleTariffQuantityMax = 100;
export const getProductDetailedTariffGroupsResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductDetailedTariffGroupsResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductDetailedTariffGroupsResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductDetailedTariffGroupsResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductDetailedTariffGroupsResponseTariffsItemGroupProductMinSaleTariffQuantityMin = 0;

export const getProductDetailedTariffGroupsResponseTariffsItemGroupProductMinSaleTariffQuantityMax = 100;
export const getProductDetailedTariffGroupsResponseTariffsItemGroupProductMaxSaleTariffQuantityMin = 0;

export const getProductDetailedTariffGroupsResponseTariffsItemGroupProductMaxSaleTariffQuantityMax = 100;


export const getProductDetailedTariffGroupsResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().optional(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductDetailedTariffGroupsResponseTariffsItemProductTariffSettingsMinSaleQuantityMin).max(getProductDetailedTariffGroupsResponseTariffsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductDetailedTariffGroupsResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin).max(getProductDetailedTariffGroupsResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductDetailedTariffGroupsResponseTariffsItemProductMinSaleTariffQuantityMin).max(getProductDetailedTariffGroupsResponseTariffsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductDetailedTariffGroupsResponseTariffsItemProductMaxSaleTariffQuantityMin).max(getProductDetailedTariffGroupsResponseTariffsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductDetailedTariffGroupsResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin).max(getProductDetailedTariffGroupsResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductDetailedTariffGroupsResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin).max(getProductDetailedTariffGroupsResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductDetailedTariffGroupsResponseTariffsItemGroupProductMinSaleTariffQuantityMin).max(getProductDetailedTariffGroupsResponseTariffsItemGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductDetailedTariffGroupsResponseTariffsItemGroupProductMaxSaleTariffQuantityMin).max(getProductDetailedTariffGroupsResponseTariffsItemGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}))
})
export const getProductDetailedTariffGroupsResponse = zod.array(getProductDetailedTariffGroupsResponseItem)


export const getProductDetailedDefaultTariffGroupQueryParams = zod.object({
  "include": zod.array(zod.enum(['tariffs', 'tariffs.type', 'tariffs.group', 'tariffs.product', 'priceInfo'])).optional()
})

export const getProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductDetailedDefaultTariffGroupResponseTariffsItemProductMinSaleTariffQuantityMin = 0;

export const getProductDetailedDefaultTariffGroupResponseTariffsItemProductMinSaleTariffQuantityMax = 100;
export const getProductDetailedDefaultTariffGroupResponseTariffsItemProductMaxSaleTariffQuantityMin = 0;

export const getProductDetailedDefaultTariffGroupResponseTariffsItemProductMaxSaleTariffQuantityMax = 100;
export const getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMinSaleTariffQuantityMin = 0;

export const getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMinSaleTariffQuantityMax = 100;
export const getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMaxSaleTariffQuantityMin = 0;

export const getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMaxSaleTariffQuantityMax = 100;


export const getProductDetailedDefaultTariffGroupResponse = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().optional(),
  "tariffs": zod.array(zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMinSaleQuantityMin).max(getProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMaxSaleQuantityMin).max(getProductDetailedDefaultTariffGroupResponseTariffsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductDetailedDefaultTariffGroupResponseTariffsItemProductMinSaleTariffQuantityMin).max(getProductDetailedDefaultTariffGroupResponseTariffsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductDetailedDefaultTariffGroupResponseTariffsItemProductMaxSaleTariffQuantityMin).max(getProductDetailedDefaultTariffGroupResponseTariffsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "groupId": zod.string().optional(),
  "group": zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMin).max(getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMin).max(getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMinSaleTariffQuantityMin).max(getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMaxSaleTariffQuantityMin).max(getProductDetailedDefaultTariffGroupResponseTariffsItemGroupProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "seatMapId": zod.string().nullish(),
  "name": zod.string(),
  "isDefault": zod.boolean(),
  "priceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullable()
}))
})


export const getProductAdditionalsQueryOffsetMin = 0;
export const getProductAdditionalsQueryLimitMax = 100;


export const getProductAdditionalsQueryParams = zod.object({
  "offset": zod.number().min(getProductAdditionalsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getProductAdditionalsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product'])).optional()
})

export const getProductAdditionalsResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductAdditionalsResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductAdditionalsResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductAdditionalsResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductAdditionalsResponseProductMinSaleTariffQuantityMin = 0;

export const getProductAdditionalsResponseProductMinSaleTariffQuantityMax = 100;
export const getProductAdditionalsResponseProductMaxSaleTariffQuantityMin = 0;

export const getProductAdditionalsResponseProductMaxSaleTariffQuantityMax = 100;


export const getProductAdditionalsResponseItem = zod.object({
  "id": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductAdditionalsResponseProductTariffSettingsMinSaleQuantityMin).max(getProductAdditionalsResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductAdditionalsResponseProductTariffSettingsMaxSaleQuantityMin).max(getProductAdditionalsResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductAdditionalsResponseProductMinSaleTariffQuantityMin).max(getProductAdditionalsResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductAdditionalsResponseProductMaxSaleTariffQuantityMin).max(getProductAdditionalsResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "type": zod.enum(['per_person', 'per_person_with_input', 'per_item']),
  "title": zod.string(),
  "priceCents": zod.number(),
  "description": zod.string().nullable(),
  "customData": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
})).optional(),
  "maxQuantity": zod.number().nullable(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().nullish()
})
export const getProductAdditionalsResponse = zod.array(getProductAdditionalsResponseItem)


export const getProductRelatedTariffTypesQueryOffsetMin = 0;
export const getProductRelatedTariffTypesQueryLimitMax = 100;


export const getProductRelatedTariffTypesQueryParams = zod.object({
  "offset": zod.number().min(getProductRelatedTariffTypesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getProductRelatedTariffTypesQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['app'])).optional()
})

export const getProductRelatedTariffTypesResponseItem = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getProductRelatedTariffTypesResponse = zod.array(getProductRelatedTariffTypesResponseItem)


export const getProductCategoriesQueryOffsetMin = 0;
export const getProductCategoriesQueryLimitMax = 100;


export const getProductCategoriesQueryParams = zod.object({
  "offset": zod.number().min(getProductCategoriesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getProductCategoriesQueryLimitMax).optional()
})

export const getProductCategoriesResponseItem = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().optional()
})
export const getProductCategoriesResponse = zod.array(getProductCategoriesResponseItem)


export const getPlanFeesQueryOffsetMin = 0;
export const getPlanFeesQueryLimitMax = 100;


export const getPlanFeesQueryParams = zod.object({
  "offset": zod.number().min(getPlanFeesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getPlanFeesQueryLimitMax).optional(),
  "ids": zod.array(zod.string()).optional(),
  "types": zod.array(zod.enum(['sales', 'payments', 'direct_links'])).optional(),
  "paymentMethodCodes": zod.array(zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])).optional()
})

export const getPlanFeesResponseItem = zod.object({
  "id": zod.string(),
  "planId": zod.string(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "paymentMethodType": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "minInstallments": zod.number(),
  "maxInstallments": zod.number(),
  "generalFee": zod.number(),
  "planRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getPlanFeesResponse = zod.array(getPlanFeesResponseItem)


export const getEstablishmentTypesQueryOffsetMin = 0;
export const getEstablishmentTypesQueryLimitMax = 100;


export const getEstablishmentTypesQueryParams = zod.object({
  "offset": zod.number().min(getEstablishmentTypesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getEstablishmentTypesQueryLimitMax).optional()
})

export const getEstablishmentTypesResponseItem = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "mcc": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getEstablishmentTypesResponse = zod.array(getEstablishmentTypesResponseItem)


export const getOrderOwnSaleQueryParams = zod.object({
  "include": zod.array(zod.enum(['coupon', 'coupon.products', 'coupon.products.product', 'customer', 'origin', 'order', 'order.decisiveTransaction', 'order.decisiveTransaction.paymentMethod', 'aggregatedVouchers', 'tags', 'aggregatedRefundRequests', 'decisiveTransaction', 'decisiveTransaction.paymentMethod'])).optional()
})

export const getOrderOwnSaleResponseAmountCentsMin = 0;
export const getOrderOwnSaleResponsePartialAmountCentsMin = 0;
export const getOrderOwnSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin = 0;

export const getOrderOwnSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax = 100;
export const getOrderOwnSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin = 0;

export const getOrderOwnSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax = 100;
export const getOrderOwnSaleResponseCouponProductsItemProductMinSaleTariffQuantityMin = 0;

export const getOrderOwnSaleResponseCouponProductsItemProductMinSaleTariffQuantityMax = 100;
export const getOrderOwnSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMin = 0;

export const getOrderOwnSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMax = 100;


export const getOrderOwnSaleResponse = zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "position": zod.number(),
  "code": zod.string().nullable(),
  "customerId": zod.string(),
  "customer": zod.object({
  "id": zod.string(),
  "firstName": zod.string(),
  "lastName": zod.string(),
  "hasAttachedBuyer": zod.boolean(),
  "email": zod.string().email().nullable(),
  "phone": zod.string().nullable(),
  "identity": zod.string().nullable(),
  "identityType": zod.enum(['cpf', 'cnpj', 'identity', 'passport']).nullable(),
  "corporateName": zod.string().nullable(),
  "countryCode": zod.string().nullable(),
  "birthdate": zod.string().date().nullable(),
  "address": zod.object({
  "zipCode": zod.string().nullable(),
  "state": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}).nullable(),
  "isExternal": zod.boolean(),
  "agreedToTerms": zod.boolean(),
  "createdAt": zod.string().datetime()
}).optional(),
  "originId": zod.string().nullable(),
  "origin": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "code": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "orderId": zod.string().nullable(),
  "order": zod.object({
  "id": zod.string(),
  "appId": zod.string(),
  "code": zod.string(),
  "decisiveTransaction": zod.object({
  "installments": zod.number(),
  "paymentMethod": zod.object({
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid'])
}).optional(),
  "brand": zod.string().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "paymentMethodDiscountPercentage": zod.number().nullable()
}).nullish(),
  "createdAt": zod.string().datetime()
}).nullish(),
  "decisiveTransactionId": zod.string().nullish(),
  "decisiveTransaction": zod.object({
  "id": zod.string(),
  "normalizedStatus": zod.enum(['Aborted', 'Confirmed', 'Denied', 'Failed', 'Ignored', 'Pending', 'Refunded']),
  "installments": zod.number(),
  "paymentMethodId": zod.string(),
  "paymentMethod": zod.object({
  "id": zod.string(),
  "code": zod.enum(['credit_card', 'debit_card', 'pix', 'post_paid']),
  "name": zod.string(),
  "active": zod.boolean(),
  "maxInstallmentsNum": zod.number().optional(),
  "minInstallmentAmountCents": zod.number(),
  "discountPercentage": zod.number().optional(),
  "interestRatePercentage": zod.number(),
  "interestRateExemptedInstallments": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "brand": zod.string().nullish(),
  "transactableType": zod.enum(['sales', 'payments', 'direct_links']),
  "transactableId": zod.string(),
  "paymentMethodDiscountPercentage": zod.number().nullish(),
  "paymentMethodInterestRatePercentage": zod.number(),
  "paymentMethodInterestRateCents": zod.number(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "currentState": zod.enum(['created', 'pending', 'expired', 'payment_complete', 'payment_voided', 'payment_chargeback', 'canceled']),
  "amountCents": zod.number().min(getOrderOwnSaleResponseAmountCentsMin),
  "partialAmountCents": zod.number().min(getOrderOwnSaleResponsePartialAmountCentsMin),
  "coupon": zod.object({
  "code": zod.string(),
  "discountAmountCents": zod.number().nullish(),
  "discountPercentage": zod.number().nullable(),
  "fullAmountCents": zod.number(),
  "discountedAmountCents": zod.number(),
  "usageType": zod.enum(['general', 'product']),
  "type": zod.enum(['nominal', 'percentage']),
  "products": zod.array(zod.object({
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getOrderOwnSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMin).max(getOrderOwnSaleResponseCouponProductsItemProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getOrderOwnSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMin).max(getOrderOwnSaleResponseCouponProductsItemProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getOrderOwnSaleResponseCouponProductsItemProductMinSaleTariffQuantityMin).max(getOrderOwnSaleResponseCouponProductsItemProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getOrderOwnSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMin).max(getOrderOwnSaleResponseCouponProductsItemProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "nominalDiscountCents": zod.number(),
  "percentageDiscount": zod.number().nullable()
})).nullish()
}).nullish(),
  "aggregatedVouchers": zod.object({
  "consumedVouchersCount": zod.number(),
  "vouchersCount": zod.number()
}).optional(),
  "tags": zod.array(zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime().nullable()
})).optional(),
  "aggregatedRefundRequests": zod.object({
  "open": zod.number(),
  "total": zod.number()
}).optional(),
  "isExternal": zod.boolean(),
  "directLinkId": zod.string().nullish(),
  "attributes": zod.array(zod.string()).nullable(),
  "agentId": zod.string().nullish(),
  "marketingData": zod.object({
  "utms": zod.array(zod.object({
  "key": zod.string(),
  "value": zod.string()
}))
}).nullable(),
  "expiresAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime()
})


export const getAppProductPackagesQueryOffsetMin = 0;
export const getAppProductPackagesQueryLimitMax = 100;


export const getAppProductPackagesQueryParams = zod.object({
  "offset": zod.number().min(getAppProductPackagesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getAppProductPackagesQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['app', 'image'])).optional(),
  "productIds": zod.array(zod.string()).optional(),
  "productCategoryIds": zod.array(zod.string()).optional(),
  "search": zod.string().optional(),
  "sortBy": zod.enum(['recent']).optional(),
  "ids": zod.array(zod.string()).optional(),
  "filter": zod.enum(['all', 'active', 'archived']).optional()
})

export const getAppProductPackagesResponseItem = zod.object({
  "id": zod.string(),
  "appId": zod.string().optional(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).optional(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "imageId": zod.string().nullable(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "description": zod.string().nullable(),
  "shortDescription": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "voucherGuidelines": zod.string().nullable(),
  "deletedAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getAppProductPackagesResponse = zod.array(getAppProductPackagesResponseItem)


export const createAppProductPackageBody = zod.object({
  "name": zod.string(),
  "internalName": zod.string().nullish(),
  "imageId": zod.string().nullish(),
  "description": zod.string().nullish(),
  "shortDescription": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "voucherGuidelines": zod.string().nullish(),
  "products": zod.array(zod.object({
  "productId": zod.string(),
  "discountPercentage": zod.number(),
  "position": zod.number().nullish()
})),
  "categoryIds": zod.array(zod.string()).nullish()
})


export const getProductPackageQueryParams = zod.object({
  "include": zod.array(zod.enum(['app', 'image'])).optional()
})

export const getProductPackageResponse = zod.object({
  "id": zod.string(),
  "appId": zod.string().optional(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).optional(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "imageId": zod.string().nullable(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "description": zod.string().nullable(),
  "shortDescription": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "voucherGuidelines": zod.string().nullable(),
  "deletedAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const updateProductPackageBody = zod.object({
  "name": zod.string(),
  "internalName": zod.string().nullish(),
  "imageId": zod.string().nullish(),
  "description": zod.string().nullish(),
  "shortDescription": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "voucherGuidelines": zod.string().nullish(),
  "products": zod.array(zod.object({
  "productId": zod.string(),
  "discountPercentage": zod.number(),
  "position": zod.number().nullish()
})),
  "categoryIds": zod.array(zod.string()).nullish()
})

export const updateProductPackageResponse = zod.object({
  "id": zod.string(),
  "appId": zod.string().optional(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).optional(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "imageId": zod.string().nullable(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "description": zod.string().nullable(),
  "shortDescription": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "voucherGuidelines": zod.string().nullable(),
  "deletedAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})


export const getProductPackageProductsQueryOffsetMin = 0;
export const getProductPackageProductsQueryLimitMax = 100;


export const getProductPackageProductsQueryParams = zod.object({
  "offset": zod.number().min(getProductPackageProductsQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getProductPackageProductsQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['product', 'productPackage'])).optional()
})

export const getProductPackageProductsResponseProductTariffSettingsMinSaleQuantityMin = 0;

export const getProductPackageProductsResponseProductTariffSettingsMinSaleQuantityMax = 100;
export const getProductPackageProductsResponseProductTariffSettingsMaxSaleQuantityMin = 0;

export const getProductPackageProductsResponseProductTariffSettingsMaxSaleQuantityMax = 100;
export const getProductPackageProductsResponseProductMinSaleTariffQuantityMin = 0;

export const getProductPackageProductsResponseProductMinSaleTariffQuantityMax = 100;
export const getProductPackageProductsResponseProductMaxSaleTariffQuantityMin = 0;

export const getProductPackageProductsResponseProductMaxSaleTariffQuantityMax = 100;


export const getProductPackageProductsResponseItem = zod.object({
  "id": zod.string(),
  "productPackageId": zod.string(),
  "productId": zod.string(),
  "product": zod.object({
  "id": zod.string(),
  "sellingMode": zod.enum(['with_date_and_time', 'with_date_only', 'without_date']),
  "active": zod.boolean(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "description": zod.string(),
  "shortDescription": zod.string(),
  "voucherSettings": zod.object({
  "splitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullish(),
  "expirationDatetime": zod.string().datetime().nullish(),
  "expirationDays": zod.number().nullish(),
  "guidelines": zod.string().nullish()
}),
  "tariffSettings": zod.object({
  "minSaleQuantity": zod.number().min(getProductPackageProductsResponseProductTariffSettingsMinSaleQuantityMin).max(getProductPackageProductsResponseProductTariffSettingsMinSaleQuantityMax),
  "maxSaleQuantity": zod.number().min(getProductPackageProductsResponseProductTariffSettingsMaxSaleQuantityMin).max(getProductPackageProductsResponseProductTariffSettingsMaxSaleQuantityMax),
  "displayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullish(),
  "durationMinutes": zod.number().nullish(),
  "antecedenceMinutes": zod.number().nullish()
}),
  "voucherGuidelines": zod.string().nullish(),
  "includedItems": zod.array(zod.string()).nullish(),
  "notIncludedItems": zod.array(zod.string()).nullish(),
  "tariffDisplayOrder": zod.enum(['alphabetical', 'created_date', 'lower_price', 'higher_price']).nullable(),
  "minSaleTariffQuantity": zod.number().min(getProductPackageProductsResponseProductMinSaleTariffQuantityMin).max(getProductPackageProductsResponseProductMinSaleTariffQuantityMax),
  "maxSaleTariffQuantity": zod.number().min(getProductPackageProductsResponseProductMaxSaleTariffQuantityMin).max(getProductPackageProductsResponseProductMaxSaleTariffQuantityMax),
  "durationMinutes": zod.number().nullable(),
  "antecedenceMinutes": zod.number().nullable(),
  "voucherSplitPolicy": zod.enum(['normal', 'unit']),
  "expirationType": zod.enum(['fixed_date', 'fixed_days']).nullable(),
  "expirationDatetime": zod.string().datetime().nullable(),
  "expirationDays": zod.number().nullable(),
  "defaultTariffGroupPriceInfo": zod.object({
  "defaultPriceCents": zod.number(),
  "maxPriceCents": zod.number(),
  "minPriceCents": zod.number()
}).nullish(),
  "defaultTariff": zod.object({
  "id": zod.string(),
  "typeId": zod.string(),
  "type": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).optional(),
  "priceCents": zod.number(),
  "oldPriceCents": zod.number().nullish(),
  "description": zod.string().nullish(),
  "isDependent": zod.boolean(),
  "isDefault": zod.boolean()
}).nullish(),
  "thumbnailUrl": zod.string().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "productPackage": zod.object({
  "id": zod.string(),
  "appId": zod.string().optional(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).optional(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "imageId": zod.string().nullable(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "description": zod.string().nullable(),
  "shortDescription": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "voucherGuidelines": zod.string().nullable(),
  "deletedAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "discountPercentage": zod.number().optional(),
  "position": zod.number().optional(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getProductPackageProductsResponse = zod.array(getProductPackageProductsResponseItem)


export const getProductPackageCategoriesQueryOffsetMin = 0;
export const getProductPackageCategoriesQueryLimitMax = 100;


export const getProductPackageCategoriesQueryParams = zod.object({
  "offset": zod.number().min(getProductPackageCategoriesQueryOffsetMin).optional(),
  "limit": zod.number().min(1).max(getProductPackageCategoriesQueryLimitMax).optional(),
  "include": zod.array(zod.enum(['category', 'productPackage'])).optional()
})

export const getProductPackageCategoriesResponseItem = zod.object({
  "id": zod.string(),
  "productPackageId": zod.string(),
  "categoryId": zod.string(),
  "productPackage": zod.object({
  "id": zod.string(),
  "appId": zod.string().optional(),
  "app": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "logoUrl": zod.string().nullable(),
  "address": zod.object({
  "uf": zod.string().nullable(),
  "city": zod.string().nullable(),
  "district": zod.string().nullable(),
  "street": zod.string().nullable(),
  "number": zod.string().nullable(),
  "complement": zod.string().nullish()
}),
  "contact": zod.object({
  "email": zod.string().nullable(),
  "phone": zod.string().nullable()
}),
  "code": zod.string(),
  "publicCode": zod.string(),
  "publicApiToken": zod.string(),
  "createdAt": zod.string().datetime(),
  "languages": zod.array(zod.string()).nullable(),
  "planId": zod.string(),
  "plan": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "sellerType": zod.enum(['provider', 'agent'])
}).nullish(),
  "minuteSetInterval": zod.number()
}).optional(),
  "name": zod.string(),
  "internalName": zod.string().nullable(),
  "imageId": zod.string().nullable(),
  "image": zod.object({
  "id": zod.string(),
  "alternativeText": zod.string(),
  "styles": zod.array(zod.object({
  "style": zod.string(),
  "url": zod.string()
})),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "description": zod.string().nullable(),
  "shortDescription": zod.string().nullable(),
  "includedItems": zod.array(zod.string()).nullable(),
  "notIncludedItems": zod.array(zod.string()).nullable(),
  "voucherGuidelines": zod.string().nullable(),
  "deletedAt": zod.string().datetime().nullable(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
}).nullish(),
  "category": zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "createdAt": zod.string().datetime(),
  "deletedAt": zod.string().datetime().optional()
}).nullish(),
  "createdAt": zod.string().datetime(),
  "updatedAt": zod.string().datetime()
})
export const getProductPackageCategoriesResponse = zod.array(getProductPackageCategoriesResponseItem)


