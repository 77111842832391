import { ConfigFields, UseConfig } from '@/features/config/types/config';
import { useConfig } from '@/features/config/useConfig';
import useFilter from './useFilter';
import { useMemo, useState } from 'react';

type UseConfigFilter<T extends UseConfig<ConfigFields>, F extends ConfigFields> = {
  config: T;
  totalOfSelectedFilters: number;
  totalOfFilters: number;
  selectedFilters: Partial<F>;
  handleSelectedFilters: (filters: Partial<F>) => void;
  handleApplyFilter: () => void;
  handleResetFilters: () => void;
  handleCloseFilters: () => void;
};

const useConfigFilter = <T extends UseConfig<ConfigFields>, F extends ConfigFields>(): UseConfigFilter<T, F> => {
  const config = useConfig<T>();
  const [selectedFilters, setSelectedFilters] = useState<Partial<F>>(config.config as Partial<F>);
  const { closeFilters } = useFilter();

  const totalOfSelectedFilters = useMemo(
    () => Object.values(selectedFilters).filter((value) => value || (Array.isArray(value) && value.length > 0)).length,
    [selectedFilters],
  );

  const totalOfFilters = useMemo(
    () => Object.values(config).filter((value) => value || (Array.isArray(value) && value.length > 0)).length,
    [config],
  );

  const handleSelectedFilters = (filters: Partial<F>) => {
    setSelectedFilters(filters);
  };

  const handleApplyFilter = () => {
    config.patchConfig(selectedFilters);
    closeFilters();
  };

  const handleResetFilters = () => {
    config.clearConfig();
    handleSelectedFilters(config.defaultConfig as F);
    closeFilters();
  };

  const handleCloseFilters = () => {
    closeFilters();
  };

  return {
    config,
    totalOfSelectedFilters,
    totalOfFilters,
    selectedFilters,
    handleSelectedFilters,
    handleApplyFilter,
    handleResetFilters,
    handleCloseFilters,
  };
};

export default useConfigFilter;
