import { Stack, styled } from '@planne-software/uni/mui/material';
export const StyledDayCellSkeleton = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.tokens.spacing['spacing-1xmini'],
  width: '3.1875rem',
  height: '5rem',
  gap: theme.tokens.spacing['spacing-2xmini'],
  borderRadius: theme.tokens.radius['border-radius-xs'],
  backgroundColor: theme.tokens.colors['fill-color-neutral-softest'],
  [theme.breakpoints.down('sm')]: {
    width: '2.7rem',
  },
}));
