type Fn<T> = (id: string) => Promise<T>;

const loadItems = async <T>(idsParam: string, fn: Fn<T>) => {
  const ids = idsParam.split(',');
  const promises = ids.map((itemId) => fn(itemId));
  return await Promise.all(promises);
};

const getItemsFromUrl = async <T>(url: URL, fn: Fn<T>, param: string) => {
  const ids = url.searchParams.get(param);

  if (ids) {
    return await loadItems<T>(ids, fn);
  }

  return false;
};

export default getItemsFromUrl;
