import i18n from '@/services/i18n';
import translateReservationPT from '@/features/reservation/locales/pt.json';
import { Fragment, useEffect, useMemo, useCallback, useState } from 'react';
import ReservationDetails from './ReservationDetails';
import { Actions, Content } from './styles';
import Button from '@/components/Button';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import {
  useGetReservation,
  useGetReservationTariffs,
  useRescheduleReservation,
  useSecureRescheduleReservation,
} from '@/services/sellerApiQuery/api/reservations/reservations';
import BackdropLoading from '@/components/BackdropLoading';
import theme from '@/theme';
import { Box, useMediaQuery } from '@mui/system';
import RescheduleDateTimeSelector from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector';
import { zodResolver } from '@hookform/resolvers/zod';
import { rescheduleFormSchema, isValidDateTime } from '@/features/reservation/components/RescheduleForm/schema';
import { useForm } from 'react-hook-form';
import { SecureRescheduleReservationParams } from '@/services/sellerApiQuery/model';
import { RescheduleFormError, RescheduleFormSchema } from '@/features/reservation/components/RescheduleForm/types';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import useErrorHandler from '@/hooks/useErrorHandler';
import { enqueueSnackbar } from 'notistack';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import { getUseLoadReservationRescheduleSchedulingsQueryKey } from '@/features/reservation/queries/useLoadReservationRescheduleSchedulings';
import { useQueryClient, InvalidateQueryFilters } from '@tanstack/react-query';
import { CalendarProvider } from '@/contexts/Calendar/Provider';

type Props = {
  reservationId: string;
  onCancel?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
};

const RescheduleForm = (props: Props) => {
  const { reservationId, onCancel = () => {}, onSuccess = () => {}, onFail = () => {} } = props;

  // TODO: Move this to a Router Resolver
  i18n.addResourceBundle('pt-BR', 'reservation', translateReservationPT);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(['reservation'], { keyPrefix: 'reschedule_form' });
  const { errorHandler, reportError } = useErrorHandler();
  const queryClient = useQueryClient();

  const [isChaosMode, setIsChaosMode] = useState(false);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [calendarView, setCalendarView] = useState(15);

  const { data: reservation, isLoading: isLoadingReservation } = useGetReservation(reservationId, {
    include: ['product'],
  });

  const { data: reservationTariffs, isLoading: isLoadingTariffs } = useGetReservationTariffs(reservationId, {
    include: ['tariff.type', 'tariff.group'],
  });

  const {
    mutate: rescheduleReservationSecure,
    error: errorResponseSecure,
    isSuccess: isSuccessSecure,
    isPending: isPendingSecure,
  } = useSecureRescheduleReservation();

  const {
    mutate: rescheduleReservationInsecure,
    error: errorResponseInsecure,
    isSuccess: isSuccessInsecure,
    isPending: isPendingInsecure,
  } = useRescheduleReservation();

  const isSuccess = useMemo(() => isSuccessInsecure || isSuccessSecure, [isSuccessInsecure, isSuccessSecure]);

  const errorResponse = useMemo(
    () => errorResponseSecure || errorResponseInsecure,
    [errorResponseSecure, errorResponseInsecure],
  );

  const isPending = useMemo(() => isPendingSecure || isPendingInsecure, [isPendingSecure, isPendingInsecure]);

  const isLoading = useMemo(
    () => isLoadingReservation || isLoadingTariffs || isPending,
    [isLoadingReservation, isLoadingTariffs, isPending],
  );

  const resolver = useMemo(() => zodResolver(rescheduleFormSchema(reservation)), [reservation]);

  const reschedulingForm = useForm<RescheduleFormSchema, unknown, SecureRescheduleReservationParams>({
    mode: 'onChange',
    values: {
      scheduleDate: reservation?.scheduleDate ? apiDateToDateObject(reservation.scheduleDate) : undefined,
      scheduleTime: reservation?.scheduleTime ?? undefined,
    },
    reValidateMode: 'onBlur',
    resetOptions: {
      keepIsValid: false,
    },
    resolver,
  });

  const { handleSubmit, setError, setValue, reset, watch } = reschedulingForm;

  const scheduleDate = watch('scheduleDate');
  const scheduleTime = watch('scheduleTime');

  const isSubmitDisabled = useMemo(() => {
    return !isValidDateTime({ scheduleDate, scheduleTime }, reservation);
  }, [isPending, scheduleDate, scheduleTime, reservation]);

  const onSubmit = (data: SecureRescheduleReservationParams) => {
    if (isChaosMode) {
      return rescheduleReservationInsecure({
        reservationId: reservationId,
        data: {
          ...data,
          verifications: {
            ignoreSchedulings: true,
            ignoreAvailabilities: true,
            ignoreResources: true,
            ignoreSchedulingAntecedence: true,
          },
        },
      });
    } else {
      rescheduleReservationSecure({
        reservationId: reservationId,
        data,
      });
    }
  };

  const handleErrorResponse = useCallback(
    (response: unknown) => {
      if (response) {
        const { errors } = errorHandler<RescheduleFormError>(response, AnalyticsEvents.RESCHEDULE_FAIL);
        reportError(response);

        if (errors) {
          errors.forEach((error) => {
            const message = t(`erros.${error.rule}`);
            setError('root', {
              type: 'manual',
              message,
            });
            enqueueSnackbar(message, {
              variant: 'error',
            });
          });
        }

        onFail();
      }
    },
    [onFail, errorHandler, reportError],
  );

  const handleSuccess = useCallback(() => {
    enqueueSnackbar(t('date_time_selector.success'), {
      variant: 'success',
    });

    queryClient.invalidateQueries(
      getUseLoadReservationRescheduleSchedulingsQueryKey(reservationId) as InvalidateQueryFilters,
    );

    onSuccess();
  }, [onSuccess, reservationId, queryClient]);

  useEffect(() => {
    handleErrorResponse(errorResponse);
  }, [errorResponse]);

  useEffect(() => {
    if (isSuccess) {
      handleSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    const isSellModeWithDateOnly = reservation?.product?.sellingMode === 'with_date_only';

    if (!isSellModeWithDateOnly) {
      setValue('scheduleTime', null, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }
  }, [scheduleDate]);

  return (
    <Fragment>
      {isLoading ? <BackdropLoading /> : null}

      {!isLoadingReservation && !isLoadingTariffs && reservation && reservationTariffs ? (
        <Box component='form' onSubmit={handleSubmit(onSubmit)}>
          <Content
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: 'calc(100vh - 200px)',
              overflow: isMobile ? 'auto' : 'initial',
            }}
          >
            <ReservationDetails
              reservation={reservation}
              reservationTariffs={reservationTariffs}
              isChaosMode={isChaosMode}
              onChaosModeChange={(state) => {
                setIsChaosMode(state);
                if (!state) {
                  reset();
                }
              }}
            />

            <CalendarProvider
              activeDate={calendarDate}
              onDateChange={(date: Date) => setCalendarDate(date)}
              calendarView={calendarView}
              onCalendarViewChange={(view) => setCalendarView(view)}
            >
              <RescheduleDateTimeSelector reservation={reservation} form={reschedulingForm} isChaosMode={isChaosMode} />
            </CalendarProvider>
          </Content>

          <Actions
            sx={{
              flexDirection: { xs: 'column-reverse', md: 'row' },
            }}
          >
            <Button variant='outlined' startIcon={<IconX />} onClick={() => onCancel()} fullWidth={isMobile}>
              {t('cancel')}
            </Button>

            <Button
              variant='contained'
              startIcon={<IconCheck />}
              type='submit'
              fullWidth={isMobile}
              disabled={isSubmitDisabled}
            >
              {t('confirm')}
            </Button>
          </Actions>
        </Box>
      ) : null}
    </Fragment>
  );
};

export default RescheduleForm;
