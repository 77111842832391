/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */

export type PolicyRuleNamespace = typeof PolicyRuleNamespace[keyof typeof PolicyRuleNamespace];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyRuleNamespace = {
  apps: 'apps',
  sales: 'sales',
  saleItems: 'saleItems',
  tariffGroups: 'tariffGroups',
  customers: 'customers',
  reservations: 'reservations',
  vouchers: 'vouchers',
  products: 'products',
  saleAdditionals: 'saleAdditionals',
  incomeSchedulings: 'incomeSchedulings',
  refundRequests: 'refundRequests',
  directLinks: 'directLinks',
  siteConfigs: 'siteConfigs',
  paymentMethods: 'paymentMethods',
  availabilities: 'availabilities',
  saleOrigins: 'saleOrigins',
  schedulings: 'schedulings',
  partnerships: 'partnerships',
  coupons: 'coupons',
  tags: 'tags',
  reservationTariffs: 'reservationTariffs',
  arrangedProducts: 'arrangedProducts',
  directSales: 'directSales',
  couponProducts: 'couponProducts',
  productCategories: 'productCategories',
  productPackages: 'productPackages',
  productPackageProducts: 'productPackageProducts',
  productPackageCategories: 'productPackageCategories',
} as const;
