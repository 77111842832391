import { usePersistenceProxy } from '@/features/config/context/persistenceProxy';
import { ConfigContextStore, UseConfig } from '@/features/config/types/config';
import useConfigManager from '@/features/config/useConfigManager';
import useGetLocalStoragePersistKey from '@/features/config/useGetLocalStoragePersistKey';
import { ProductPackagesConfigState } from '@/features/productPackages/types';
import normalizeArray from '@/utils/normalizeArray';

interface ProductPackagesConfigStore extends ConfigContextStore<ProductPackagesConfigState> {}

const baseConfig = {
  products: [],
  productCategories: [],
  search: undefined,
};

const useProductPackagesConfig = (): ProductPackagesConfigStore => {
  const key = useGetLocalStoragePersistKey({
    pageId: 'product-packages',
  });

  const context = {
    localStorageKey: key,
    keys: [
      {
        stateKey: 'config.products',
        urlKey: 'products',
        persistOnLocalStorage: true,
        formatFn: (value: string) => normalizeArray(value.split(',')),
      },
      {
        stateKey: 'config.productCategories',
        urlKey: 'categories',
        persistOnLocalStorage: true,
        formatFn: (value: string) => normalizeArray(value.split(',')),
      },
      {
        stateKey: 'config.search',
        urlKey: 'search',
        persistOnLocalStorage: true,
      },
    ],
  } as ProductPackagesConfigStore;

  const configWithProxy = usePersistenceProxy<UseConfig<ProductPackagesConfigStore>>({
    localStorageKey: context.localStorageKey,
    keys: context.keys,
    config: baseConfig,
    defaultConfig: baseConfig,
  });

  const { config, patchConfig, clearConfig, defaultConfig } =
    useConfigManager<ProductPackagesConfigStore>(configWithProxy);

  return {
    ...context,
    config,
    patchConfig,
    clearConfig,
    defaultConfig,
  } as ConfigContextStore<ProductPackagesConfigState>;
};

export default useProductPackagesConfig;
