import { format } from 'date-fns';

import i18n from '@/services/i18n';
import { dateWithTimezone } from '@/utils';
import { SellingMode } from '@/services/SellerApi';

export const getProductSellingModeDisplay = (
  sellingMode: SellingMode,
  scheduleDate?: string | null,
  scheduleTime?: string | null,
) => {
  if (sellingMode === SellingMode.WithDateAndTime && scheduleDate && scheduleTime) {
    return format(dateWithTimezone(scheduleDate), 'dd/MM/yyyy') + ' - ' + scheduleTime;
  }

  if (sellingMode === SellingMode.WithDateOnly && scheduleDate) {
    return format(dateWithTimezone(scheduleDate), 'dd/MM/yyyy');
  }

  return i18n.t('product.product_without_date', { ns: 'ui' });
};
