import i18n from '@/services/i18n';
import translatePT from '@/features/productPackages/locales/pt.json';

export const NS = 'product-packages';

const loadProductPackagesTranslations = () => {
  i18n.addResourceBundle('pt-BR', NS, translatePT);
};

export default loadProductPackagesTranslations;
