import { styled, Stack, grid2Classes } from '@planne-software/uni/mui/material';
import { datePickerClasses, DatePickerLegend, datePickerLegendClasses } from '@planne-software/uni/DatePicker';
import { Button } from '@planne-software/uni/Button';

export const StyledFormContent = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing['spacing-sm'],
  padding: theme.tokens.spacing['spacing-sm'],

  [`& .${datePickerClasses.root}`]: {
    minWidth: 'auto',
  },
}));

export const StyledHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '1rem',

  [theme.breakpoints.down('sm')]: {
    gap: '0.5rem',
    flexDirection: 'column',
  },
}));

export const StyledHeaderActions = styled(Stack)(({ theme }) => ({
  gap: '1rem',
  flexDirection: 'row',

  [theme.breakpoints.down('sm')]: {
    gap: '0.5rem',
  },
}));

export const StyledHeaderCalendarViewAction = styled(Button)(() => ({
  flex: '1',
}));

export const StyledDatePickerLegend = styled(DatePickerLegend)(() => ({
  [`&.${datePickerLegendClasses.root}`]: {
    maxWidth: '100%',
  },
}));

export const StyledTimeContainer = styled(Stack)(() => ({
  maxHeight: '336px',
  width: '100%',
  position: 'relative',

  [`& .${grid2Classes.root}`]: {
    '&::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D2D2D2',
      borderRadius: '0.25rem',
      width: '0.5rem',
      alignSelf: 'stretch',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: `calc(#D2D2D2 + 10%)`,
    },
  },
}));

export const StyledTimeSelect = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.tokens.spacing['spacing-2xmini'],
  justifyContent: 'flex-start',
  textAlign: 'left',
  borderRadius: theme.tokens.radius['border-radius-2xs'],
  '&:hover': {
    backgroundColor: theme.tokens.colors['background-fill'],
  },
}));
