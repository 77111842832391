import { TFunction } from 'i18next';
import { cnpj, cpf } from 'cpf-cnpj-validator';

import { z } from 'zod';
import { removeMask } from '@/utils/masks';
import { IdentityTypeEnum } from '@/services/SellerApi';
import { MERCOSUL_COUNTRY_CODE } from '@/common/countries';
import BrasilApi from '@/services/BrasilApi';

const fillAddress = async (zipCode  : string) => {
  if (!zipCode) return;

  try {
    const { data: addressData } = await BrasilApi.getAddressByCep({ cep: zipCode });
    if (addressData?.cep) {
      return {
        state: addressData.state,
        city: addressData.city,
        street: addressData.street,
        district: addressData.neighborhood,
        number: null,
        complement: null,
        zipCode: zipCode,
      };
    }
  } catch (error) {
    return {
      state: null,
      city: null,
      street: null,
      district: null,
      number: null,
      complement: null,
      zipCode: zipCode,
    };
  }
}

export const schema = (t: TFunction<'payment'[]>) => {
  const requiredMessage = t('customer_form.errors.required_field');

  const requiredStringField = z.string({ required_error: requiredMessage }).nonempty({ message: requiredMessage });

  return z
    .object({
      firstName: requiredStringField,
      lastName: requiredStringField,
      email: requiredStringField,
      phone: requiredStringField.superRefine((value, ctx) => {
        if (!value) return;

        if (!removeMask(value)) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: requiredMessage,
          });
        }

        // the real phone number after DDI
        if (!value.split(' ')[1]) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('customer_form.errors.invalid_phone'),
          });
        }
      }),
      identity: requiredStringField,
      nationality: z
        .object(
          {
            alpha2: requiredStringField,
            alpha3: requiredStringField,
            code: requiredStringField,
            name: requiredStringField,
            emoji: requiredStringField,
            unicode: requiredStringField,
            dialCode: requiredStringField,
            image: requiredStringField,
          },
          { required_error: requiredMessage, invalid_type_error: t('customer_form.errors.invalid_country') },
        )
        .refine((value) => !!value?.alpha3, {
          message: requiredMessage,
        }),
      identityType: z.string().optional(),
      corporateName: z.string().optional(),
      address: z.object({
        zipCode: z.string().nullable(),
        state: z.string().nullable(),
        city: z.string().nullable(),
        street: z.string().nullable(),
        district: z.string().nullable(),
        number: z.string().nullable(),
        complement: z.string().nullable(),
      }).optional(),
    })
    .superRefine(({ identityType, identity, nationality, corporateName, address }, ctx) => {
      const isBrazil = nationality.alpha3 === MERCOSUL_COUNTRY_CODE.BRAZIL;
      const isValidBrazilianIdentity = cpf.isValid(identity) || cnpj.isValid(identity);

      if (isBrazil && !isValidBrazilianIdentity) {
        ctx.addIssue({
          path: ['identity'],
          code: z.ZodIssueCode.custom,
          message: t('customer_form.errors.invalid_document'),
        });
      }

      if (isBrazil && !address?.zipCode) {
        ctx.addIssue({
          path: ['address.zipCode'],
          code: z.ZodIssueCode.custom,
          message: requiredMessage,
        });
      }

      if (cnpj.isValid(identity) && !corporateName) {
        ctx.addIssue({
          path: ['corporateName'],
          code: z.ZodIssueCode.custom,
          message: requiredMessage,
        });
      }

      if (identityType === IdentityTypeEnum.Cpf && !cpf.isValid(identity)) {
        ctx.addIssue({
          path: ['identity'],
          code: z.ZodIssueCode.custom,
          message: t('customer_form.errors.invalid_document'),
        });
      }

      if (identityType === IdentityTypeEnum.Cnpj && !cnpj.isValid(identity)) {
        ctx.addIssue({
          path: ['identity'],
          code: z.ZodIssueCode.custom,
          message: t('customer_form.errors.invalid_document'),
        });
      }
    })
    .transform(async (data) => {
      const hasIdentityType = !!data.identityType;
      const brazilianIdentityType = cpf.isValid(data.identity) ? IdentityTypeEnum.Cpf : IdentityTypeEnum.Cnpj;
      const countryCode = data.nationality?.alpha3;

      const zipCode = data.address?.zipCode?.replace(/\D/g, '');
      const address = zipCode ? await fillAddress(zipCode) : null;
      
      return {
        countryCode,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email.trim(),
        phone: data.phone.replace(/[^0-9+]/g, ''),
        identity: data.identity.replace(/[^0-9a-zA-Z]/g, ''),
        identityType: hasIdentityType ? data.identityType : brazilianIdentityType,
        corporateName: data.corporateName,
        address,
      };
    });
};
