import { useTranslation } from 'react-i18next';

import Select from '@/components/Form/Select';
import { FilterOption } from '@/interfaces/filterContext';
import { useCallback, useMemo } from 'react';
import { Product, SellingMode } from '@/services/sellerApiQuery/model';
import useLoadProductsQuery from '@/queries/useLoadProductsQuery';
import { NS } from '@/features/products/locales/loadProductsTranslations';
type Props = {
  value?: string | string[] | FilterOption[] | null;
  filterOptions?: string[];
  initialState?: Product[];
  error?: boolean;
  isMultiple?: boolean;
  activeOnly?: boolean;
  disableInactive?: boolean;
  sellingModes?: SellingMode[];
  onChange?: (event: unknown, value: unknown) => void;
  onReset?: () => void;
};
const ProductSelector = (props: Props) => {
  const {
    onChange = () => undefined,
    onReset,
    error,
    isMultiple = true,
    activeOnly = true,
    disableInactive = false,
    sellingModes = undefined,
    initialState = [],
    filterOptions = [],
    value,
  } = props;
  const { t } = useTranslation(['ui', NS]);

  const sellingModesParam = useMemo(() => {
    if (sellingModes) {
      if (sellingModes.length === 0) {
        return undefined;
      }
      return sellingModes;
    }
    return [SellingMode.with_date_and_time, SellingMode.with_date_only];
  }, [sellingModes]);

  const [productsQuery] = useLoadProductsQuery(sellingModesParam, initialState, activeOnly);

  const { data: products, isLoading: productsLoading } = productsQuery(true);

  const productsOptions = useMemo(() => {
    return (
      products?.map((product) => ({
        label: product.internalName ?? product.name,
        value: product.id,
      })) || []
    );
  }, [products]);

  const filterOptionsFinal = useMemo(() => {
    if (disableInactive) {
      return [
        ...productsOptions
          .filter((p) => !products?.some((product) => product.id === p.value && product.active))
          .map((p) => p.value),
        ...filterOptions,
      ];
    }
    return filterOptions;
  }, [productsOptions, filterOptions, disableInactive]);

  const selectValue = useMemo(() => {
    if (!isMultiple) {
      if (value) {
        return productsOptions.find((p) => p.value === value);
      }
      return null;
    }
    if (Array.isArray(value)) {
      return productsOptions.filter((p) => (value as string[]).includes(p.value));
    }
    if (value) {
      return productsOptions.filter((p) => p.value === value);
    }
    return [];
  }, [productsOptions, value, isMultiple]);

  const isOptionEqualToValue = useCallback((option: unknown, value: unknown) => {
    if (value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return (option as FilterOption).value === value;
    }

    if (Array.isArray(value)) {
      return (value as FilterOption[])?.some((v: FilterOption) => v.value === (option as FilterOption).value);
    }

    return (option as FilterOption).value === (value as FilterOption).value;
  }, []);

  const handleProductFilterChange = useCallback(
    (_: unknown, value: unknown) => {
      if (!value) {
        onChange(_, undefined);
        return;
      }
      let ids = undefined;
      if (isMultiple) {
        ids = (value as FilterOption[])?.map((v) => v.value);
      } else {
        ids = [(value as FilterOption)?.value];
      }
      const newValue = productsOptions?.filter((p) => ids.includes(p.value));
      onChange(_, newValue);
    },
    [onChange, productsOptions],
  );

  const handleReset = useCallback(() => {
    if (onReset) {
      handleProductFilterChange(undefined, undefined);
    }
  }, [onReset]);

  return (
    <Select
      fullWidth
      multiple={isMultiple}
      checkbox={isMultiple}
      limitTags={1}
      disableCloseOnSelect
      placeholder={t('product_selector.placeholder', { ns: NS })}
      loading={productsLoading}
      loadingText={t('loading')}
      error={error}
      options={productsOptions ?? []}
      onChange={handleProductFilterChange}
      onReset={handleReset}
      value={selectValue}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionDisabled={(option) => filterOptionsFinal.includes((option as FilterOption)?.value)}
      getOptionLabel={(option) => (option as FilterOption)?.label}
      getOptionKey={(option) => (option as FilterOption)?.value}
    />
  );
};

export default ProductSelector;
