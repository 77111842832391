import useAccount from '@/hooks/useAccount';
import PageLayout from '@/layouts/PageLayout';
import { IPageLayoutActions } from '@/layouts/PageLayout/PageLayoutTypes';
import { useConfig } from '@/features/config/useConfig';
import { Stack } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// const ProductPackagesFilters = lazy(() => import('@/features/product-packages/components/ProductPackagesFilters'));
import ProductPackagesFilters from '@/features/productPackages/components/ProductPackagesFilters';
import { ProductPackagesConfigState } from '@/features/productPackages/types';
import { ArchivedParamParameter, PolicyRuleNamespace } from '@/services/sellerApiQuery/model';
import useActiveTabInRoute from '@/hooks/useActiveTabInRoute';
import ProductPackageTable from '@/features/productPackages/components/ProductPackageTable';

const LazyFilterFields = () => {
  return (
    <Suspense fallback={null}>
      <ProductPackagesFilters />
    </Suspense>
  );
};

const ProductPackagesPageWithConfig = () => {
  const { hasPermission } = useAccount();
  const { config, patchConfig } = useConfig<ProductPackagesConfigState>();
  const activeTab = useActiveTabInRoute<ArchivedParamParameter>(['archived'], 'active');

  const navigate = useNavigate();

  const filters = useMemo(() => {
    return {
      products: config.products,
      productCategories: config.productCategories,
      search: config.search,
    };
  }, [config]);

  const { t } = useTranslation(['product-packages']);

  const canCreateNewItem = useMemo(() => {
    return hasPermission(PolicyRuleNamespace.productPackages, 'CreateAppProductPackage');
  }, [hasPermission]);

  const actions: IPageLayoutActions = {
    primary: {
      label: t('header.actions.new_package'),
      Icon: <IconPlus />,
      href: './create',
    },
  };

  const tabs = [
    {
      label: t('tabs.active'),
      value: 'active',
    },
    {
      label: t('tabs.archived'),
      value: 'archived',
    },
  ];

  const handleTabChange = (_: unknown, newValue: string) => {
    const queryParam = new URL(window.location.href).searchParams.toString();
    const basePath = newValue === 'active' ? './' : './archived';
    navigate(queryParam ? `${basePath}?${queryParam}` : basePath);
  };

  const handleSearchChange = (search: string) => {
    patchConfig({ search });
  };

  const handleSearchClear = () => {
    patchConfig({ search: null });
  };

  return (
    <PageLayout
      title={t('page_title')}
      showFilterButton
      showSearchInput
      showFilterActions={false}
      actions={canCreateNewItem ? actions : undefined}
      slots={{
        FilterFields: LazyFilterFields,
      }}
      slotProps={{
        filterFields: {
          filters,
        },
        searchInput: {
          value: filters.search || '',
          onSearch: handleSearchChange,
          onClear: handleSearchClear,
        },
        tab: {
          root: {
            value: activeTab,
            onChange: handleTabChange,
            variant: 'fullWidth',
          },
          tabs,
        },
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <ProductPackageTable
          status={activeTab}
          productIds={filters.products}
          categoryIds={filters.productCategories}
          search={filters.search}
        />
      </Stack>
    </PageLayout>
  );
};

export default ProductPackagesPageWithConfig;
