import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AlertTitle, Grid, Typography } from '@mui/material';

import useAnalytics from '@/hooks/analytics/useAnalytics';
import {
  DirectSaleTransaction,
  DirectSalesApiGetDirectSalePaymentOptionsRequest,
  ErrorObject,
  PaymentMethodCode,
  PaymentOption,
  Sale,
} from '@/services/SellerApi';

import { useSellerApi } from '@/hooks/useSellerApi';
import { copyToClipboard } from '@/utils';

import {
  Alert,
  Alerts,
  CameraInstruction,
  PixCode,
  PixCodeBox,
  QrCode,
  QrCodeContainer,
  Step,
  Steps,
  StepsTitle,
  VerifyingPayment,
} from './styles';
import Loading from '@/components/Loading';
import Button from '@/components/Button';

import { Copy05 } from '@untitled-ui/icons-react';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import usePaymentFingerprint from '@/hooks/usePaymentFingerprints';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import TransactionErrorAlert from '../TransactionErrorAlert';
import { TransactionErrorAlertModalTitle } from '../TransactionErrorAlert/styles';
import useAuth from '@/contexts/Auth/hooks/useAuth';

type Props = {
  saleId: string;
  sale?: Sale;
  onSuccess?: (transaction: DirectSaleTransaction) => void;
  onFail?: (error: ErrorObject) => void;
  onCloseErrorModal?: () => void;
};

const PixPaymentForm = (props: Props) => {
  const { saleId, onCloseErrorModal = () => {}, onSuccess = () => {}, onFail = () => {} } = props;

  const { track } = useAnalytics();
  const { sessionId } = useAuth();

  const { directSalesApi, salesApi, transactionsApi } = useSellerApi();

  const { t } = useTranslation(['payment']);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPaymentOption, setLoadingPaymentOption] = useState<boolean>(true);
  const [paymentOption, setPaymentOption] = useState<PaymentOption | undefined>(undefined);
  const [transaction, setTransaction] = useState<DirectSaleTransaction | undefined>(undefined);
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);
  const [verifyingTransaction, setVerifyingTransaction] = useState<boolean>(false);

  const [transactionError, setTransactionError] = useState<
    'transaction' | 'antifraud' | 'expired' | 'insufficientAmount' | undefined
  >(undefined);

  usePaymentFingerprint(paymentOption ? [paymentOption] : []);

  const getPaymentOptions = async (saleId: string) => {
    setLoadingPaymentOption(true);
    const params: DirectSalesApiGetDirectSalePaymentOptionsRequest = {
      saleId: saleId,
      sessionId,
    };

    try {
      const { data } = await directSalesApi.getDirectSalePaymentOptions(params);

      const option = data.find((option) => {
        return option.paymentMethod.code === PaymentMethodCode.Pix;
      });

      setPaymentOption(option);
    } catch (error) {
      enqueueSnackbar(t('errors.could_not_retrieve_payment_option'), {
        variant: 'error',
      });
    } finally {
      setLoadingPaymentOption(false);
    }
  };

  const createTransaction = async () => {
    setLoading(true);

    try {
      const { data } = await salesApi.transactDirectSale({
        saleId,
        transactionRequest: {
          paymentMethodId: paymentOption?.paymentMethod.id ?? '',
          deviceInfo: {
            language: navigator.language,
            screenHeight: screen.height,
            screenWidth: screen.width,
            timeZoneOffset: new Date().getTimezoneOffset() / 60,
          },
          sessionId,
        },
      });

      setTransaction(data);
    } catch (error) {
      const res = (error as AxiosError).response!.data as ErrorObject;

      if (res.errors?.length) {
        if (res.errors[0].extra?.transactionStatusType === 'insufficientAmount') {
          setTransactionError('insufficientAmount');
          onFail(res);
          return;
        }

        // eslint-disable-next-line
        // @ts-ignore
        if (res.errors[0]?.type === 'invalidStatus') {
          enqueueSnackbar(t('errors.could_not_create_transaction'), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(t('errors.rejected.description_expired'), {
            variant: 'error',
          });
        }
      }

      enqueueSnackbar(t('errors.could_not_create_transaction'), {
        variant: 'error',
      });

      onFail(res);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyCodeToClipboard = (code: string) => {
    copyToClipboard(code);

    setCopiedToClipboard(true);

    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 5000);
  };

  const handleCloseErrorModal = () => {
    setTransactionError(undefined);
    onCloseErrorModal();
  };

  useEffect(() => {
    if (!props.saleId) return;

    getPaymentOptions(props.saleId);

    track('paymentForm');
  }, []);

  useEffect(() => {
    if (paymentOption) {
      createTransaction();
    }
  }, [paymentOption]);

  useEffect(() => {
    if (!transaction) return;

    setVerifyingTransaction(true);

    const interval = setInterval(async () => {
      try {
        const { data } = await transactionsApi.getDirectSaleTransaction({
          transactionId: transaction!.id,
        });

        if (!data.pending) {
          clearInterval(interval);
          setVerifyingTransaction(false);

          onSuccess(data);
        }
      } catch (error) {
        enqueueSnackbar(t('pix_form.could_not_retrieve_transaction'), {
          variant: 'error',
        });
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [transaction]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading || loadingPaymentOption ? <Loading sx={{ my: '70px' }} /> : null}

        {!loadingPaymentOption && !paymentOption ? (
          <Typography variant='h6' sx={{ my: '50px' }}>
            {t('pix_form.sale_without_pix_message')}
          </Typography>
        ) : null}

        {transaction ? (
          <>
            <CameraInstruction>{t('pix_form.camera_instruction')}</CameraInstruction>

            <QrCodeContainer>
              <QrCode src={transaction.pix?.qrCode} />
            </QrCodeContainer>

            {verifyingTransaction ? (
              <>
                <Loading />
                <VerifyingPayment>{t('pix_form.verifying_payment')}</VerifyingPayment>
              </>
            ) : null}

            <PixCodeBox>
              <PixCode>{transaction.pix?.code}</PixCode>
            </PixCodeBox>

            <Button
              fullWidth
              variant='contained'
              onClick={() => handleCopyCodeToClipboard(transaction.pix?.code ?? '')}
              startIcon={<Copy05 />}
              sx={{ marginBottom: '24px' }}
            >
              {copiedToClipboard ? t('pix_form.code_copied') : t('pix_form.copy_code')}
            </Button>

            <StepsTitle>{t('pix_form.steps.title')}</StepsTitle>

            <Steps>
              <Step>{t('pix_form.steps.step_1')}</Step>
              <Step>
                {t('pix_form.steps.step_2', {
                  ...transaction.pix?.receiverInfo,
                })}
              </Step>
            </Steps>

            <AlertTitle>{t('pix_form.attention.title')}</AlertTitle>

            <Alerts>
              <Alert>{t('pix_form.attention.message_1')}</Alert>
              <Alert>{t('pix_form.attention.message_2')}</Alert>
              <Alert>{t('pix_form.attention.message_3')}</Alert>
            </Alerts>
          </>
        ) : null}
      </Grid>

      <ModalOnDesktopDrawerOnMobile
        width={455}
        anchor='bottom'
        isOpen={!!transactionError}
        title={<TransactionErrorAlertModalTitle>{t('errors.rejected.title')}</TransactionErrorAlertModalTitle>}
        onClose={handleCloseErrorModal}
      >
        {transactionError ? <TransactionErrorAlert type={transactionError} onClose={handleCloseErrorModal} /> : null}
      </ModalOnDesktopDrawerOnMobile>
    </Grid>
  );
};

export default PixPaymentForm;
