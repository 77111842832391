import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { X } from '@untitled-ui/icons-react';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useMemo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button';

import BackdropLoading from '@/components/BackdropLoading';

import useErrorHandler from '@/hooks/useErrorHandler';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import DynamicProductWithDiscount from './DynamicProductWithDiscount';

import Input from '@/components/Form/Input';
import { useSchema, ProductPackageFormData } from '@/features/productPackages/components/ProductPackageForm/schema';
import {
  getGetProductPackageQueryKey,
  useGetProductPackage,
  useGetProductPackageProducts,
} from '@/services/sellerApiQuery/api/product-packages/product-packages';
import FormSession from '@/features/agenda/features/availability/components/AvailabilityForm/FormSession';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorObject, ProductPackage, ProductPackageParams } from '@/services/sellerApiQuery/model';
import { ErrorType } from '@/services/sellerApiQuery/mutator/custom-instance';

type Props = {
  productPackageId?: string;
  onSubmit: (values: ProductPackageParams) => void;
  data?: ProductPackage;
  error?: ErrorType<ErrorObject> | null;
  loading?: boolean;
  isSuccess?: boolean;
};

export const ProductPackageForm = (params: Props) => {
  const { productPackageId, onSubmit, data, error, loading = false, isSuccess = false } = params;

  const navigate = useNavigate();
  const { t } = useTranslation(['product-packages'], { keyPrefix: 'form' });
  const { errorHandler, reportError, mapFieldPath } = useErrorHandler();
  const { track } = useAnalytics();
  const queryClient = useQueryClient();

  const { data: productPackage, isLoading: isProductPackage } = useGetProductPackage(
    productPackageId ?? '',
    undefined,
    {
      query: {
        queryKey: getGetProductPackageQueryKey(productPackageId ?? '', undefined),
        enabled: !!productPackageId,
      },
    },
  );

  const isEdit = useMemo(() => !!productPackage, [productPackage]);
  const isLoading = useMemo(() => loading || isProductPackage, [loading, isProductPackage]);

  const { data: products } = useGetProductPackageProducts(productPackage?.id ?? '', undefined);
  const productsWithFormattedDiscount = useMemo(() => {
    if (products) {
      return products
        .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
        .map((product) => ({
          ...product,
          ...(product.discountPercentage
            ? { discountPercentage: product.discountPercentage * 100 }
            : { discountPercentage: 0 }),
        }));
    }
    return [];
  }, [products]);

  const formatItems = (value: string[] | string | null | undefined) => {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (Array.isArray(value)) {
      return value.join('\n');
    }
    return value;
  };

  const productPackageValues = useMemo(() => {
    if (productPackage) {
      return {
        ...productPackage,
        products: productsWithFormattedDiscount ?? [],
        includedItems: formatItems(productPackage?.includedItems),
        notIncludedItems: formatItems(productPackage?.notIncludedItems),
      };
    }
    return undefined;
  }, [productPackage, productsWithFormattedDiscount]);

  const getDefaultValues = () => {
    return {
      description: undefined,
      shortDescription: undefined,
      name: '',
      internalName: undefined,
      includedItems: undefined,
      notIncludedItems: undefined,
      voucherGuidelines: undefined,
      products: [],
    } as Partial<ProductPackageFormData>;
  };

  const schema = useSchema(isEdit);

  const { handleSubmit, formState, control, setValue, setError, watch } = useForm<ProductPackageFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    values: productPackageValues as ProductPackageFormData,
    defaultValues: getDefaultValues(),
    resolver: zodResolver(schema),
  });

  const { errors, isDirty } = formState;
  const formValues = watch();

  const emitError = (errorResponse: unknown) => {
    reportError(errorResponse);
    const { errors } = errorHandler(errorResponse);

    if (errors && Array.isArray(errors)) {
      errors.forEach((error) => {
        if (error.path) {
          const { path, messagePath } = mapFieldPath('products', ['position'], 'productId', error);

          setError(path as keyof ProductPackageFormData, {
            type: 'manual',
            message: t(`error.fields.${messagePath}`),
          });
        } else {
          enqueueSnackbar(isEdit ? t('error.update') : t('error.create'), {
            variant: 'error',
          });
        }
      });
    }
  };

  const submit = (data: ProductPackageFormData) => {
    onSubmit(data);
  };

  const onReplicateDiscount = () => {
    const discountPercentage = formValues!.products![0].discountPercentage;

    const newProducts = formValues!.products!.map((item) => ({
      ...item,
      discountPercentage,
    }));

    setValue('products', newProducts);
  };

  useEffect(() => {
    if (isSuccess) {
      const event = !isEdit
        ? AnalyticsEvents.PRODUCT_PACKAGE_CREATE_SUCCESS
        : AnalyticsEvents.PRODUCT_PACKAGE_EDIT_SUCCESS;

      if (isEdit) {
        queryClient.invalidateQueries({
          queryKey: getGetProductPackageQueryKey(productPackage?.id ?? '', undefined),
        });
      }

      track(event, {
        data: {
          ...data,
        },
      });

      enqueueSnackbar(isEdit ? t('update.success') : t('create.success'), {
        variant: 'success',
      });

      navigate('../productPackages');
    }
  }, [data, isEdit]);

  useEffect(() => {
    if (error) {
      emitError(error);
    }
  }, [error]);

  useEffect(() => {
    if (productsWithFormattedDiscount) {
      setValue('products', productsWithFormattedDiscount as ProductPackageFormData['products']);
    }
  }, [productsWithFormattedDiscount]);

  return (
    <Fragment>
      <Grid container spacing={6} component='form' onSubmit={handleSubmit(submit)}>
        {isLoading ? <BackdropLoading /> : null}

        <Grid item xs={12}>
          <Grid container spacing={6}>
            <FormSession title={t('names')}>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name='name'
                  control={control}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <Input
                      label={t('name')}
                      name={name}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={!!errors[name]}
                      helperText={errors[name]?.message as string}
                      disabled={isEdit}
                      {...rest}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name='internalName'
                  control={control}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <Input
                      label={t('internal_name')}
                      name={name}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={!!errors[name]}
                      helperText={errors[name]?.message as string}
                      disabled={isEdit}
                      {...rest}
                    />
                  )}
                />
              </Grid>
            </FormSession>

            <FormSession title={t('information.label')}>
              <Grid item xs={12}>
                <Controller
                  name='description'
                  control={control}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <Input
                      {...rest}
                      multiline
                      fullWidth
                      minRows={2}
                      maxRows={6}
                      id='description'
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder={t('information.long_description.placeholder')}
                      label={t('information.long_description.label')}
                      error={!!errors[name]}
                      helperText={(errors[name]?.message as string) ?? t('information.long_description.helper_text')}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='shortDescription'
                  control={control}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <Input
                      {...rest}
                      label={t('information.short_description.label')}
                      name={name}
                      fullWidth
                      multiline
                      minRows={2}
                      maxRows={6}
                      id='shortDescription'
                      value={value}
                      onChange={onChange}
                      placeholder={t('information.short_description.placeholder')}
                      error={!!errors.shortDescription}
                      helperText={
                        (errors.shortDescription?.message as string) ?? t('information.short_description.helper_text')
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='includedItems'
                  control={control}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <Input
                      {...rest}
                      label={t('information.included_items.label')}
                      name={name}
                      fullWidth
                      multiline
                      minRows={2}
                      maxRows={6}
                      id='includedItems'
                      value={value}
                      onChange={(event) => {
                        const newValue = formatItems(event.target.value);
                        onChange(newValue);
                      }}
                      placeholder={t('information.included_items.placeholder')}
                      error={!!errors.includedItems}
                      helperText={
                        (errors.includedItems?.message as string) ?? t('information.included_items.helper_text')
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='notIncludedItems'
                  control={control}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <Input
                      {...rest}
                      label={t('information.not_included_items.label')}
                      name={name}
                      fullWidth
                      multiline
                      minRows={2}
                      maxRows={6}
                      id='notIncludedItems'
                      value={value}
                      onChange={(event) => {
                        const newValue = formatItems(event.target.value);
                        onChange(newValue);
                      }}
                      placeholder={t('information.not_included_items.placeholder')}
                      error={!!errors.notIncludedItems}
                      helperText={
                        (errors.notIncludedItems?.message as string) ?? t('information.not_included_items.helper_text')
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='voucherGuidelines'
                  control={control}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <Input
                      {...rest}
                      label={t('information.voucher_guidelines.label')}
                      name={name}
                      fullWidth
                      multiline
                      minRows={2}
                      maxRows={6}
                      id='voucherGuidelines'
                      value={value}
                      onChange={onChange}
                      placeholder={t('information.voucher_guidelines.placeholder')}
                      error={!!errors.voucherGuidelines}
                      helperText={
                        (errors.voucherGuidelines?.message as string) ?? t('information.voucher_guidelines.helper_text')
                      }
                    />
                  )}
                />
              </Grid>
            </FormSession>

            <FormSession title={t('products')}>
              <DynamicProductWithDiscount
                errors={errors}
                control={control}
                formValues={formValues}
                isEdit={isEdit}
                onReplicateDiscount={onReplicateDiscount}
              />
            </FormSession>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} spacing={2}>
            <Grid item>
              <Button
                color='primary'
                variant='outlined'
                disabled={isLoading}
                startIcon={<X />}
                href='../productPackages'
              >
                {t('buttons.cancel')}
              </Button>
            </Grid>

            <Grid item>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={isLoading || !isDirty || !!Object.keys(errors).length}
                startIcon={<IconDeviceFloppy />}
              >
                {t('buttons.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ProductPackageForm;
