/* eslint-disable react-refresh/only-export-components */
import { ReactNode, Suspense } from 'react';
import { LoaderFunctionArgs, RouteObject } from 'react-router-dom';
import { NotifiableError } from '@bugsnag/js';

import Loading from '@/components/Loading';
import ProtectedRoute from '@/routes/ProtectedRoute';
import policyCodeToRule, { PolicyCode } from '@/utils/policyCodeToRule';
import i18n from '@/services/i18n';
import translatePT from '@/features/agenda/locales/pt.json';
import Bugsnag from '@/services/bugsnag';

// const LazyAgendaPage = lazy(() => import('./pages/AgendaPage'));
// const LazySchedulingFormPage = lazy(() => import('./pages/SchedulingFormPage'));
// const LazySchedulingModal = lazy(() => import('./components/SchedulingModal'));
// const LazyAvailabilityModal = lazy(() => import('./components/AvailabilityModal'));
// const LazyAvailabilityFormPage = lazy(() => import('./pages/AvailabilityFormPage'));

import LazyAgendaPage from './pages/AgendaPage';
import LazySchedulingFormPage from './pages/SchedulingFormPage';
import LazySchedulingModal from './components/SchedulingModal';
import LazyAvailabilityModal from './components/AvailabilityModal';
import LazyAvailabilityFormPage from './pages/AvailabilityFormPage';
import loadProductsTranslations from '@/features/products/locales/loadProductsTranslations';
import { getProduct } from '@/services/sellerApiQuery/api/products/products';
import getItemsFromUrl from '@/hooks/getItemsFromUrl';

const SuspenseElement = ({ children }: { children: ReactNode }) => (
  <Suspense fallback={<Loading />}>{children}</Suspense>
);

const buildRouter = (path: string, policies: PolicyCode[], element: JSX.Element) => {
  return {
    path,
    element: (
      <ProtectedRoute
        featureFlag='new-schedulings-page'
        policies={{
          OR: policies.map((policy) => policyCodeToRule(policy)),
        }}
        element={element}
      />
    ),
  };
};

const AGENDA_POLICIES: PolicyCode[] = [
  'schedulings:GetAppAggregatedSchedulings',
  'availabilities:GetAppAggregatedAvailabilities',
];

export const schedulingRouters: RouteObject[] = [
  {
    path: 'agenda',
    loader: async (args: LoaderFunctionArgs<unknown>) => {
      try {
        i18n.addResourceBundle('pt-BR', 'agenda', translatePT);
        loadProductsTranslations();
        const url = new URL(args.request.url);
        return getItemsFromUrl(url, getProduct, 'products');
      } catch (error) {
        Bugsnag.notify(error as NotifiableError);
        return false;
      }
    },
    element: (
      <ProtectedRoute
        featureFlag='new-schedulings-page'
        policies={{
          OR: AGENDA_POLICIES.map((policy) => policyCodeToRule(policy)),
        }}
        element={
          <SuspenseElement>
            <LazyAgendaPage />
          </SuspenseElement>
        }
      />
    ),
    children: [
      {
        path: 'availabilities/:availabilityId',
        element: (
          <ProtectedRoute
            featureFlag='new-schedulings-page'
            element={
              <SuspenseElement>
                <LazyAvailabilityModal isOpen={true} />
              </SuspenseElement>
            }
          />
        ),
      },
      {
        path: 'schedulings/:schedulingId',
        element: (
          <ProtectedRoute
            featureFlag='new-schedulings-page'
            element={
              <SuspenseElement>
                <LazySchedulingModal isOpen={true} />
              </SuspenseElement>
            }
          />
        ),
      },
    ],
  },
  buildRouter(
    'agenda/schedulings/new',
    ['schedulings:CreateProductScheduling'],
    <SuspenseElement>
      <LazySchedulingFormPage />
    </SuspenseElement>,
  ),
  buildRouter(
    'agenda/schedulings/:id/edit',
    ['schedulings:UpdateScheduling'],
    <SuspenseElement>
      <LazySchedulingFormPage />
    </SuspenseElement>,
  ),
  buildRouter(
    'agenda/availabilities/new',
    ['availabilities:CreateAppAvailability'],
    <SuspenseElement>
      <LazyAvailabilityFormPage />
    </SuspenseElement>,
  ),
  buildRouter(
    'agenda/availabilities/:id/edit',
    ['availabilities:UpdateAvailability'],
    <SuspenseElement>
      <LazyAvailabilityFormPage />
    </SuspenseElement>,
  ),
];
