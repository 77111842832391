import { Button, buttonClasses } from '@planne-software/uni/Button';
import { Box, Stack, styled } from '@planne-software/uni/mui/material';

export const StyledHorizontalCalendar = styled(Stack)({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
});

export const StyledVirtualScroll = styled(Stack)({
  overflowX: 'auto',
  overflowY: 'hidden',
  position: 'relative',
  height: '80px',
  width: '100%',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-track': {
    display: 'none',
  },
});

export const StyledArrowButton = styled(Button)(() => ({
  [`&.${buttonClasses.sm}`]: {
    padding: '0.5rem 0.5rem',
  },
  [`&.${buttonClasses.xs}`]: {
    padding: '0.5rem 0.5rem',
    [`& svg`]: {
      width: '1rem',
      height: '1rem',
    },
  },
}));

export const StyledDayCellRender = styled(Box, { shouldForwardProp: (prop) => prop !== 'size' && prop !== 'start' })<{
  size: number;
  start: number;
}>(({ size, start }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: `${size}px`,
  transform: `translateX(${start}px)`,
}));