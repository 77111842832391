import { Stack } from '@mui/material';
import { useMemo, Fragment } from 'react';

import theme from '@/theme';
import { FilterContent } from '@/components/FilterDrawer/styles';
import FilterActions from '@/components/FilterDrawer/FilterActions';
import ProductSelector from '@/features/products/components/ProductSelector';
import { FilterOption } from '@/interfaces/filterContext';
import { useConfig } from '@/features/config/useConfig';
// @TODO: Implement ProductCategoriesSelector in the future
// import ProductCategoriesSelector from '@/features/products/components/ProductCategoriesSelector';
import { ProductPackagesConfigFields, ProductPackagesConfigState } from '@/features/productPackages/types';
import useConfigFilter from '@/hooks/useConfigFilter';
import { Product } from '@/services/sellerApiQuery/model';
import useGetItemsFromRouter from '@/hooks/useGetItemsFromRouter';

const ProductPackagesFilters = () => {
  const { config } = useConfig<ProductPackagesConfigState>();
  const productsFromRouter = useGetItemsFromRouter<Product>();

  const {
    totalOfSelectedFilters,
    totalOfFilters,
    handleApplyFilter,
    handleResetFilters,
    handleCloseFilters,
    selectedFilters,
    handleSelectedFilters,
  } = useConfigFilter<ProductPackagesConfigState, ProductPackagesConfigFields>();

  const productsSelected = useMemo(() => selectedFilters?.products || config?.products, [selectedFilters, config]);

  const handleFilterChange = (key: keyof ProductPackagesConfigFields) => (_: unknown, value: unknown) => {
    const newValue = value as FilterOption[];

    handleSelectedFilters({
      ...selectedFilters,
      [key]: newValue.map((p) => p.value),
    });
  };

  const handlerProductFilterChange = handleFilterChange('products');
  // @TODO: Implement ProductCategoriesSelector in the future
  // const handlerProductCategories = handleFilterChange('productCategories');

  return (
    <Fragment>
      <FilterContent isNewDesign>
        <Stack sx={{ flexDirection: 'column', gap: theme.spacing(2) }}>
          <ProductSelector
            value={productsSelected}
            onChange={handlerProductFilterChange}
            initialState={productsFromRouter}
          />

          {/* @TODO: Implement ProductCategoriesSelector in the future */}
          {/* <ProductCategoriesSelector value={selectedFilters?.productCategories} onChange={handlerProductCategories} /> */}
        </Stack>
      </FilterContent>
      <FilterActions
        handleApplyFilter={() => handleApplyFilter()}
        handleCloseFilters={() => handleCloseFilters()}
        handleResetFilters={() => handleResetFilters()}
        selectedFiltersLength={totalOfSelectedFilters}
        appliedFiltersLength={totalOfFilters}
      />
    </Fragment>
  );
};

export default ProductPackagesFilters;
