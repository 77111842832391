import Button from '@/components/Button';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { useTranslation } from 'react-i18next';
import { IconX, IconCheck } from '@tabler/icons-react';
import { Stack, Typography } from '@mui/material';
import { ProductPackage } from '@/services/sellerApiQuery/model';
import { useDeleteProductPackage } from '@/services/sellerApiQuery/api/product-packages/product-packages';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import BackdropLoading from '@/components/BackdropLoading';
import useBreakpoints from '@/hooks/useBreakpoints';

type Props = {
  isOpen: boolean;
  productPackage?: ProductPackage;
  onClose: () => void;
  onSuccess?: () => void;
};
const DeleteProductPackageDialog = (props: Props) => {
  const { isOpen, productPackage, onClose, onSuccess = () => {} } = props;
  const { t } = useTranslation(['product-packages']);
  const { isMobile } = useBreakpoints();

  const { mutate: deleteProductPackage, isPending, isError, isSuccess } = useDeleteProductPackage();

  const handleSuccess = () => {
    if (productPackage?.id) {
      deleteProductPackage({ productPackageId: productPackage.id });
    }
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('archive_dialog.message.error'), {
        variant: 'error',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
      onClose();
      enqueueSnackbar(t('archive_dialog.message.success'), {
        variant: 'success',
      });
    }
  }, [isSuccess]);

  return (
    <ModalOnDesktopDrawerOnMobile
      isOpen={isOpen}
      onClose={() => onClose()}
      showCloseButton={false}
      title={t('archive_dialog.title')}
      width='31.25rem'
    >
      {isPending ? <BackdropLoading /> : null}
      <Stack flexDirection={{ xs: 'column', sm: 'row' }} justifyContent='flex-end' gap='0.5rem' flexWrap='wrap'>
        <Stack>
          <Typography variant='body1'>{t('archive_dialog.description', { name: productPackage?.name })}</Typography>
        </Stack>
        <Stack flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap='0.5rem'>
          <Button
            variant='outlined'
            color='secondary'
            disabled={isPending}
            onClick={() => onClose()}
            startIcon={<IconX />}
            fullWidth={isMobile}
          >
            {t('archive_dialog.cancel')}
          </Button>

          <Button
            type='button'
            onClick={handleSuccess}
            variant='contained'
            color='primary'
            disabled={isPending}
            startIcon={isMobile ? <IconCheck /> : undefined}
            endIcon={!isMobile ? <IconCheck /> : undefined}
            fullWidth={isMobile}
          >
            {t('archive_dialog.confirm')}
          </Button>
        </Stack>
      </Stack>
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default DeleteProductPackageDialog;
