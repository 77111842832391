import DayCell from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/CalendarSelector/HorizontalCalendar/DayCell';
import { DayCellProps } from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/CalendarSelector/HorizontalCalendar/DayCell/types';
import { Stack, useMediaQuery } from '@planne-software/uni/mui/material';
import { useMemo, useRef, useState, useEffect } from 'react';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import { addDays, subDays, isSameDay } from 'date-fns';
import {
  StyledHorizontalCalendar,
  StyledVirtualScroll,
  StyledArrowButton,
  StyledDayCellRender,
} from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/CalendarSelector/HorizontalCalendar/styles';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import useErrorHandler from '@/hooks/useErrorHandler';

type Page = Date[][] | null;

enum PageDirection {
  BEFORE = 'before',
  AFTER = 'after',
}

type Props = {
  dayRenderer: (props: DayCellProps<Date>) => DayCellProps<Date>;
  value?: Date;
  focusedDate?: Date | null;
  onChange: (date: Date | null) => void;
  onViewChange: (date: Date) => void;
  viewSize?: number;
  onScroll?: (direction: 'forward' | 'backward') => void;
  disablePast?: boolean;
};

/**
 * HorizontalCalendar component renders a horizontally scrollable calendar with day cells and using a virtual scroll.
 *
 * @param {Props} props - The properties for the HorizontalCalendar component.
 * @param {Function} props.dayRenderer - Function to set the properties for each day cell rendered.
 * @param {Date} [props.value=new Date()] - The selected date.
 * @param {Function} props.onChange - Callback function when a date is selected.
 * @param {Function} props.onViewChange - Callback function when the view changes.
 * @param {number} [props.viewSize=11] - The number of days to display in the view.
 * @param {Function} [props.onScroll=() => {}] - Callback function when the calendar is scrolled.
 * @param {boolean} [props.disablePast=true] - Flag to disable past dates.
 * @param {Date} [props.focusedDate] - The date to focus on.
 *
 * @returns {JSX.Element} The rendered HorizontalCalendar component.
 *
 * @component
 *
 * @example
 * <HorizontalCalendar
 *   dayRenderer={customDayRenderer}
 *   value={selectedDate}
 *   onChange={handleDateChange}
 *   onViewChange={handleViewChange}
 *   viewSize={7}
 *   onScroll={handleScroll}
 *   disablePast={false}
 *   focusedDate={new Date()}
 * />
 */
const HorizontalCalendar = (props: Props) => {
  const {
    dayRenderer,
    value = new Date(),
    onChange,
    onViewChange,
    viewSize = 11,
    onScroll = () => {},
    disablePast = false,
    focusedDate,
  } = props;
  const virtualScrollRef = useRef<HTMLDivElement>(null);
  const { reportError } = useErrorHandler();

  const [days, setDays] = useState<DayCellProps<Date>[]>([]);
  const [pages, setPages] = useState<Page>(null);
  const [activePage, setActivePage] = useState<Date[] | null>(null);
  const [oldValue, _] = useState<Date>(value);
  const [focusedDay, setFocusedDay] = useState<Date | null>(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const estimatedSize = useMemo(() => (isMobile ? 45 : 55), [isMobile]);

  const rowVirtualizer = useVirtualizer({
    count: days.length,
    getScrollElement: () => virtualScrollRef.current,
    estimateSize: () => estimatedSize,
    overscan: viewSize,
    getItemKey: (index) => days[index].day.toISOString(),
    horizontal: true,
    onChange: (data) => {
      if (data.scrollDirection) {
        onScroll(data.scrollDirection);
      }
    },
  });

  const isScrollingPending = useMemo(() => rowVirtualizer.isScrolling, [rowVirtualizer.isScrolling]);

  /**
   * Determines if the "before" button should be disabled.
   *
   * @returns {boolean} `true` if the "before" button should be disabled, otherwise `false`.
   *
   * The button is disabled if:
   * - `activePage` is not defined.
   * - The `activePage` is the first page and `disablePast` is `true`.
   *
   * @param {Array} pages - The array of pages.
   * @param {Array} activePage - The currently active page.
   * @param {boolean} disablePast - Flag indicating if past dates should be disabled.
   */
  const isBeforeButtonDisabled = useMemo(() => {
    if (!activePage) return true;
    const activePageIndex = pages?.findIndex((page) => isSameDay(page[0], activePage?.[0]));
    if (activePageIndex === 1 && disablePast) {
      return true;
    }
  }, [pages, activePage, disablePast]);

  /**
   * DayCellRender component is used by react-window to render the horizontal virtual scroll.
   *
   * @param index - The index of the day in the days array.
   * @param isScrolling - A boolean indicating if the list is currently being scrolled.
   * @param style - The style object to be applied to the cell.
   */
  const DayCellRender = ({ index, size, start }: VirtualItem) => {
    const dayProps = days[index];
    return (
      <StyledDayCellRender key={dayProps.day.toISOString()} size={size} start={start}>
        <DayCell {...dayRenderer(dayProps)} />
      </StyledDayCellRender>
    );
  };

  /**
   * Generates properties for a day cell in the calendar.
   *
   * @param {Date} day - The date for which to generate properties.
   * @returns {DayCellProps<Date>} The properties for the day cell.
   *
   * @remarks
   * - The function checks if the given day is today and sets the color accordingly.
   * - If `disablePast` is true, days before today are marked as disabled.
   * - The `onDaySelect` function is set to call `onChange` with the selected date.
   */
  const getDayProps = (day: Date): DayCellProps<Date> => {
    const today = new Date();
    const isToday = isSameDay(day, today);
    const disabled = disablePast && day < today;
    return {
      day,
      disabled,
      onDaySelect: (date: Date) => onChange(date),
      color: isToday ? 'error' : 'neutral',
      outsideCurrentMonth: false,
      isFirstVisibleCell: false,
      isLastVisibleCell: false,
    };
  };

  const addDaysBefore = (pointer: Date, totalOfNewDays: number, withPointer = false) => {
    const newDays = [];
    for (let i = totalOfNewDays; i > 0; i--) {
      const subValue = withPointer ? i : i - 1;
      newDays.push(getDayProps(subDays(pointer, subValue)));
    }
    return newDays;
  };

  const addDaysAfter = (pointer: Date, totalOfNewDays: number, withPointer = false) => {
    const newDays = [];
    for (let i = 0; i < totalOfNewDays; i++) {
      const sumValue = withPointer ? i : i + 1;
      newDays.push(getDayProps(addDays(pointer, sumValue)));
    }
    return newDays;
  };

  /**
   * Creates a new page of dates either before or after the current page.
   *
   * @param direction - The direction to create the new page, either `PageDirection.BEFORE` or `PageDirection.AFTER`.
   * @param currentPage - The current page of dates, represented as an array with the first and last date of the page.
   * @returns A new array of dates representing the new page, with the first and last date of the new page.
   */
  const createAPageBeforeOrAfter = (direction: PageDirection, currentPage: Date[]) => {
    if (currentPage) {
      const [firstDay, lastDay] = currentPage;
      if (direction === PageDirection.BEFORE) {
        const newFirstDay = subDays(firstDay, viewSize);
        const newLastDay = subDays(firstDay, 1);
        return [newFirstDay, newLastDay];
      } else {
        const newFirstDay = addDays(lastDay, 1);
        const newLastDay = addDays(lastDay, viewSize);
        return [newFirstDay, newLastDay];
      }
    }
  };

  /**
   * Adds days to the calendar based on the given page.
   *
   * This function checks if there are days after the last day of the given page
   * and before the first day of the given page. If not, it adds the necessary days
   * to the calendar and updates the pages accordingly.
   *
   * @param page - An array of two Date objects representing the first and last day of the current page.
   */
  const addDaysBasedOnAPage = (page: Date[]) => {
    const [firstDay, lastDay] = page;
    const nextDayIndex = days.findIndex((day) => isSameDay(day.day, addDays(lastDay, 1)));
    if (nextDayIndex === -1) {
      const newDays = addDaysAfter(lastDay, viewSize);
      const newPage = createAPageBeforeOrAfter(PageDirection.AFTER, page);
      setDays((prevDays) => [...prevDays, ...newDays]);
      if (newPage) {
        setPages((prevPages) => (prevPages ? [...prevPages, newPage] : [newPage]));
      }
    }

    const prevDayIndex = days.findIndex((day) => isSameDay(day.day, subDays(firstDay, 1)));
    if (prevDayIndex === -1) {
      const newDays = addDaysBefore(firstDay, viewSize, true);
      const newPage = createAPageBeforeOrAfter(PageDirection.BEFORE, page);
      setDays((prevDays) => [...newDays, ...prevDays]);
      setPages((prevPages) => (newPage ? [newPage, ...(prevPages || [])] : prevPages));
    }
  };

  const scrollToView = (direction: 'next' | 'prev') => {
    if (virtualScrollRef.current && pages && activePage) {
      const currentPageIndex = pages.findIndex((page) => isSameDay(page[0], activePage[0]));
      if (currentPageIndex !== -1) {
        const targetPage = direction === 'next' ? pages[currentPageIndex + 1] : pages[currentPageIndex - 1];
        if (targetPage) {
          setActivePage(targetPage);
        }
      }
    }
  };

  /**
   * Checks if a given day exists within a specified page range.
   *
   * @param day - The date to check.
   * @param page - An array containing two dates, the first and last day of the page range.
   * @returns `true` if the day is within the page range, `false` otherwise.
   */
  const dayExistsInPage = (day: Date, page: Date[]) => {
    const [firstDay, lastDay] = page;
    return isSameDay(day, firstDay) || (day > firstDay && day < lastDay) || isSameDay(day, lastDay);
  };

  /**
   * Finds or creates a page that contains the specified day.
   *
   * @param {Date} day - The date to find or create a page for.
   * @returns {Page} - The page that contains the specified day.
   *
   * This function first checks if the day exists in any of the current pages.
   * If it does, it returns that page. If not, it creates new pages until the
   * day is included in one of them. The new pages are created either before or
   * after the current pages, depending on the position of the day relative to
   * the existing pages.
   *
   * The function updates the state with the new pages and days.
   */
  const findOrCreateDayPage = (day: Date) => {
    if (pages && day) {
      const page = pages.find((p) => dayExistsInPage(day, p));
      if (page) {
        return page;
      }
    }

    const newPages = [...(pages || [])];
    const newDays = [...days];
    let currentPage = newPages[newPages.length - 1];
    let direction = PageDirection.AFTER;

    if (day < currentPage[0]) {
      currentPage = newPages[0];
      direction = PageDirection.BEFORE;
    }

    // Prevent infinite loop
    const maxIterations = 100;
    let iterations = 0;

    while (!dayExistsInPage(day, currentPage) && iterations < maxIterations) {
      const newPage = createAPageBeforeOrAfter(direction, currentPage);
      if (newPage) {
        const newPageDays =
          direction === PageDirection.AFTER
            ? addDaysAfter(currentPage[1], viewSize)
            : addDaysBefore(currentPage[0], viewSize, true);
        if (direction === PageDirection.AFTER) {
          newPages.push(newPage);
          newDays.push(...newPageDays);
        } else {
          newPages.unshift(newPage);
          newDays.unshift(...newPageDays);
        }
        currentPage = newPage;
      }
      iterations++;
    }

    // check if currentPage has the day
    if (!dayExistsInPage(day, currentPage)) {
      const message = 'Failed to find or create day page.';
      reportError(message);
      console.error(message);
    }

    if (iterations >= maxIterations) {
      const message = 'Reached maximum iteration limit while finding or creating day page.';
      reportError(message);
      console.error(message);
    }

    setPages(newPages);
    setDays(newDays);
    return currentPage;
  };

  /**
   * Finds the page corresponding to the given day and sets it as the active page.
   * If the page does not exist, it creates a new one.
   *
   * @param day - The date for which to find or create the page.
   */
  const findADayPageAndFocus = (day: Date) => {
    if (!day) return;
    if (pages && days) {
      const dayPage = findOrCreateDayPage(day);
      if (dayPage) {
        setActivePage(dayPage);
      }
    }
  };

  /**
   * Creates the pages to view in the calendar.
   *
   * This function initializes the calendar view by creating pages and days based on the provided `oldValue`.
   * It generates the initial page and additional pages before and after the initial page if necessary.
   * The days for each page are also generated and set in the state.
   *
   * Preconditions:
   * - `oldValue` must be defined.
   * - `days` array must be empty.
   * - `pages` must be null.
   *
   * Postconditions:
   * - `days` array will be populated with the days to be displayed.
   * - `pages` array will be populated with the pages to be displayed.
   * - `activePage` will be set to the initial page.
   *
   * @returns {void}
   */
  const createPagesToView = () => {
    if (oldValue && days.length === 0 && pages === null) {
      const firstDay = oldValue;
      const newDays = [];
      const daysAfter = addDaysAfter(firstDay, viewSize, true);
      const newPage = [firstDay, daysAfter[daysAfter.length - 1].day];
      const newPages = [newPage];
      newDays.push(...daysAfter);

      const pageBefore = createAPageBeforeOrAfter(PageDirection.BEFORE, newPage);
      const pageBeforeDays = pageBefore ? addDaysBefore(newPage[0], viewSize, true) : [];
      const pageAfter = createAPageBeforeOrAfter(PageDirection.AFTER, newPage);
      const pageAfterDays = pageAfter ? addDaysAfter(newPage[1], viewSize) : [];
      if (pageBefore) {
        newPages.unshift(pageBefore);
        newDays.unshift(...pageBeforeDays);
      }
      if (pageAfter) {
        newPages.push(pageAfter);
        newDays.push(...pageAfterDays);
      }

      setDays(newDays);
      setPages(newPages);
      setActivePage(newPage);
    }
  };

  useEffect(() => {
    if (activePage) {
      const [firstDay] = activePage;

      onViewChange(firstDay);

      if (!isScrollingPending) {
        addDaysBasedOnAPage(activePage);
      }
      if (!isScrollingPending && virtualScrollRef.current) {
        const firstDayIndex = days.findIndex((day) => isSameDay(day.day, firstDay));

        if (firstDayIndex > 0) {
          rowVirtualizer.scrollToOffset(firstDayIndex * estimatedSize);
        }
      }
    }
  }, [activePage, days, viewSize, isScrollingPending, virtualScrollRef.current, estimatedSize]);

  useEffect(() => {
    createPagesToView();
  }, [oldValue, viewSize, days, pages, virtualScrollRef.current]);

  useEffect(() => {
    if (focusedDay) {
      findADayPageAndFocus(focusedDay);
      setFocusedDay(null);
    }
  }, [focusedDay]);

  useEffect(() => {
    if (focusedDate) {
      setFocusedDay(focusedDate);
    }
  }, [focusedDate]);

  return (
    <Stack width={isMobile ? '336px' : '100%'}>
      <StyledHorizontalCalendar>
        <StyledArrowButton
          size={isMobile ? 'xs' : 'sm'}
          color='neutral'
          variant='text'
          type='button'
          disabled={!!isBeforeButtonDisabled}
          onClick={() => scrollToView('prev')}
        >
          <IconChevronLeft />
        </StyledArrowButton>

        <StyledVirtualScroll ref={virtualScrollRef}>
          {rowVirtualizer.getVirtualItems().map(({ key, ...rest }) => (
            <DayCellRender key={key} {...rest} />
          ))}
        </StyledVirtualScroll>

        <StyledArrowButton
          size={isMobile ? 'xs' : 'sm'}
          color='neutral'
          variant='text'
          type='button'
          onClick={() => scrollToView('next')}
        >
          <IconChevronRight />
        </StyledArrowButton>
      </StyledHorizontalCalendar>
    </Stack>
  );
};

export default HorizontalCalendar;
