import { createAppProductPackageBody, updateProductPackageBody } from '@/services/sellerApiQuery/zod/planneSellerAPI';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
export type ProductPackageFormData = Omit<
  z.infer<typeof createAppProductPackageBody | typeof updateProductPackageBody>,
  'includeItems' | 'notIncludeItems'
> & {
  includedItems?: string;
  notIncludedItems?: string;
};

const superRefine = (data: ProductPackageFormData, ctx: z.RefinementCtx, t: TFunction) => {
  data.products.forEach((product, index) => {
    if (product.discountPercentage < 0 || product.discountPercentage > 100) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['products', index, 'discountPercentage'],
        message: t('error.fields.discountPercentage.range'),
      });
    }
  });
  if (data.products.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['products'],
      message: t('error.fields.products.required'),
    });
  }

  if (data.name.length > 255) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['name'],
      message: t('error.fields.name.maxLength'),
    });
  }

  if ((data.internalName?.length ?? 0) > 255) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['internalName'],
      message: t('error.fields.internalName.maxLength'),
    });
  }
};

export const useSchema = (isEdit: boolean) => {
  const schema = isEdit ? updateProductPackageBody : createAppProductPackageBody;
  const { t } = useTranslation(['product-packages'], { keyPrefix: 'form' });

  return schema
    .omit({
      includedItems: true,
      notIncludedItems: true,
    })
    .extend({
      includedItems: z.string().optional(),
      notIncludedItems: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      superRefine(data as ProductPackageFormData, ctx, t);
    })
    .transform((data: unknown) => {
      const newData = data as ProductPackageFormData;

      // @TODO: find a better way to parse the items getting empty lines
      const parseItems = (items?: string) =>
        items
          ?.split('\n')
          .map((item: string) => item.trim())
          .filter((item: string) => item.length > 0);

      const includedItems = parseItems(newData.includedItems);
      const notIncludedItems = parseItems(newData.notIncludedItems);

      newData.products = newData.products.map((product, index) => ({
        ...product,
        discountPercentage: product.discountPercentage / 100,
        position: index,
      }));

      return {
        ...newData,
        includedItems: includedItems ?? undefined,
        notIncludedItems: notIncludedItems ?? undefined,
      };
    });
};
