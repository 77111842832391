import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Grid } from '@mui/material';

import useAccount from '@/hooks/useAccount';
import EmptyPage from '@/components/EmptyPage';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useSellerApi } from '@/hooks/useSellerApi';
import CouponTable from '@/features/coupons/components/CouponList/CouponTable';
import CouponEmptyPageIcon from '@/assets/Icons/coupon-empty-page-icon.svg';
import { Coupon, CouponsApiGetAppCouponsRequest } from '@/services/SellerApi';
import useFilter from '@/hooks/useFilter';

type Props = {
  currentStatus?: CouponsApiGetAppCouponsRequest['filter'];
  belongsToPartnership?: boolean;
};

const initialPaginationModel = { page: 0, pageSize: 5 };

export const CouponList = (props: Props) => {
  const { currentStatus, belongsToPartnership = false } = props;

  const { search, appliedFilters, isFiltersLoaded } = useFilter();

  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { couponsApi } = useSellerApi();
  const { selectedAccount } = useAccount();
  const { t } = useTranslation(['coupons']);
  const [loading, setLoading] = useState(true);
  const [totalCoupons, setTotalCoupons] = useState(0);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [paginationModel, setPaginationModel] = useState(initialPaginationModel);
  const [belongsToPartnershipPaginationModel, setBelongsToPartnershipPaginationModel] =
    useState(initialPaginationModel);

  const isArchivedCouponList = currentStatus === 'archived';

  const pageModel = useMemo(
    () => (belongsToPartnership ? belongsToPartnershipPaginationModel : paginationModel),
    [belongsToPartnership, belongsToPartnershipPaginationModel, paginationModel],
  );

  const loadCoupons = async () => {
    const payload: CouponsApiGetAppCouponsRequest = {
      include: ['usage', 'products', 'partnership', 'partnership.agent'],
      ...(search ? { search } : {}),
      limit: pageModel.pageSize,
      appId: selectedAccount?.appId ?? '',
      offset: pageModel.page * pageModel.pageSize,
      ...(currentStatus ? { filter: currentStatus } : {}),
      ...appliedFilters,
      belongsToPartnership,
    };

    try {
      setLoading(true);
      const { data, headers } = await couponsApi.getAppCoupons(payload);

      setCoupons(data);
      setTotalCoupons(parseInt(headers['x-pagination-total-count'] ?? '0'));
      track('coupons/retrieveRequestRefundSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('list.could_not_load_coupons'), { variant: 'error' });
      track('coupons/retrieveRequestRefundFail', { payload });
    } finally {
      setLoading(false);
    }
  };

  const getEmptyPageDescription = () => {
    if (search) {
      return t('empty_page.filter.search');
    }

    if (Object.keys(appliedFilters).length) {
      return t('empty_page.filter.applied');
    }

    if (belongsToPartnership) {
      return t('empty_page.partnership_description');
    }

    return isArchivedCouponList ? t('empty_page.archived_description') : t('empty_page.description');
  };

  const handleChangePage = (
    pageModel: SetStateAction<{
      page: number;
      pageSize: number;
    }>,
  ) => {
    if (belongsToPartnership) {
      setBelongsToPartnershipPaginationModel((prev) => ({ ...prev, ...pageModel }));
    } else {
      setPaginationModel((prev) => ({ ...prev, ...pageModel }));
    }
  };

  useEffect(() => {
    if (selectedAccount && isFiltersLoaded) {
      loadCoupons();
    }
  }, [pageModel, search, selectedAccount, appliedFilters, belongsToPartnership, isFiltersLoaded]);

  return (
    <Grid container paddingX={{ xs: 0, sm: 4, md: 6 }}>
      {coupons.length ? (
        <Grid item xs={12}>
          <CouponTable
            coupons={coupons}
            loading={loading}
            setLoading={setLoading}
            totalCoupons={totalCoupons}
            paginationModel={pageModel}
            setPaginationModel={handleChangePage}
            onRefresh={loadCoupons}
            currentStatus={currentStatus}
            belongsToPartnership={belongsToPartnership}
          />
        </Grid>
      ) : null}

      {!coupons.length && !loading ? (
        <Grid item xs={12} alignContent='center' justifyContent='center' mt={6}>
          <EmptyPage
            description={getEmptyPageDescription()}
            buttonText={isArchivedCouponList || belongsToPartnership ? '' : t('empty_page.button')}
            onClick={() => navigate('create')}
            Icon={<img src={CouponEmptyPageIcon} />}
            showCicleBelowIcon={false}
          />
        </Grid>
      ) : null}

      {!coupons.length && loading ? (
        <Grid item xs={12} textAlign='center'>
          <Box display='flex' alignItems='center' justifyContent='center' height='60vh' width='100%'>
            <CircularProgress color='primary' />
          </Box>
        </Grid>
      ) : null}

      <Outlet context={{ refreshCouponList: loadCoupons }} />
    </Grid>
  );
};
