import { useLoaderData } from 'react-router-dom';
import { useMemo } from 'react';

const useGetItemsFromRouter = <T>(): T[] => {
  const routerData = useLoaderData();
  const items = useMemo(() => {
    return routerData as T[];
  }, [routerData]);

  if (!items) {
    return [];
  }

  if (!Array.isArray(items)) {
    return [];
  }

  return [];
};

export default useGetItemsFromRouter;
