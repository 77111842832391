import { Box, Divider, IconButton, Paper } from '@mui/material';
import { SearchMd, XClose } from '@untitled-ui/icons-react';
import { ChangeEventHandler, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from './styles';

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onSearch: (value?: string) => void;
  onClear?: () => void;
};

const SearchInput = (props: Props) => {
  const { t } = useTranslation(['ui']);

  const { placeholder = t('searchInput.placeholder'), onChange, onSearch, onClear } = props;

  const [value, setValue] = useState<string | undefined>(props.value);

  const handleSearch = () => {
    if (value === undefined) {
      return;
    }
    const trimmedSearch = value.trim();
    onSearch(trimmedSearch);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Paper elevation={0} sx={{ borderRadius: '4px', overflow: 'hidden', flex: 1 }}>
      <Input
        fullWidth
        value={value ?? ''}
        autoComplete='cc-csc'
        onChange={handleOnChange}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
        InputProps={{
          endAdornment: (
            <Box display='flex' alignItems='center' gap={1}>
              {value ? (
                <IconButton onClick={onClear}>
                  <XClose />
                </IconButton>
              ) : undefined}

              <Box display='flex' alignItems='center' gap={1}>
                <Divider sx={{ height: 28 }} orientation='vertical' />

                <IconButton onClick={() => handleSearch()} {...(value ? { color: 'primary' } : {})}>
                  <SearchMd />
                </IconButton>
              </Box>
            </Box>
          ),
        }}
      />
    </Paper>
  );
};

export default SearchInput;
