import { CancelOutlined } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  Checkbox,
} from '@mui/material';
import { forwardRef, HTMLAttributes, Key, Ref, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Input, { InputProps } from '@/components/Form/Input';
import { colors } from '@/theme';

export interface SelectOptions extends Record<string, unknown> {
  label: string;
  value: string;
}

type Props = Omit<AutocompleteProps<unknown, boolean, boolean, boolean>, 'renderInput'> &
  Omit<InputProps, 'onChange'> & {
    checkbox?: boolean;
    selectAllOption?: boolean;
  };

export const Select = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const {
    name,
    id = name,
    options,
    fullWidth,
    error,
    tooltipProps,
    onChange,
    helperText,
    value,
    loading,
    loadingText,
    openOnFocus = true,
    getOptionLabel,
    label,
    checkbox = false,
    selectAllOption = false,
    multiple = selectAllOption, // turning multiple on by default if there is a select all option
    placeholder,
    renderOption,
    required,
    InputProps,
    ...rest
  } = props;

  const { t } = useTranslation(['ui']);

  const SELECT_ALL = 'select-all';

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    selectedOptions: unknown,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'selectOption') {
      const hasSelectAll = (selectedOptions as SelectOptions[])?.find((option) => option.value === SELECT_ALL);

      if (hasSelectAll && (selectedOptions as SelectOptions[]).length > options.length) {
        return onChange?.(event, [], reason);
      }

      if (hasSelectAll) {
        return onChange?.(event, options, reason);
      }

      return onChange?.(event, selectedOptions, reason);
    }

    if (reason === 'removeOption') {
      return onChange?.(event, selectedOptions, reason);
    }

    if (reason === 'clear') {
      return onChange?.(event, [], reason);
    }
  };

  const customRenderOptions =
    checkbox && !renderOption
      ? (
          { key, ...rest }: HTMLAttributes<HTMLLIElement> & { key?: Key | null },
          option: unknown,
          { selected }: AutocompleteRenderOptionState,
        ) => (
          <li key={key || (option as SelectOptions).value} {...rest}>
            <Checkbox
              checked={
                (option as SelectOptions).value === SELECT_ALL && options.length === (value as Array<unknown>)?.length
                  ? true
                  : selected
              }
              style={{ marginRight: 8 }}
              indeterminate={
                options.length > (value as Array<unknown>)?.length &&
                (value as Array<unknown>).length &&
                (option as SelectOptions).value === SELECT_ALL
                  ? true
                  : false
              }
            />
            {getOptionLabel?.(option) || (option as SelectOptions).label}
          </li>
        )
      : renderOption;

  const customOptions =
    checkbox && selectAllOption ? [{ label: t('select_all.label'), value: SELECT_ALL }, ...options] : options;

  const customOnChange = checkbox ? handleChange : onChange;

  return (
    <Autocomplete
      id={id}
      ref={ref}
      openOnFocus={openOnFocus}
      options={customOptions}
      fullWidth={fullWidth}
      multiple={multiple}
      loading={loading}
      loadingText={loadingText}
      getOptionLabel={getOptionLabel}
      popupIcon={<KeyboardArrowDownIcon />}
      sx={{ '& .MuiInputBase-root': { paddingTop: '3px !important', paddingBottom: '2px !important' } }}
      {...rest}
      aria-required={required}
      value={value}
      onChange={customOnChange}
      renderInput={(params) => (
        <Input
          {...params}
          helperText={helperText}
          fullWidth={fullWidth}
          error={error}
          label={label}
          tooltipProps={tooltipProps}
          placeholder={placeholder}
          required={required}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
          }}
        />
      )}
      renderOption={customRenderOptions}
      ChipProps={{
        size: 'medium',
        color: 'primary',
        variant: 'outlined',
        deleteIcon: <CancelOutlined />,
        sx: {
          background: colors.blue[100],
        },
      }}
      slotProps={{
        popper: {
          sx: {
            zIndex: 9999,
          },
        },
        ...rest.slotProps,
      }}
    />
  );
});

export type { Props as SelectProps };

export default Select;
