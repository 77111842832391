import useAccount from '@/hooks/useAccount';
import { useGetAppProductPackages } from '@/services/sellerApiQuery/api/product-packages/product-packages';
import { ArchivedParamParameter, ProductPackage } from '@/services/sellerApiQuery/model';
import { useState, useMemo, useCallback, Fragment, useEffect } from 'react';
import Table, { TableColDef } from '@/components/Table';
import { GridRowParams, GridActionsCellItem, GridPaginationModel, GridValueGetterParams } from '@mui/x-data-grid';
import ExtendedGridActionsCellItem from '@/components/ExtendedGridActionsCellItem';
import { Edit05, FileX03 } from '@untitled-ui/icons-react';
import { Link, Grid } from '@mui/material';
import { format } from 'date-fns';
import DeleteProductPackageDialog from '@/features/productPackages/components/DeleteProductPackageDialog';
import { useTranslation } from 'react-i18next';
import { initialPaginationState } from '@/utils';
import { AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';

type Props = {
  status?: ArchivedParamParameter;
  productIds?: string[];
  categoryIds?: string[];
  search?: string | null;
};

const ProductPackageTable = (props: Props) => {
  const { status = 'active', productIds, categoryIds, search } = props;
  const { hasPermission, selectedAccount } = useAccount();
  const [removingPackage, setRemovingPackage] = useState<ProductPackage | null>(null);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(initialPaginationState);
  const [totalOfItens, setTotalOfItens] = useState(0);
  const [packages, setPackages] = useState<ProductPackage[]>([]);
  const { t } = useTranslation(['product-packages']);
  const { track } = useAnalytics();

  const pageOffset = useMemo(() => {
    const newOffset = paginationModel.page * paginationModel.pageSize;
    return newOffset < 0 ? 0 : newOffset;
  }, [paginationModel]);

  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetAppProductPackages(
    selectedAccount?.appId || '',
    {
      filter: status,
      ...(productIds && productIds.length > 0 ? { productIds } : {}),
      ...(categoryIds && categoryIds.length > 0 ? { categoryIds } : {}),
      ...(search && search.length > 0 ? { search } : {}),
      limit: paginationModel.pageSize,
      offset: pageOffset,
    },
    {
      request: {
        returnResponse: true,
      },
    },
  );

  const isArchived = useMemo(() => status === 'archived', [status]);
  const canEdit = useMemo(
    () =>
      hasPermission('productPackages', 'UpdateProductPackage') &&
      hasPermission('productPackageProducts', 'GetProductPackageProducts'),
    [hasPermission],
  );
  const canDelete = useMemo(() => hasPermission('productPackages', 'DeleteProductPackage'), [hasPermission]);
  const noActionPermissions = useMemo(() => !canEdit && !canDelete, [hasPermission]);
  const getEditUrl = useCallback(
    (id: string) => `/${selectedAccount?.app?.code}/productPackages/${id}/edit`,
    [selectedAccount],
  );

  const handleRemove = async (value: ProductPackage) => {
    setRemovingPackage(value);
  };

  const getActions = (productPackage: ProductPackage) => [
    ...(canEdit && !isArchived
      ? [
          <ExtendedGridActionsCellItem
            icon={<Edit05 />}
            key='coupon-edit'
            label={t('table.actions.edit')}
            href={getEditUrl(productPackage.id)}
            showInMenu
            component={Link}
          />,
        ]
      : []),
    ...(canDelete && !isArchived
      ? [
          <GridActionsCellItem
            icon={<FileX03 />}
            key='coupon-delete'
            onClick={() => handleRemove(productPackage)}
            label={t('table.actions.archive')}
            showInMenu
          />,
        ]
      : []),
  ];

  const columns: TableColDef[] = [
    {
      field: 'name',
      enableOnMobile: true,
      headerName: t('table.columns.name'),
      flex: 1,
    },
    ...(isArchived
      ? [
          {
            field: 'deletedAt',
            enableOnMobile: true,
            headerName: t('table.columns.archived_at'),
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.deletedAt ? format(new Date(params.row.deletedAt), 'dd/MM/yyyy') : '-',
          },
        ]
      : []),
    ...(!noActionPermissions && !isArchived
      ? [
          {
            field: 'actions',
            headerName: t('table.columns.actions'),
            type: 'actions',
            sortable: false,
            width: 60,
            getActions: (params: GridRowParams) => getActions(params.row),
          } as TableColDef,
        ]
      : []),
  ];

  useEffect(() => {
    if (response && !Array.isArray(response) && 'headers' in response) {
      const data = response as unknown as AxiosResponse<ProductPackage[]>;
      setTotalOfItens(parseInt(data.headers['x-pagination-total-count'] ?? '0'));

      if (data.data) {
        setPackages(data.data);
      }
    }
  }, [response]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('error.fetch'), { variant: 'error' });
      track(AnalyticsEvents.PRODUCT_PACKAGE_LIST_FAIL, { error: error });
      bugsnag.notify(error as NotifiableError);
    }
  }, [isError, error]);

  return (
    <Fragment>
      <Grid item xs={12} paddingX={{ xs: 0, md: 2 }}>
        <Table
          rows={packages ?? []}
          columns={columns}
          paginationModel={paginationModel}
          paginationMode='server'
          disableColumnMenu
          loading={isLoading}
          rowCount={totalOfItens}
          onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
          /* @TODO: Implement this on view task
        onMobileClick={({ row }) => {
          if (hasPermission('productPackages', 'GetAppProductPackages')) {
            navigate(row.id);
          }
        }} */
        />
      </Grid>

      <DeleteProductPackageDialog
        isOpen={!!removingPackage}
        productPackage={removingPackage ?? undefined}
        onClose={() => setRemovingPackage(null)}
        onSuccess={() => {
          refetch();
        }}
      />
    </Fragment>
  );
};

export default ProductPackageTable;
