import {
  IconAdjustments,
  IconBell,
  IconBoxPadding,
  IconBuildingBank,
  IconBuildingStore,
  IconCalendar,
  IconCalendarMonth,
  IconCalendarWeek,
  IconCash,
  IconChartPie,
  IconClipboard,
  IconClipboardList,
  IconCoin,
  IconCoins,
  IconCreditCard,
  IconCreditCardPay,
  IconEdit,
  IconFileCheck,
  IconFileExport,
  IconFileInvoice,
  IconFilePlus,
  IconFileText,
  IconHelp,
  IconLayoutGrid,
  IconLink,
  IconPackage,
  IconPackages,
  IconPlugConnected,
  IconRefresh,
  IconSettings,
  IconShoppingBag,
  IconShoppingCartCheck,
  IconShoppingCartPin,
  IconShoppingCartShare,
  IconSwitchHorizontal,
  IconTemplate,
  IconTicket,
  IconTool,
  IconTruckLoading,
  IconUnlink,
  IconUser,
  IconUserHexagon,
  IconUsersGroup,
  IconUserShare,
  IconWorldCode,
  IconWorldWww,
} from '@tabler/icons-react';
import { ReactNode } from 'react';

import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import { AppPolicyRules } from '@/interfaces/rules';

/**
 * @deprecated
 * Use AppPolicyRules instead. Set a object with AND or OR key with an array of ApiPolicyRule
 */
export interface MenuItemPolicyProps {
  namespace: string;
  action: string;
}

interface CommonMenuItemProps {
  type: string;
  text: string;
  code: string;
  icon?: ReactNode;
  to?: string;
  track?: AnalyticsEvents;
  target?: string;
  policies?: MenuItemPolicyProps[] | AppPolicyRules;
}

export interface MenuItemProps extends CommonMenuItemProps {
  featureFlag?: string;
  showToAgent?: boolean;
}

export interface NestedMenuItemProps extends CommonMenuItemProps {
  children?: MenuItemProps[];
}

export const mainMenuStructure: NestedMenuItemProps[] = [
  {
    type: 'nested',
    text: 'Inventário',
    code: 'inventory',
    icon: <IconPackages />,
    children: [
      {
        icon: <IconPackage />,
        type: 'item',
        text: 'Produtos',
        code: 'products',
        to: 'products',
        policies: {
          AND: [
            {
              namespace: 'products',
              action: 'GetAppProducts',
              code: 'products:GetAppProducts',
            },
            {
              namespace: 'products',
              action: 'GetProduct',
              code: 'products:GetProduct',
            },
          ],
        },
      },
      {
        type: 'item',
        text: 'Categorias',
        code: 'categories',
        icon: <IconLayoutGrid />,
        to: 'product-categories',
        policies: [{ namespace: 'productCategories', action: 'GetProductCategories' }],
      },
      {
        type: 'item',
        text: 'Mapas de Lugares',
        code: 'seat-maps',
        to: 'seat-maps',
        policies: [{ namespace: 'seatMaps', action: 'GetAppSeatMaps' }],
        featureFlag: 'seat_maps',
        icon: <IconBoxPadding />,
      },
      {
        type: 'item',
        text: 'Pacotes de produtos',
        code: 'packages',
        icon: <IconPackages />,
        to: 'productPackages',
        featureFlag: 'PR-646-product-packages',
        policies: [{ namespace: 'productPackages', action: 'GetAppProductPackages' }],
      },
    ],
  },
  {
    type: 'nested',
    text: 'Operações',
    code: 'operations',
    icon: <IconClipboard />,
    children: [
      {
        icon: <IconCalendarMonth />,
        type: 'item',
        text: 'Agendamento',
        code: 'schedulings',
        to: 'schedulings',
        featureFlag: 'show_old_schedulings_availabilities_pages',
        policies: [{ namespace: 'schedulings', action: 'GetAppSchedulings' }],
      },
      {
        icon: <IconCalendarMonth />,
        type: 'item',
        text: 'Agenda',
        code: 'agenda',
        to: 'agenda',
        featureFlag: 'new-schedulings-page',
        policies: {
          OR: [
            {
              namespace: 'schedulings',
              action: 'GetAppAggregatedSchedulings',
              code: 'schedulings:GetAppAggregatedSchedulings',
            },
            {
              namespace: 'availabilities',
              action: 'GetAppAggregatedAvailabilities',
              code: 'availabilities:GetAppAggregatedAvailabilities',
            },
          ],
        },
      },
      {
        icon: <IconCalendarWeek />,
        type: 'item',
        text: 'Disponibilidades',
        code: 'availabilities',
        featureFlag: 'show_old_schedulings_availabilities_pages',
        to: 'availabilities',
        policies: [{ namespace: 'availabilities', action: 'GetAppAvailabilities' }],
      },
      {
        icon: <IconCalendar />,
        type: 'item',
        text: 'Reservas',
        code: 'reservations',
        to: 'reservations',
        policies: [{ namespace: 'reservations', action: 'GetAppReservations' }],
      },
      {
        icon: <IconFileCheck />,
        type: 'item',
        text: 'Consumir Voucher',
        code: 'voucher-identification',
        to: 'voucher-identification',
        policies: {
          AND: [
            {
              namespace: 'vouchers',
              action: 'IdentifyAppVoucher',
              code: 'vouchers:IdentifyAppVoucher',
            },
            {
              namespace: 'vouchers',
              action: 'GetAppQrCodeVoucher',
              code: 'vouchers:GetAppQrCodeVoucher',
            },
            {
              namespace: 'vouchers',
              action: 'ConsumeAppQrCodeVoucher',
              code: 'vouchers:ConsumeAppQrCodeVoucher',
            },
          ],
        },
      },
      {
        icon: <IconFileInvoice />,
        type: 'item',
        text: 'Vouchers',
        code: 'vouchers',
        to: 'vouchers',
        policies: [{ namespace: 'vouchers', action: 'GetAppVouchers' }],
      },
    ],
  },
  {
    type: 'nested',
    text: 'Comercial',
    code: 'commercial',
    icon: <IconShoppingBag />,
    children: [
      {
        icon: <IconShoppingCartCheck />,
        type: 'item',
        text: 'Vendas',
        code: 'sales',
        to: 'sales',
        policies: [{ namespace: 'sales', action: 'GetAppSales' }],
      },
      {
        icon: <IconTicket />,
        type: 'item',
        text: 'Cupons',
        code: 'coupons',
        to: 'coupons',
        policies: [{ namespace: 'coupons', action: 'GetAppCoupons' }],
      },
      {
        icon: <IconShoppingCartPin />,
        type: 'item',
        text: 'Origens de Venda',
        code: 'sale-origins',
        to: 'sale-origins',
        policies: [{ namespace: 'saleOrigins', action: 'GetAppSaleOrigins' }],
      },
      {
        icon: <IconUser />,
        type: 'item',
        text: 'Clientes',
        code: 'customers',
        to: 'customers',
        policies: [{ namespace: 'customers', action: 'GetAppCustomers' }],
      },
    ],
  },
  {
    type: 'nested',
    text: 'Relatórios',
    code: 'reports',
    icon: <IconChartPie />,
    children: [
      {
        icon: <IconShoppingCartShare />,
        type: 'item',
        text: 'Exportar Vendas',
        code: 'sales-export',
        to: 'sales-export',
        policies: [{ namespace: 'sales', action: 'ExportAppSales' }],
      },
      {
        icon: <IconUserShare />,
        type: 'item',
        text: 'Exportar Clientes',
        code: 'export_customers',
        to: 'export_customers',
        policies: [{ namespace: 'customers', action: 'ExportAppCustomers' }],
      },
      {
        icon: <IconFilePlus />,
        type: 'item',
        text: 'Exportar Adicionais',
        code: 'additionals-export',
        to: 'additionals-export',
        policies: [{ namespace: 'saleAdditionals', action: 'ExportAppSaleAdditionals' }],
      },
      {
        icon: <IconFileExport />,
        type: 'item',
        text: 'Exportar Vouchers',
        code: 'vouchers-export',
        to: 'vouchers-export',
        policies: [{ namespace: 'vouchers', action: 'ExportAppVouchers' }],
      },
    ],
  },
  {
    type: 'nested',
    text: 'Financeiro',
    code: 'financial',
    icon: <IconBuildingBank />,
    children: [
      {
        icon: <IconCoins />,
        type: 'item',
        text: 'Recebíveis',
        code: 'income-schedulings',
        to: 'income-schedulings',
        showToAgent: true,
        policies: [{ namespace: 'incomeSchedulings', action: 'GetAppIncomeSchedulings' }],
      },
      {
        icon: <IconFileText />,
        type: 'item',
        text: 'Extrato',
        code: 'date-interval-statement',
        to: 'date-interval-statement',
        policies: [{ namespace: 'incomeSchedulings', action: 'GetAppIncomeSchedulings' }],
      },
      {
        icon: <IconSwitchHorizontal />,
        type: 'item',
        text: 'Estornos',
        code: 'refund_requests',
        to: 'refund-requests',
        policies: [{ namespace: 'refundRequests', action: 'GetAppRefundRequests' }],
      },
    ],
  },
  {
    type: 'nested',
    text: 'Planne Link',
    code: 'planne_link',
    icon: <IconLink />,
    children: [
      {
        icon: <IconCreditCardPay />,
        type: 'item',
        text: 'Link de Pagamento',
        code: 'payment_link',
        to: 'direct-links/payment',
        policies: [{ namespace: 'directLinks', action: 'GetAppDirectLinks' }],
      },
      {
        icon: <IconUnlink />,
        type: 'item',
        text: 'Link de Venda',
        code: 'sale_link',
        to: 'direct-links/sale',
        policies: [{ namespace: 'directLinks', action: 'GetAppDirectLinks' }],
      },
    ],
  },
  {
    type: 'nested',
    text: 'Parcerias',
    code: 'partnerships',
    icon: <IconUsersGroup />,
    children: [
      {
        icon: <IconBuildingStore />,
        type: 'item',
        text: 'Vitrine de produtos',
        code: 'arrangedProducts:GetAppArrangedProducts',
        to: 'partnership/productShowCase',
        policies: [{ namespace: 'arrangedProducts', action: 'GetAppArrangedProducts' }],
        showToAgent: true,
        featureFlag: 'partnership_product_showcase',
      },
      {
        icon: <IconClipboardList />,
        type: 'item',
        text: 'Minhas compras',
        code: 'directSales:GetAppDirectSales',
        to: 'partnership/directSales',
        policies: [{ namespace: 'directSales', action: 'GetAppDirectSales' }],
        showToAgent: true,
        featureFlag: 'partnership_direct_sales',
      },
      {
        icon: <IconUserHexagon />,
        type: 'item',
        text: 'Agentes',
        code: 'afiliations',
        to: 'partnership/agents',
        policies: [{ namespace: 'partnerships', action: 'GetAppValidProvidedPartnerships' }],
        featureFlag: 'partnership_provider',
      },
      {
        icon: <IconTruckLoading />,
        type: 'item',
        text: 'Fornecedores',
        code: 'providers',
        to: 'partnership/providers',
        policies: [{ namespace: 'partnerships', action: 'GetAppValidArrangedPartnerships' }],
        featureFlag: 'partnership_agent',
        showToAgent: true,
      },
      {
        icon: <IconCash />,
        type: 'item',
        text: 'Comissões',
        code: 'commissions',
        to: 'partnership/commissions',
        policies: {
          AND: [
            {
              namespace: 'saleItems',
              action: 'GetAppArrangedSaleItems',
              code: 'sales:GetAppArrangedSaleItems',
            },
            {
              namespace: 'saleItems',
              action: 'GetAppArrangedSaleItemsAggregatedInfo',
              code: 'sales:GetAppArrangedSaleItemsAggregatedInfo',
            },
          ],
        },
        featureFlag: 'partnership_agent',
        showToAgent: true,
      },
    ],
  },
  {
    type: 'nested',
    text: 'Site',
    code: 'site',
    icon: <IconWorldCode />,
    children: [
      {
        icon: <IconTemplate />,
        type: 'item',
        text: 'Templates',
        code: 'general_configurations',
        to: 'site/configuration',
        policies: [{ namespace: 'siteConfigs', action: 'GetAppSiteConfig' }],
      },
      {
        icon: <IconEdit />,
        type: 'item',
        text: 'Editar Conteúdo',
        code: 'content_editor',
        to: 'site/template-data',
        policies: [{ namespace: 'siteConfigs', action: 'GetAppSiteConfig' }],
      },
      {
        icon: <IconRefresh />,
        type: 'item',
        text: 'Atualizações',
        code: 'updates',
        to: 'site/builds',
        policies: [{ namespace: 'siteConfigs', action: 'GetAppSiteConfig' }],
      },
      {
        icon: <IconWorldWww />,
        type: 'item',
        text: 'Domínios',
        code: 'domains',
        to: 'hosts',
        policies: [{ namespace: 'siteConfigs', action: 'GetAppSiteConfig' }],
      },
    ],
  },
  {
    type: 'nested',
    text: 'Configurações',
    code: 'settings',
    icon: <IconSettings />,
    children: [
      {
        icon: <IconTool />,
        type: 'item',
        text: 'Geral',
        code: 'general',
        to: 'configuration/general',
        policies: [{ namespace: 'apps', action: 'UpdateApp' }],
      },
      {
        icon: <IconAdjustments />,
        type: 'item',
        text: 'Sistema',
        code: 'system',
        to: 'configuration/system',
        policies: [{ namespace: 'apps', action: 'UpdateApp' }],
      },
      {
        icon: <IconCoin />,
        type: 'item',
        text: 'Financeiro',
        code: 'financial',
        to: 'configuration/financial',
        policies: [{ namespace: 'apps', action: 'UpdateAppInfo' }],
      },
      {
        icon: <IconCreditCard />,
        type: 'item',
        text: 'Métodos de Pagamento',
        code: 'payment_methods',
        to: 'payment-methods',
        policies: [{ namespace: 'paymentMethods', action: 'CreateAppPaymentMethod' }],
      },
      {
        icon: <IconBell />,
        type: 'item',
        text: 'Notificações',
        code: 'notifications',
        to: 'configuration/notifications',
        policies: [{ namespace: 'apps', action: 'UpdateAppInfo' }],
      },
      {
        icon: <IconPlugConnected />,
        type: 'item',
        text: 'Técnico',
        code: 'technical',
        to: 'configuration/technical',
        policies: [{ namespace: 'apps', action: 'UpdateApp' }],
      },
    ],
  },
  {
    type: 'item',
    text: 'Ajuda',
    code: 'help',
    icon: <IconHelp />,
    to: 'https://ajuda.planne.com.br/kb',
    track: AnalyticsEvents.MENU_HELP_LINK_CLICK,
    target: '_blank',
    policies: [],
  },
];

export default mainMenuStructure;
